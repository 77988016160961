import { type SubmissionValueState } from '../../models/form-submission/submission-value-state.model';
import { type AccessorFn } from '../drupal-webform-types';

export const VALUE_ACCESSOR_SELECT: AccessorFn = (v, settings) => {
    if ((v as SubmissionValueState | undefined)?.isDisabled) {
        return undefined;
    }

    const value = v as string;
    // Select options have a string as key for the first element, but for all others a number is used as key
    const options = settings?.options;
    if (!options) {
        return '';
    }

    const label = options.find(o => o.value.toString() === value)?.key ?? undefined;

    // We need to split at '--' to seperate the label from the help text
    return label?.split('--')[0];
};
