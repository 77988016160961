<ng-container *transloco="let t; prefix: 'ftbPrivacyPolicy'">
    <big-ui-form-row
        [showInfo]="!!settings.help"
        [infoTextTemplate]="help"
        [id]="settings.id"
        class="mb-2"
        [errors]="control?.errors"
        [showError]="isInvalid"
        [errorOverrides]="errorOverrides"
        (infoLayerOpened)="trackFormsInfoIconEvent($event)">
        <big-ui-checkbox
            [id]="settings.id"
            [titleHtml]="settings.markup"
            [value]="settings.returnValue"
            [isDisabled]="control?.disabled"
            [isRequired]="control?.isRequired"
            [isValid]="isValid"
            [isInvalid]="isInvalid"
            [formControl]="control"
            [formFieldTracking]="settings" />
    </big-ui-form-row>
</ng-container>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
