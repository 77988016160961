import { Component } from '@angular/core';
import { SelectBaseComponent } from '../../../base-components/select-base/select-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-select',
    templateUrl: './select.component.html',
})
export class SelectComponent extends SelectBaseComponent {
    public errorOverrides = { required: 'ftbForm.validation.mustBeAValueFromTheList' };

    public static register(): ComponentMap {
        return {
            big_webform_select: SelectComponent, // eslint-disable-line @typescript-eslint/naming-convention
            select: SelectComponent,
        };
    }
}
