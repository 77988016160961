import { Component, Injector, Input, SecurityContext, effect, inject, signal, type OnChanges } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UiAlertComponent } from '@big-direkt/ui/alert';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { SanitizePipe } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { catchError, map, of } from 'rxjs';
import { type ErrorNodeMessage } from '../models/error-node-message';
import { type ErrorNodeModel } from '../models/error-node-model';
import { ErrorNodeLoaderService } from '../services/error-node-loader.service';
import { ErrorNodeMessageExtractorService } from '../services/error-node-message-extractor.service';

@Component({
    selector: 'big-error-handling-alert',
    standalone: true,
    templateUrl: './error-handling-alert.component.html',
    providers: [provideTranslationScope('errorHandling', /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`))],
    imports: [SanitizePipe, TranslocoDirective, UiAlertComponent],
})
export class ErrorHandlingAlertComponent implements OnChanges {
    public readonly securityContext: SecurityContext = SecurityContext.HTML;

    private readonly errorNodeLoaderService = inject(ErrorNodeLoaderService);
    private readonly errorNodeMessageExtractorService = inject(ErrorNodeMessageExtractorService);
    private readonly injector = inject(Injector);

    @Input({ required: true })
    public errorCode: number | undefined = undefined;

    @Input()
    public errorType = 'general';

    public data = signal<ErrorNodeMessage | undefined>(undefined);

    public ngOnChanges(): void {
        if (!this.errorCode) {
            this.data.set(undefined);

            return;
        }

        const res = toSignal(
            this.errorNodeLoaderService.get(this.errorCode, this.errorType).pipe(
                map((node: ErrorNodeModel) => this.errorNodeMessageExtractorService.extract(node)),
                catchError(() =>
                    of<ErrorNodeMessage>({
                        alertType: 'danger',
                    }),
                ),
            ),
            { injector: this.injector },
        );

        effect(
            () => {
                this.data.set(res());
            },
            {
                allowSignalWrites: true,
                injector: this.injector,
            },
        );
    }
}
