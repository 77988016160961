@if (isLamapoll) {
    <div [id]="lamapollId"></div>
} @else {
    <iframe
        #iframe
        class="mt-0 mb-12 -mx-2 w-[calc(100%_+_16px)]"
        [ngStyle]="{ 'height.px': height }"
        [src]="data.url | sanitize: sanitizeContext"
        (load)="onIframeLoaded()"></iframe>
}
