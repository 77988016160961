import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, inject, Input, Output, signal } from '@angular/core';
import { ScrollService, WindowService } from '@big-direkt/utils/environment';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumInfo, IconBigMediumSchliessenKreis, IconComponent } from '@big-direkt/utils/icons';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'big-ui-info-button',
    standalone: true,
    imports: [IconComponent, TranslocoDirective],
    providers: [provideTranslationScope('uiInfoButton', /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`))],
    templateUrl: './info-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoButtonComponent {
    private readonly windowService = inject(WindowService);
    private readonly scrollService = inject(ScrollService);
    public readonly iconInfo = IconBigMediumInfo;
    public readonly iconClose = IconBigMediumSchliessenKreis;

    @HostBinding('class') public classList = 'inline-flex';
    @Input({ required: true }) public readonly scrollAnchorId!: string;
    @Output() public readonly infoLayerOpened = new EventEmitter<boolean>();

    public isOpen = signal(false);

    public toggleTooltip(event: Event): void {
        event.preventDefault();
        event.stopImmediatePropagation();

        this.isOpen.set(!this.isOpen());
        this.infoLayerOpened.emit(this.isOpen());
        if (this.isOpen()) {
            this.windowService.nativeWindow()?.requestAnimationFrame((): void => {
                this.scrollService.scroll(`#${CSS.escape(this.scrollAnchorId)}`, { scrollIntoView: true, scrollIntoViewOptions: { block: 'end' } });
            });
        }
    }
}
