@if (infoValue) {
    <big-ui-form-row
        [rowClass]="'block mb-6'"
        [label]="label"
        [id]="settings.id"
        (infoLayerOpened)="trackFormsInfoIconEvent($event)">
        <input
            class="form-control"
            [id]="settings.id"
            type="text"
            [attr.readonly]="isReadOnly"
            [formControl]="control" />
    </big-ui-form-row>
}
