import { Component, Input, type OnInit } from '@angular/core';
import { ProgressBarDirection, progressBarDirection } from './progress-bar-direction';

@Component({
    selector: 'big-ui-progress-bar',
    templateUrl: './progress-bar.component.html',
    standalone: true,
})
export class ProgressBarComponent implements OnInit {
    private readonly min = 0;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    private readonly max = 100;

    @Input() public current = 0;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    @Input() public direction: ProgressBarDirection = progressBarDirection.down;

    public progress = 0;

    public ngOnInit(): void {
        const current = this.min > this.current ? this.min : this.current;
        this.progress = this.direction === progressBarDirection.up ? this.reduceToMaxValue(current) : this.max - this.reduceToMaxValue(current);
    }

    private reduceToMaxValue(value: number): number {
        return value > this.max ? this.max : value;
    }
}
