<div
    *transloco="let t; prefix: 'uiOneTimeToken'"
    class="flex"
    [formGroup]="formGroup">
    @for (control of formGroup.controls | keyvalue; track i; let i = $index; let first = $first) {
        <div class="ml-2.5 first:ml-0">
            <input
                #controls
                maxlength="1"
                [formControlName]="control.key"
                [required]="isRequired"
                id="{{ first ? id : id + '-' + i }}"
                name="{{ first ? id : id + '-' + i }}"
                [attr.aria-label]="t('inputLabel', { current: i + 1, total: numberFields })"
                [attr.type]="inputType"
                (keyup)="onKeyUpHandler(i, $event)"
                class="form-control w-14"
                autocomplete="off"
                [ngClass]="{
                    'is-invalid': !isValid && showValidation,
                    'is-valid': isValid && showValidation
                }" />
        </div>
    }
</div>
