import { type SubmissionValueAccessorFn } from '../drupal-webform-types';

export const SUBMISSION_VALUE_ACCESSOR_REPEATED_PAGE: SubmissionValueAccessorFn = (_v, settings, parsedChildValues) => {
    // Repeated page case
    if (!settings.repeatedElement || settings.repeatedIndex === undefined) {
        return undefined;
    }

    return { [settings.repeatedElement]: { [settings.repeatedIndex]: { [settings.name]: parsedChildValues } } };
};
