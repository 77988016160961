<label
    [for]="id"
    class="border-0"
    [class.opacity-50]="isDisabled">
    <div
        class="flex items-center border-2 border-transparent !bg-alt focus-within:!bg-gray-300 p-1.5 rounded-lg"
        [ngClass]="{
            '!border-success': isValid,
            '!border-danger': isInvalid,
            'hover:!bg-contrast': !isDisabled,
        }">
        <input
            [formControl]="formControl"
            class="relative h-5 w-5 flex-shrink-0 cursor-pointer appearance-none border-2 border-gray-300 checked:border-primary bg-default before:absolute before:block before:content[''] before:w-[10px] before:h-[10px] before:top-2/4 before:left-2/4 before:-translate-y-2/4 before:-translate-x-2/4 checked:before:bg-primary"
            [ngClass]="{ '!border-success': isValid, '!border-danger': isInvalid, '!cursor-default': isDisabled, 'hover:border-gray-500': !isDisabled }"
            [id]="id"
            type="checkbox"
            [attr.name]="id"
            [required]="isRequired"
            (ngModelChange)="onChange(formControl.value)"
            [value]="value" />
        <big-ui-label
            [for]="id"
            [label]="title"
            [labelHtml]="titleHtml"
            [isDisabled]="isDisabled"
            [isRequired]="isRequired"
            class="w-full h-full ml-2" />
    </div>
</label>
