import { type SubmissionValueState } from '../../models/form-submission/submission-value-state.model';
import { type AccessorFn, type SubmissionValueAccessorFn } from '../drupal-webform-types';

export const SUBMISSION_VALUE_ACCESSOR_CHECKBOXES: SubmissionValueAccessorFn = (v, settings) => {
    const value = v as Record<string, boolean>;
    const parsedData: Record<string, string | undefined> = {};
    settings.children.forEach(child => {
        if (value[child.name]) {
            parsedData[Object.keys(parsedData).length] = child.returnValue;
        }
    });

    return { [settings.name]: parsedData };
};

// lists all filled inner control titles (usually only contains checkboxes)
// eslint-disable-next-line sonarjs/function-return-type
export const VALUE_ACCESSOR_CHECKBOXES: AccessorFn = (v, settings) => {
    if ((v as SubmissionValueState | undefined)?.isDisabled) {
        return undefined;
    }

    const value = v as Record<number, string> | undefined;
    const checkboxTitles =
        value &&
        Object.values(value).map(x => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const compositeKey = `${settings!.name}[${x}]`;
            const found = settings?.children.find(child => child.name === compositeKey) ?? settings?.children.find(child => child.name === x);

            return found?.title ?? '';
        });

    return checkboxTitles?.length ? checkboxTitles : '';
};
