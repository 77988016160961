import { DRUPAL_WEBFORM_TYPE_CONFIG, PageHierarchyNode, type FormConfigElement } from '@big-direkt/form/contracts';
import { type FormService } from '../form/form.service';

export class PageHierarchyFormNode extends PageHierarchyNode {
    public override get children(): PageHierarchyFormNode[] {
        return super.children as PageHierarchyFormNode[];
    }

    public override get leafs(): PageHierarchyFormNode[] {
        return super.leafs as PageHierarchyFormNode[];
    }

    public override get nextLeaf(): PageHierarchyFormNode | undefined {
        return super.nextLeaf as PageHierarchyFormNode;
    }

    public override get previousLeaf(): PageHierarchyFormNode | undefined {
        return super.previousLeaf as PageHierarchyFormNode;
    }

    public override get lastLeaf(): PageHierarchyFormNode {
        return super.lastLeaf as PageHierarchyFormNode;
    }

    protected override get previousSibling(): PageHierarchyFormNode | undefined {
        return super.previousSibling as PageHierarchyFormNode | undefined;
    }

    public get firstAccessibleLeaf(): PageHierarchyFormNode | undefined {
        return this.leafs.find(leaf => leaf.isAccessible);
    }

    public get lastAccessibleLeaf(): PageHierarchyFormNode | undefined {
        return this.leafs
            .slice()
            .reverse()
            .find((leaf: PageHierarchyFormNode): boolean => leaf.isAccessible);
    }

    public get nextAccessibleLeaf(): PageHierarchyFormNode | undefined {
        const nextLeaf: PageHierarchyFormNode | undefined = this.nextLeaf;

        return !nextLeaf || nextLeaf.isAccessible ? nextLeaf : nextLeaf.nextAccessibleLeaf;
    }

    public get previousAccessibleLeaf(): PageHierarchyFormNode | undefined {
        const { previousLeaf } = this;

        return !previousLeaf || previousLeaf.isAccessible ? previousLeaf : previousLeaf.previousAccessibleLeaf;
    }

    public get hasNextAccessibleLeaf(): boolean {
        return !!this.nextAccessibleLeaf;
    }

    public get hasPreviousAccessibleLeaf(): boolean {
        return !!this.previousAccessibleLeaf;
    }
    public constructor(
        public override readonly settings: FormConfigElement,
        public override readonly parent: PageHierarchyFormNode | undefined,
        private readonly formService: FormService,
    ) {
        super(settings, parent);
        this._children = this.parseChildren(this.settings);
    }

    public override createNode(settings: FormConfigElement): PageHierarchyNode {
        return new PageHierarchyFormNode(settings, this, this.formService);
    }
    protected override isDisabled(node: FormConfigElement): boolean {
        return !!this.formService.getFormControl(node.arrayParents)?.disabled;
    }
    protected override isInput(node: FormConfigElement): boolean {
        return DRUPAL_WEBFORM_TYPE_CONFIG[node.type].isInput;
    }
}
