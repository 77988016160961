import {
    DRUPAL_WEBFORM_TYPE_CONFIG,
    VALUE_ACCESSOR_DEFAULT,
    type FormConfigElement,
    type PreviewDisplayValue,
    type SubmissionValue,
} from '@big-direkt/form/contracts';

export abstract class FormElementValueServiceBase {
    public abstract isDisabled(element: FormConfigElement): boolean;
    public abstract submissionValue(element: FormConfigElement): SubmissionValue;
    public abstract getLinkValue(element: FormConfigElement): { label: string; src?: string }[] | undefined;

    // eslint-disable-next-line sonarjs/function-return-type
    public getFormattedPreviewValue(element: FormConfigElement): PreviewDisplayValue {
        if (!(element.type in DRUPAL_WEBFORM_TYPE_CONFIG)) {
            return undefined;
        }

        const value = this.submissionValue(element);
        const accessor = DRUPAL_WEBFORM_TYPE_CONFIG[element.type].previewAccessor ?? VALUE_ACCESSOR_DEFAULT;

        return accessor(value, element);
    }
}
