/* eslint-disable @typescript-eslint/naming-convention */
import { inject, Injectable } from '@angular/core';
import { type UiResultFilterModel } from '@big-direkt/ui/result-filter';
import { EnvironmentService } from '@big-direkt/utils/environment';
import { type BreadcrumbItemModel } from '@big-direkt/utils/shared';
import { MeiliSearch, type Hit, type Index } from 'meilisearch';
import { BehaviorSubject, from, map, of, type Observable } from 'rxjs';
import { type SearchResult } from '../models/search-result';
import { SearchTrackingService } from './search-tracking.service';

const MIN_SUGGEST_STRING_LENGTH = 3;

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    private readonly client;
    private readonly environment = inject(EnvironmentService);
    private readonly searchTrackingService = inject(SearchTrackingService);
    private readonly facetsAllowed = ['category'];
    private readonly defaultResults: UiResultFilterModel[] = [];
    private readonly indexName = 'big_direkt_de';
    public readonly filters = new BehaviorSubject<UiResultFilterModel[]>([]);
    public readonly term = new BehaviorSubject<string>('');

    private index: Index | undefined;

    public constructor() {
        this.client = new MeiliSearch({
            host: this.environment.baseHref,
        });
    }

    public search(
        term: string,
        offset = 0,
        limit = 10,
        filters: UiResultFilterModel[] = [],
        customFilter?: string,
        customSort?: string[],
    ): Observable<SearchResult> {
        if (!term || term === '') {
            this.filters.next(this.defaultResults);

            return of({
                total: 0,
                offset: 0,
                limit,
                items: [],
            });
        }

        const filter = customFilter ?? [
            filters.filter(x => this.facetsAllowed.includes(x.facet)).map(activeFilter => `${activeFilter.facet}="${activeFilter.title}"`),
        ];

        return from(
            this.client.multiSearch({
                queries: [
                    {
                        indexUid: this.indexName,
                        q: term,
                        limit,
                        offset,
                        filter,
                        sort: customSort ?? [],
                    },
                    {
                        indexUid: this.indexName,
                        facets: this.facetsAllowed,
                        q: term,
                        limit: 0,
                    },
                ],
            }),
        ).pipe(
            map(multiSearch => {
                const items = multiSearch.results.at(0);
                const facetDistribution = multiSearch.results.at(1)?.facetDistribution ?? {};

                this.filters.next(
                    Object.keys(facetDistribution)
                        .map(facet =>
                            Object.entries(facetDistribution[facet])
                                .map((item: [string, number]) => {
                                    const [name, distribution] = item;

                                    return {
                                        facet,
                                        title: name,
                                        // eslint-disable-next-line sonarjs/no-nested-functions
                                        isActive: filters.find(toFind => toFind.facet === facet && toFind.title === name)?.isActive ?? false,
                                        distribution,
                                    };
                                })
                                .sort((a, b) => b.distribution - a.distribution),
                        )
                        .flat(),
                );

                this.searchTrackingService.trackKeywordSearch(items?.estimatedTotalHits ?? 0);

                return {
                    total: items?.estimatedTotalHits ?? 0,
                    offset: items?.offset ?? offset,
                    limit: items?.limit ?? limit,
                    items: (items?.hits ?? []).map((hit: Hit) => ({
                        type: hit.type,
                        id: hit.uuid,
                        title: hit.title,
                        body: hit.introduction,
                        url: hit.url,
                        breadcrumb: this.formatBreadcrumb(hit.breadcrumbs_title, hit.breadcrumbs_link),
                    })),
                };
            }),
        );
    }

    public getSuggestions(term: string): Observable<string[]> {
        if (!this.index) {
            this.index = this.client.index(this.indexName);
        }

        return term === '' || term.length < MIN_SUGGEST_STRING_LENGTH
            ? of([])
            : from(this.index.search(term)).pipe(map(item => item.hits.map(hit => hit.title)));
    }

    private formatBreadcrumb(titles: string[] | string, links: string[] | string): BreadcrumbItemModel[] {
        const breadcrumbsTitle: string[] = Array.isArray(titles) ? titles : [titles];
        breadcrumbsTitle.shift();

        const breadcrumbsLink: string[] = Array.isArray(links) ? links : [links];
        breadcrumbsLink.shift();

        return breadcrumbsTitle
            .map(
                (title: string, index: number): BreadcrumbItemModel => ({
                    title,
                    uri: breadcrumbsLink[index],
                }),
            )
            .filter(item => item.uri);
    }
}
