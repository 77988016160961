import { type ValidatorFn } from '@angular/forms';
import { type FormConfigElement } from '@big-direkt/form/contracts';
import { type CustomFormControlValidatorEntry } from '../../interfaces/custom-form-control-validator.token';
import { HinValidator } from '../components/hin/hin.validator';

export const hinFieldValidatorFactory: () => CustomFormControlValidatorEntry = (): CustomFormControlValidatorEntry =>
    ({
        type: 'big_webform_hin',
        handle: (_settings: FormConfigElement, validators: ValidatorFn[]): void => {
            validators.push(HinValidator.hinValidator());
        },
    }) as CustomFormControlValidatorEntry;
