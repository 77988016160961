import { type FormConfigElement } from '@big-direkt/form/contracts';
import { type TokenTreeNode } from './token-tree-node';

export class TokenRefService {
    private tokenTree: Record<string, TokenTreeNode> = {};

    public registerRecursive(element: FormConfigElement, updateNodeFromSettings: (node: TokenTreeNode, settings: FormConfigElement) => void): void {
        this.register(element, updateNodeFromSettings);
        element.children.forEach(child => {
            this.registerRecursive(child, updateNodeFromSettings);
        });
    }

    public register<TConfig extends FormConfigElement>(element: TConfig, updateNodeFromSettings: (node: TokenTreeNode, settings: TConfig) => void): void {
        if (!element.tokenRefs) {
            return;
        }

        const tokenRefs: Record<string, string | undefined> = this.transformTokenRefs(element.tokenRefs);

        Object.entries(tokenRefs).forEach(([token, defaultValue]: [string, string | undefined]): void => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition,sonarjs/different-types-comparison
            if (this.tokenTree[token] === undefined) {
                this.tokenTree[token] = {};
            }

            let node: TokenTreeNode = this.tokenTree[token];
            element.arrayParents.forEach((parent: string, index: number): void => {
                if (element.arrayParents.length && index === element.arrayParents.length - 1) {
                    updateNodeFromSettings(node, element);
                    node.defaultValue = defaultValue;

                    return;
                }

                if (!node.children) {
                    node.children = {};
                }

                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (!node.children[parent]) {
                    node.children[parent] = {};
                }

                node = node.children[parent];
            });
        });
    }

    public replaceTokenRefs(settings: FormConfigElement, index: string): void {
        if (settings.tokenRefs && !Array.isArray(settings.tokenRefs)) {
            const oldRefs = settings.tokenRefs;
            const newRefs: Record<string, string> = {};
            Object.keys(oldRefs).forEach(key => {
                const newKey = key.replace('$INDEX', index);
                newRefs[newKey] = oldRefs[key];
            });
            settings.tokenRefs = newRefs;
        }

        if (settings.children.length) {
            settings.children.forEach(sub => {
                this.replaceTokenRefs(sub, index);
            });
        }
    }

    public replaceIndexedLabels(settings: FormConfigElement, groupIndex: string): void {
        settings.wizardLabel = settings.wizardLabel?.replace(/\$INDEX/g, groupIndex);
        settings.title = settings.title?.replace(/\$INDEX/g, groupIndex);
        settings.previewLabel = settings.previewLabel?.replace(/\$INDEX/g, groupIndex);

        settings.children.forEach((child: FormConfigElement): void => {
            this.replaceIndexedLabels(child, groupIndex);
        });
    }

    public findReplacement(value = '', parents: string[] = []): string {
        if (!value) {
            return '';
        }

        const regex = value.match(/(#[^#]+#)/g);

        if (!regex?.[0]) {
            return value;
        }

        return value.replace(/(#[^#]+#)/g, (match: string): string => {
            const result: string | undefined = this.findReplacementForMatch(match, parents);

            return result ?? match;
        });
    }

    private static selectBestMatch(current: TokenTreeNode, candidate: TokenTreeNode): TokenTreeNode {
        // Take any candidate if current node has no component
        // Otherwise check whether candidate has component with enabled and non-empty control
        if (!current.valueGetter || candidate.hasFilledVisibleControl?.()) {
            return candidate;
        }

        return current;
    }

    private transformTokenRefs(tokenRefs: Record<string, string> | string[]): Record<string, string | undefined> {
        if (!Array.isArray(tokenRefs)) {
            return tokenRefs;
        }

        const transformedTokenRefs: Record<string, undefined> = {};
        tokenRefs.forEach((token: string): void => {
            transformedTokenRefs[token] = undefined;
        });

        return transformedTokenRefs;
    }

    private findReplacementForMatch(token: string, parents: string[] = []): string | undefined {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition,sonarjs/different-types-comparison
        if (this.tokenTree[token] === undefined) {
            return undefined;
        }

        let result: TokenTreeNode = this.tokenTree[token];
        let node: TokenTreeNode = this.tokenTree[token];

        for (const parent of parents) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (node.children?.[parent] === undefined) {
                break;
            }

            node = node.children[parent];
            result = TokenRefService.selectBestMatch(result, node);
        }

        while (node.children) {
            node = Object.values(node.children)[0];
        }

        result = TokenRefService.selectBestMatch(result, node);

        return result.valueGetter?.() || node.defaultValue;
    }
}
