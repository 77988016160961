import { type SubmissionObject, type SubmissionValue } from '@big-direkt/form/contracts';

const isObject = (item?: SubmissionObject | SubmissionValue): item is SubmissionObject => !!item && typeof item === 'object' && !Array.isArray(item);

// eslint-disable-next-line jsdoc/require-param
/**
 * Merges two SubmissionObjects like Object.assign while keeping all different child properties.
 * implemented for usecase:
 * repeatedPages create Properties for the same submissionObject { [pagedef]: { [index]: { [content] }}}
 * When using Object.assign, the submissionObject for index 1 would overwrite the one for index 0
 */
export const deepMerge = (target?: SubmissionObject, source?: SubmissionObject | SubmissionValue): void => {
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!target[key]) {
                    target[key] = {};
                }
                deepMerge(target[key] as SubmissionObject, source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        });
    }
};
