import { type ValidatorFn } from '@angular/forms';
import { type FormConfigElement } from '@big-direkt/form/contracts';
import { type CustomFormControlValidatorEntry } from '../../interfaces/custom-form-control-validator.token';
import { CustomPatternValidator } from '../../utilities/custom-pattern-validator/custom-pattern-validator';

export const textFieldValidatorFactory: () => CustomFormControlValidatorEntry = (): CustomFormControlValidatorEntry =>
    ({
        type: 'textfield',
        handle: (settings: FormConfigElement, validators: ValidatorFn[]): void => {
            if (settings.attributes?.pattern) {
                validators.push(CustomPatternValidator.validatePattern(settings));
            }
        },
    }) as CustomFormControlValidatorEntry;
