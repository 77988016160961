import { AsyncPipe, NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    EventEmitter,
    HostBinding,
    inject,
    Input,
    type OnInit,
    Output,
    signal,
    ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, type MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumSchliessen, IconComponent } from '@big-direkt/utils/icons';
import { BaseControlValueAccessor } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { type AutocompleteOption } from '../../models';

const DEFAULT_MIN_CHARACTERS = 3;
const DEFAULT_STYLES_WITHOUT_VALIDATION =
    // eslint-disable-next-line max-len
    'absolute py-0 min-h-[1rem] border-l-2 border-r-2 border-b-2 !rounded-b-md -translate-y-[4px] before:absolute before:bg-gray-300 before:h-[1px] before:left-4 before:right-4 before:top-0';

@Component({
    selector: 'big-ui-autocomplete-input',
    standalone: true,
    templateUrl: './autocomplete-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        AsyncPipe,
        FormsModule,
        IconComponent,
        MatAutocompleteModule,
        NgClass,
        ReactiveFormsModule,
        TranslocoDirective,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AutocompleteInputComponent,
            multi: true,
        },
        provideTranslationScope('uiAutocompleteInput', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`)),
    ],
})
export class AutocompleteInputComponent<T> extends BaseControlValueAccessor<T | string> implements OnInit {
    public readonly iconClose = IconBigMediumSchliessen;
    public readonly internalAutocompleteControl = new FormControl<string | null | undefined>('');
    private readonly destroyRef = inject(DestroyRef);

    @HostBinding('class') public classList = 'block';

    @Input({ required: true }) public name!: string;
    @Input({ required: true }) public options: AutocompleteOption<T>[] | null | undefined;
    @Input({ required: true }) public optionsValueTransformer!: (option: AutocompleteOption<T>) => string | null | undefined;
    @Input() public isRequired = false;
    @Input() public placeholder?: string;
    @Input() public minCharacters = DEFAULT_MIN_CHARACTERS;
    @Input() public requireSelection = false;
    @Input() public id?: string;
    @Input() public inputStyle?: string;
    @Input() public buttonStyle?: string;

    @Output() public readonly optionSelected = new EventEmitter<void>();
    @Output() public readonly inputChanges = new EventEmitter<string | null | undefined>();
    @Output() public readonly elementBlurred = new EventEmitter<void>();

    @ViewChild(MatAutocompleteTrigger) public autocomplete?: MatAutocompleteTrigger;

    private lastSelectedLabel?: string | null;
    private _isInvalid = true;
    private _isValid = false;
    private _autocompleteStyle?: string;

    public autocompleteClasses = signal(`${DEFAULT_STYLES_WITHOUT_VALIDATION} border-gray-200`);

    @Input() public set autocompleteStyle(value: string | undefined) {
        this._autocompleteStyle = value;
        if (this._autocompleteStyle) {
            this.autocompleteClasses.set(this._autocompleteStyle);
        }
    }

    public get autocompleteStyle(): string | undefined {
        return this._autocompleteStyle;
    }

    @Input() public set isValid(value: boolean) {
        this._isValid = value;
        if (this._isValid && !this._autocompleteStyle) {
            this.autocompleteClasses.set(`${DEFAULT_STYLES_WITHOUT_VALIDATION} border-success`);
        }
    }

    public get isValid(): boolean {
        return this._isValid;
    }

    @Input() public set isInvalid(value: boolean) {
        this._isInvalid = value;
        if (this._isInvalid && !this._autocompleteStyle) {
            this.autocompleteClasses.set(`${DEFAULT_STYLES_WITHOUT_VALIDATION} border-danger`);
        }
    }
    public get isInvalid(): boolean {
        return this._isInvalid;
    }

    public ngOnInit(): void {
        this.internalAutocompleteControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(visibleInputValue => {
            if (!visibleInputValue || visibleInputValue.length < this.minCharacters) {
                this.inputChanges.emit(undefined);
            }

            if (visibleInputValue && visibleInputValue.length >= this.minCharacters) {
                this.inputChanges.emit(visibleInputValue);
            }

            if (this.lastSelectedLabel !== visibleInputValue && this.formControl.value) {
                this.formControl.setValue(undefined);
            }

            if (!this.requireSelection) {
                this.formControl.setValue(visibleInputValue);
            }

            this.onChange(this.formControl.value);
        });
    }

    public onInputChange(inputEvent: Event): void {
        // if we use the requireSelection directive the value wont be updated as long as we dont select something
        // we now manually update the internal value here
        if (this.isRequired) {
            this.internalAutocompleteControl.setValue((inputEvent.target as HTMLInputElement).value);
        }
    }

    public onBlur(): void {
        this.elementBlurred.emit();
    }

    public onResetButtonClick(): void {
        this.autocomplete?.closePanel();
        this.internalAutocompleteControl.reset();
        this.inputChanges.emit(undefined);
    }

    // when an option is selected we fill the visible input with a string
    // but set the actual formControl value to the event value when selection is required
    public onOptionSelected(event: MatAutocompleteSelectedEvent): void {
        const selectedOption: AutocompleteOption<T> = event.option.value;
        const transformedValue = this.optionsValueTransformer(selectedOption);

        this.lastSelectedLabel = transformedValue;
        this.internalAutocompleteControl.setValue(transformedValue);

        this.formControl.setValue(selectedOption.value);
        this.onChange(this.formControl.value);
        this.optionSelected.emit();
    }

    public override writeValue(value: string): void {
        this.internalAutocompleteControl.setValue(value);
    }
}
