import { formatDate } from '@angular/common';
import { Component, inject, LOCALE_ID } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UiDateComponent } from '@big-direkt/ui/date';
import type { MessageTemplateResult } from '@big-direkt/ui/error';
import { UiFormRowComponent } from '@big-direkt/ui/form-row';
import { Nl2brPipe } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { TextfieldBaseComponent } from '../../../base-components/textfield-base/textfield-base.component';
import { FieldTrackingDirective } from '../../../directives/field-tracking/field-tracking.directive';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    standalone: true,
    selector: 'big-form-date',
    templateUrl: './date.component.html',
    imports: [ReactiveFormsModule, UiDateComponent, UiFormRowComponent, Nl2brPipe, TranslocoDirective, FieldTrackingDirective],
})
export class DateComponent extends TextfieldBaseComponent {
    private readonly locale = inject(LOCALE_ID);
    private readonly dateFormat = 'dd.MM.yyyy';

    public errorOverrides = {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        required: 'ftbForm.validation.datefield.invalid',
        mask: 'ftbForm.validation.datefield.invalid',
        matDatepickerParse: 'ftbForm.validation.datefield.invalid',
        matDatepickerMin: (errorDetails: { min: string }): MessageTemplateResult => ({
            i18nKey: 'ftbForm.validation.datefield.min',
            params: { min: formatDate(errorDetails.min, this.dateFormat, this.locale) },
        }),
        matDatepickerMax: (errorDetails: { max: string }): MessageTemplateResult => ({
            i18nKey: 'ftbForm.validation.datefield.max',
            params: { max: formatDate(errorDetails.max, this.dateFormat, this.locale) },
        }),
    };

    public static register(): ComponentMap {
        return {
            date: DateComponent,
        };
    }

    public updateErrors(errors: Record<string, unknown> | null): void {
        // eslint-disable-next-line no-null/no-null
        this.control?.setErrors(errors);
    }
}
