import { type FormConfigElement } from '../form-config-element/form-config-element.model';
import { MARKUP_TYPES } from '../markup.types';

export abstract class PageHierarchyNode {
    protected _children: PageHierarchyNode[] = [];

    private _isPreview = false;
    private _hasInputs = false;
    private _hasVisibleMarkup = false;

    public get children(): PageHierarchyNode[] {
        return this._children;
    }

    public get hasInputs(): boolean {
        return this._hasInputs;
    }

    public get hasVisibleMarkup(): boolean {
        return this._hasVisibleMarkup;
    }

    public get isPreview(): boolean {
        return this._isPreview;
    }

    public get isSubmitPage(): boolean {
        return this.settings.isSubmitPage ?? false;
    }

    public get wizardVariant(): string | undefined {
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing,sonarjs/prefer-nullish-coalescing
        return this.settings.wizardVariant || undefined;
    }

    public get hasWizardEntry(): boolean {
        return this.settings.showInWizard ?? false;
    }

    public get isWizardVisible(): boolean {
        return this.wizardVariant !== 'hidden';
    }

    public get wizardLabel(): string | undefined {
        return this.settings.wizardLabel ?? this.settings.title;
    }

    public get previewLabel(): string | undefined {
        return this.settings.previewLabel ?? this.settings.title;
    }

    public get firstChild(): PageHierarchyNode | undefined {
        return this.children[0];
    }

    public get lastChild(): PageHierarchyNode | undefined {
        return this.children[this.children.length - 1];
    }

    public get firstLeaf(): PageHierarchyNode {
        return this.firstChild?.firstLeaf ?? this;
    }

    public get lastLeaf(): PageHierarchyNode {
        return this.lastChild?.lastLeaf ?? this;
    }

    public get isLeaf(): boolean {
        return this.children.length === 0;
    }

    public get nextNode(): PageHierarchyNode | undefined {
        return (
            this.firstChild ?? this.ancestorsAndSelf.reverse().find((node: PageHierarchyNode): PageHierarchyNode | undefined => node.nextSibling)?.nextSibling
        );
    }

    public get nextLeaf(): PageHierarchyNode | undefined {
        const nextNode: PageHierarchyNode | undefined = this.nextNode;

        if (!nextNode) {
            return undefined;
        }

        return nextNode.isLeaf ? nextNode : nextNode.nextLeaf;
    }

    public get previousNode(): PageHierarchyNode | undefined {
        return this.previousSibling?.lastLeaf ?? this.parent;
    }

    public get previousLeaf(): PageHierarchyNode | undefined {
        const previousNode: PageHierarchyNode | undefined = this.previousNode;

        if (!previousNode) {
            return undefined;
        }

        return previousNode.isLeaf ? previousNode : previousNode.previousLeaf;
    }

    public get leafs(): PageHierarchyNode[] {
        if (this.isLeaf) {
            return [this];
        }

        return this.children.reduce((leafs: PageHierarchyNode[], child: PageHierarchyNode): PageHierarchyNode[] => {
            if (child.isLeaf) {
                return [...leafs, child];
            }

            return [...leafs, ...child.leafs];
        }, []);
    }

    public get isAccessible(): boolean {
        if (this.isDisabled(this.settings)) {
            return false;
        }

        return !this.isAnyParentHidden(this.settings);
    }

    public get level(): number {
        if (!this.parent) {
            return 0;
        }

        return this.parent.level + 1;
    }

    public get descendantsAndSelf(): PageHierarchyNode[] {
        const descendants: PageHierarchyNode[] = this.children.reduce(
            (nodes: PageHierarchyNode[], child: PageHierarchyNode): PageHierarchyNode[] => [...nodes, ...child.descendantsAndSelf],
            [],
        );

        return [this, ...descendants];
    }

    public get ancestorsAndSelf(): PageHierarchyNode[] {
        if (!this.parent) {
            return [this];
        }

        return [...this.parent.ancestorsAndSelf, this];
    }

    private get nextSibling(): PageHierarchyNode | undefined {
        if (!this.parent) {
            return undefined;
        }

        const index: number = this.parent.children.indexOf(this);

        if (index < 0) {
            return undefined;
        }

        return this.parent.children[index + 1];
    }

    protected get previousSibling(): PageHierarchyNode | undefined {
        if (!this.parent) {
            return undefined;
        }

        const index: number = this.parent.children.indexOf(this);

        if (index < 0) {
            return undefined;
        }

        return this.parent.children[index - 1];
    }

    public constructor(
        public readonly settings: FormConfigElement,
        public readonly parent?: PageHierarchyNode,
    ) {}

    public abstract createNode(settings: FormConfigElement, parent: PageHierarchyNode): PageHierarchyNode;

    public hasDescendant(node: PageHierarchyNode): boolean {
        for (const child of this.children) {
            if (child === node) {
                return true;
            }

            if (child.hasDescendant(node)) {
                return true;
            }
        }

        return false;
    }

    public isBefore(node: PageHierarchyNode): boolean {
        if (node === this) {
            return false;
        }

        const nextNode: PageHierarchyNode | undefined = this.nextNode;

        if (!nextNode) {
            return false;
        }

        if (node === nextNode) {
            return true;
        }

        return nextNode.isBefore(node);
    }

    public isAfter(node: PageHierarchyNode): boolean {
        if (node === this) {
            return false;
        }

        const previousNode: PageHierarchyNode | undefined = this.previousNode;

        if (!previousNode) {
            return false;
        }

        if (node === previousNode) {
            return true;
        }

        return previousNode.isAfter(node);
    }

    // eslint-disable-next-line @typescript-eslint/prefer-return-this-type
    public findAncestorByLevel(level: number): PageHierarchyNode | undefined {
        if (this.level < level) {
            return undefined;
        }

        if (this.level === level) {
            return this;
        }

        return this.parent?.findAncestorByLevel(level);
    }

    /**
     * We need this for tracking purpose. This is the only way to differ field children in repeatable / multiple groups
     * @returns Current position of a multiple element's node. If not: undefined
     */
    public getMultiplePageIndex(): number | undefined {
        let match: number | undefined;

        const iterateThroughParents = (node: FormConfigElement | undefined): void => {
            if (!node) {
                return;
            }

            if (node.type === 'webform_multiple_item') {
                match = parseInt(node.name, 10);

                return;
            }

            if (node.parent) {
                iterateThroughParents(node.parent);
            }
        };

        iterateThroughParents(this.settings);

        return match;
    }

    public addPageNode(settings: FormConfigElement, predecessor: FormConfigElement): void {
        if (!settings.isPageElement) {
            return;
        }
        const index = this.children.findIndex(node => node.settings === predecessor);
        // insert item after predecessor
        this.children.splice(index + 1, 0, this.createNode(settings, this));
    }

    public deletePageNode(name: string): void {
        const index = this.children.findIndex(node => node.settings.name === name);
        this.children.splice(index, 1);
    }

    protected abstract isDisabled(settings: FormConfigElement | undefined): boolean;
    protected abstract isInput(child: FormConfigElement): boolean;

    protected parseChildren(settings: FormConfigElement): PageHierarchyNode[] {
        if (Array.isArray(settings.children)) {
            // eslint-disable-next-line sonarjs/function-return-type
            return settings.children.flatMap((child: FormConfigElement): PageHierarchyNode | PageHierarchyNode[] => {
                if (child.isPageElement) {
                    return this.createNode(child, this);
                }

                this._hasInputs = this._hasInputs || this.isInput(child);
                this._hasVisibleMarkup =
                    this._hasVisibleMarkup || (MARKUP_TYPES.includes(child.type) && child.showMarkupOn === 'all') || child.showMarkupOn === 'preview';
                this._isPreview = this._isPreview || child.type === 'big_webform_preview';

                return this.parseChildren(child);
            });
        }

        return [];
    }

    private isAnyParentHidden({ parent: node }: FormConfigElement): boolean {
        if (!node) {
            return false;
        }

        return this.isDisabled(node) || this.isAnyParentHidden(node);
    }
}
