import { A11yModule } from '@angular/cdk/a11y';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumSchliessen, IconComponent } from '@big-direkt/utils/icons';
import { BreakpointService } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgPopoverRef } from 'ng-overlay-container';
import { type FormCloseOverlayData } from '../../models/form-close-overlay-data.model';

@Component({
    selector: 'big-ui-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    templateUrl: './modal-form-close.component.html',
    imports: [A11yModule, AsyncPipe, IconComponent, TranslocoDirective, UiButtonComponent],
    providers: [provideTranslationScope('uiModalFormClose', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
})
export class ModalFormCloseComponent {
    private readonly breakpointService = inject(BreakpointService);

    public readonly isMobile = this.breakpointService.isMobile;
    public readonly popoverRef = inject<NgPopoverRef<FormCloseOverlayData>>(NgPopoverRef);
    public readonly iconClose = IconBigMediumSchliessen;
}
