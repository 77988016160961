import { Component } from '@angular/core';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigLightPasswortAusblenden, IconBigLightPasswortEinblenden } from '@big-direkt/utils/icons';
import { TextfieldBaseComponent } from '../../../base-components/textfield-base/textfield-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-password',
    templateUrl: './password.component.html',
    providers: [provideTranslationScope('ftbPassword', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
})
export class PasswordComponent extends TextfieldBaseComponent {
    public readonly iconShow = IconBigLightPasswortEinblenden;
    public readonly iconHide = IconBigLightPasswortAusblenden;

    public errorOverrides = {
        // eslint-disable-next-line sonarjs/no-hardcoded-credentials
        passwordsDoNotMatch: 'ftbForm.validation.password.passwordsDoNotMatch',
        pattern: 'ftbForm.validation.password.pattern',
    };

    public showPassword = false;

    public static register(): ComponentMap {
        return {
            password: PasswordComponent,
        };
    }
}
