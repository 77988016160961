<ng-container *transloco="let t; prefix: 'ftbFileUpload'">
    <big-ui-form-row
        class="mb-4"
        [showInfo]="!!settings.help"
        [id]="settings.id"
        [infoTextTemplate]="help"
        [label]="label"
        [isRequired]="control?.isRequired"
        [errors]="control?.errors"
        [errorOverrides]="errorOverrides"
        [showError]="isInvalid"
        (infoLayerOpened)="trackFormsInfoIconEvent($event)">
        @if (!isItemLimitReached) {
            <div
                class="flex bg-gray-400 border-2 border-gray-300 border-dashed h-28 hover:!border-solid p-0 form-control"
                [ngClass]="{
                    '!border-solid': isHovering,
                    '!border-gray-200': isHovering,
                    '!border-danger': isInvalid,
                    '!border-success': isValid
                }"
                [formFieldTracking]="settings"
                bigSharedDropzone
                (hovered)="toggleHover($event)"
                (dropped)="onFileChange($event)">
                <button
                    class="grow"
                    type="button"
                    (click)="fileInput.click()">
                    <div class="inline-block pointer-events-none">
                        <big-icon
                            [icon]="iconUpload"
                            class="[&>svg]:!w-6 [&>svg]:!h-6 fill-primary" />
                        <div class="text-gray-100">{{ uploadLimit && uploadLimit > 1 ? 'Dateien hochladen' : 'Datei hochladen' }}</div>
                    </div>
                    <input
                        #fileInput
                        class="hidden"
                        [id]="settings.id"
                        type="file"
                        [accept]="settings.fileMimeTypes"
                        (click)="onClickFileUpload($event)"
                        (change)="onFileChange($event.target.files)"
                        [multiple]="isMultiple"
                        [formControl]="control"
                        [appDrupalWebformElementAttributes]="settings"
                        [formFieldTracking]="settings" />
                </button>
            </div>
        }
    </big-ui-form-row>

    <div>
        @if (hasToManyFiles()) {
            <big-ui-alert
                [type]="'warning'"
                [classList]="'flex text-center border-2 rounded-md p-4 mb-4 flex-column items-center'">
                {{ t('maxFiles', { maxFiles: uploadLimit }) }}
            </big-ui-alert>
        }

        @if (0 < control.files.length) {
            <div>
                @for (file of control.files; track itemIndex; let itemIndex = $index) {
                    <div class="mb-6">
                        <div class="flex items-center">
                            @if (httpEventTypes.Response === file.status || file.errorMessage) {
                                <big-icon
                                    [icon]="iconFile"
                                    class="mr-2 text-xl" />
                            }
                            <div class="mr-4 truncate">
                                @if (file.downloadSrc) {
                                    <big-ui-button
                                        [buttonStyle]="'link'"
                                        [classList]="['max-w-full', 'truncate']"
                                        [type]="'button'"
                                        (click)="showModal(file.downloadSrc, file.name)">
                                        {{ file.name }}
                                    </big-ui-button>
                                } @else {
                                    <span>{{ file.name }}</span>
                                }
                            </div>
                            @if (httpEventTypes.Response === file.status || file.errorMessage) {
                                <button
                                    (click)="deleteFile(itemIndex)"
                                    type="button"
                                    class="ml-auto"
                                    [ngClass]="{ 'mr-10 md:mr-14': !!settings?.help }">
                                    <big-icon
                                        [icon]="iconRemove"
                                        class="text-sm fill-primary" />
                                </button>
                            }
                        </div>
                        @if (file.errorMessage) {
                            <div
                                [innerHTML]="file.errorMessage"
                                class="mt-2 text-danger"></div>
                        }
                        @if (extFileUpload && file.status === httpEventTypes.UploadProgress) {
                            <ngb-progressbar
                                [type]="!file.errorMessage ? 'primary' : 'danger'"
                                height="100%"
                                [value]="100"
                                [showValue]="false"
                                [max]="100"
                                [animated]="true"
                                [striped]="true">
                                @if (!file.errorMessage) {
                                    <span>{{ 'components.file.processingUpload' | stringReplacePipe }}</span>
                                }
                            </ngb-progressbar>
                        } @else if (!extFileUpload && file.status !== httpEventTypes.Response) {
                            <ngb-progressbar
                                [type]="!file.errorMessage ? 'primary' : 'danger'"
                                height="100%"
                                [ngClass]="[!file.errorMessage ? '!bg-success' : '!bg-danger']"
                                [value]="file.progress"
                                [showValue]="false"
                                [max]="100"
                                [animated]="true"
                                [striped]="100 === file.progress && !file.errorMessage">
                                @if (100 !== file.progress && !file.errorMessage) {
                                    <span>{{ file.progress }}%</span>
                                } @else if (100 === file.progress && !file.errorMessage) {
                                    <span>{{ 'components.file.processingUpload' | stringReplacePipe }}</span>
                                }
                                @if (file.errorMessage) {
                                    <span>{{ 'components.file.uploadError' | stringReplacePipe }}</span>
                                }
                            </ngb-progressbar>
                        }
                    </div>
                }
            </div>
        }
    </div>
</ng-container>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
