import { Injectable, isDevMode } from '@angular/core';
import { type PlaceholderData, type StringReplacementData } from '../../interfaces/string-replacement.data';

@Injectable({
    providedIn: 'root',
})
export class StringReplaceService {
    private readonly isDevMode: boolean = isDevMode();
    private strings: StringReplacementData = {};

    public initialize(strings: StringReplacementData): void {
        this.strings = strings;
    }

    public get(key: string, placeholders?: PlaceholderData): string {
        const found: string = this.findString(key);

        if (key !== found) {
            return placeholders ? this.replacePlaceholders(found, placeholders) : found;
        }

        if (this.isDevMode) {
            console.warn(`Missing string for key '${key}'`);
        }

        return key;
    }

    private findString(key: string): string {
        let result: StringReplacementData | string = this.strings;

        for (const keyPart of key.split('.')) {
            // eslint-disable-next-line no-extra-parens
            result = (result as StringReplacementData)[keyPart];

            if (!result) {
                return key;
            }
        }

        return typeof result === 'string' ? result : key;
    }

    private replacePlaceholders(str: string, placeholders: PlaceholderData): string {
        const keys: string[] = Object.keys(placeholders);

        if (keys.length === 0) {
            return str;
        }

        const regexp = new RegExp(Object.keys(placeholders).join('|'), 'gm');

        return str.replace(regexp, (match: string): string => String(placeholders[match]));
    }
}
