import { type SubmissionObject } from '../../models/form-submission/submission-object.model';
import { type SubmissionValueAccessorFn, type TypeDef } from '../drupal-webform-types';
import { getNestedSubmissionValue } from '../nested-submission-value.helper';

export const SUBMISSION_VALUE_ACCESSOR_COMPOSITE: SubmissionValueAccessorFn = (v, settings) => {
    const value = v as SubmissionObject;
    const { name } = settings;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parsedData: any = {};
    parsedData[name] = {};

    Object.keys(settings.value).forEach((key: string): void => {
        const compositeKey = `${name}[${key}]`;
        if (value[compositeKey] !== undefined) {
            parsedData[name][compositeKey] = value[compositeKey];
        }
    });

    return parsedData;
};

export const SUBMISSION_MAPPER_COMPOSITE = (data: SubmissionObject, propName: string, arrayParents: string[]): void => {
    const obj = getNestedSubmissionValue(data, propName, ...arrayParents);
    if (obj === undefined) {
        return;
    }
    const composite = obj as SubmissionObject;
    Object.keys(composite).forEach(compositeKey => {
        const key = compositeKey.split('[')[1].split(']')[0];

        const value = composite[compositeKey];
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete composite[compositeKey];
        composite[key] = value;
    });
};

export const TYPE_DEF_COMPOSITE: TypeDef = {
    isInput: false,
    isStructure: true,
    submissionValueAccessor: SUBMISSION_VALUE_ACCESSOR_COMPOSITE,
    submissionMapper: SUBMISSION_MAPPER_COMPOSITE,
};
