@if (title) {
    <label
        class="inline-block mb-2 font-sans leading-6"
        [for]="id">
        {{ title }}
    </label>
}
<div
    *transloco="let tUiSelect; prefix: 'uiSelect'"
    class="relative flex items-center">
    <select
        [id]="id"
        [name]="name"
        [formControl]="formControl"
        [required]="isRequired"
        [attr.disabled]="disabled || optionsDisabled ? true : null"
        [attr.aria-disabled]="disabled || optionsDisabled"
        (change)="onChange(formControl.value)"
        class="form-select"
        [ngClass]="{
            'is-invalid': !isValid && showValidation,
            'is-valid': isValid && showValidation,
            'disabled': disabled || optionsDisabled
        }">
        @if (showNoSelectionOption && !customNoSelectionOption) {
            <option
                class="text-placeholder"
                [ngValue]="''"
                [disabled]="isRequired || optionsDisabled">
                {{ tUiSelect('noSelection') }}
            </option>
        } @else if (showNoSelectionOption && customNoSelectionOption) {
            <option
                class="text-placeholder"
                [ngValue]="customNoSelectionOption.value"
                [disabled]="isRequired || optionsDisabled">
                <ng-container *transloco="let t; read: customNoSelectionOption.scope">
                    {{ disableTransloco ? customNoSelectionOption.key : t(customNoSelectionOption.key) }}
                </ng-container>
            </option>
        }
        @for (option of options; track $index) {
            <option
                class="text-body"
                [ngValue]="option.value"
                [disabled]="option.disabled || optionsDisabled">
                <ng-container *transloco="let t; read: option.scope">
                    {{ disableTransloco ? option.key : t(option.key) }}
                </ng-container>
            </option>
        }
        <ng-content></ng-content>
    </select>
</div>
