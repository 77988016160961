<ng-container *transloco="let t; prefix: 'ftbCheckbox'">
    <big-ui-form-row
        class="[&:not(:last-of-type)]:mb-2"
        [id]="settings.id"
        [errors]="control?.errors"
        [showError]="isInvalid && isSingleItem"
        [errorOverrides]="errorOverrides"
        [showInfo]="!!settings.help"
        [infoTextTemplate]="help"
        (infoLayerOpened)="trackFormsInfoIconEvent($event)">
        <big-ui-checkbox
            [id]="settings.id"
            [title]="label"
            [value]="settings.returnValue"
            [isDisabled]="isDisabled"
            [isRequired]="control?.isRequired"
            [isValid]="isValid"
            [isInvalid]="isInvalid"
            [formControl]="control"
            [formFieldTracking]="settings" />
    </big-ui-form-row>
</ng-container>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
