<div
    *transloco="let t; prefix: 'uiModalSickNoteDetails'"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true"
    class="flex flex-col h-full">
    <div class="bg-gray-400">
        <div class="flex p-8 print:p-0 print:mb-8">
            <div class="flex-1">
                <h4 class="mb-3 text-primary">{{ sickNote.diagnosis.text }}</h4>
                <p class="mb-0 font-bold">
                    {{ t('benefitStart', { date: sickNote.benefit.start | date }) }} | {{ t('benefitEnd', { date: sickNote.benefit.end | date }) }}
                </p>
            </div>
            <big-ui-button
                [buttonStyle]="'icon'"
                [id]="'close-overlay-icon'"
                (clicked)="popoverRef.close()"
                [title]="t('closeOverlayBtn')"
                [classList]="['p-2', 'flex', '-translate-y-2', 'print:hidden']">
                <big-icon
                    [icon]="iconClose"
                    class="primary"
                    [attr.aria-label]="t('closeOverlayBtn')" />
            </big-ui-button>
        </div>
    </div>
    <div class="grid flex-1 grid-cols-1 gap-8 p-8 print:p-0">
        <!-- Diagnosen -->
        <div>
            <p class="mb-2">
                <strong>{{ t('diagnoses.title') }}</strong>
            </p>
            <p class="mb-2">{{ t('diagnoses.code') }} {{ sickNote.diagnosis.code }}</p>
            <ul class="grid col-span-1 gap-2 mb-0">
                <ng-container *ngTemplateOutlet="listItem; context: { title: sickNote.typeOfCertificate }" />

                @if (sickNote.workOrOtherAccitent) {
                    <ng-container *ngTemplateOutlet="listItem; context: { title: t('diagnoses.workOrOtherAccitent') }" />
                }

                @if (sickNote.transitDoctorAssigned) {
                    <ng-container
                        *ngTemplateOutlet="
                            listItem;
                            context: {
                                title: t('diagnoses.transitDoctorAssigned'),
                                id: 'transit-doctor-assigned',
                                infoText: t('diagnoses.transitDoctorAssignedInfoText'),
                            }
                        " />
                }
            </ul>
        </div>

        <!-- Diagnose Zusatz -->
        @if (sickNote.otherAccident || sickNote.suffering) {
            <div>
                <p class="mb-2">
                    <strong>{{ t('diagnosisAddition.title') }}</strong>
                </p>
                <ul class="grid col-span-1 gap-2 mb-0">
                    @if (sickNote.otherAccident) {
                        <ng-container *ngTemplateOutlet="listItem; context: { title: t('diagnosisAddition.otherAccident') }" />
                    }
                    @if (sickNote.suffering) {
                        <ng-container
                            *ngTemplateOutlet="
                                listItem;
                                context: {
                                    title: t('diagnosisAddition.suffering'),
                                    id: 'suffering',
                                    infoText: t('diagnosisAddition.sufferingInfoText'),
                                }
                            " />
                    }
                </ul>
            </div>
        }

        <!-- Einleitung folgender Maßnahmen -->
        @if (sickNote.medicalRehabilitation || sickNote.gradualReintegration) {
            <div>
                <p class="mb-2">
                    <strong>{{ t('furtherMeasures.title') }}</strong>
                </p>
                <ul class="grid col-span-1 gap-2 mb-0">
                    @if (sickNote.medicalRehabilitation) {
                        <ng-container
                            *ngTemplateOutlet="
                                listItem;
                                context: {
                                    title: t('furtherMeasures.medicalRehabilitation'),
                                    id: 'medical-rehabilitation',
                                    infoText: t('furtherMeasures.medicalRehabilitationInfoText'),
                                }
                            " />
                    }
                    @if (sickNote.gradualReintegration) {
                        <ng-container
                            *ngTemplateOutlet="
                                listItem;
                                context: {
                                    title: t('furtherMeasures.gradualReintegration'),
                                    id: 'gradual-reintegration',
                                    infoText: t('furtherMeasures.gradualReintegrationInfoText'),
                                }
                            " />
                    }
                </ul>
            </div>
        }

        <!-- Im Krankengeldfall -->
        @if (sickNote.sicknessMoney || sickNote.finalCertificate) {
            <div>
                <p class="mb-2">
                    <strong>{{ t('sicknessBenefit.title') }}</strong>
                </p>
                <ul class="grid col-span-1 gap-2 mb-0">
                    @if (sickNote.sicknessMoney) {
                        <ng-container *ngTemplateOutlet="listItem; context: { title: t('sicknessBenefit.sicknessMoney') }" />
                    }
                    @if (sickNote.finalCertificate) {
                        <ng-container
                            *ngTemplateOutlet="
                                listItem;
                                context: {
                                    title: t('sicknessBenefit.finalCertificate'),
                                    id: 'final-certificate',
                                    infoText: t('sicknessBenefit.finalCertificateInfoText'),
                                }
                            " />
                    }
                </ul>
            </div>
        }

        <!-- Ausstellender Arzt -->
        <div>
            <p class="mb-2">
                <strong>{{ t('issuingDoctor.title') }}</strong>
            </p>
            <p class="mb-0">
                {{ t('issuingDoctor.name', { title: sickNote.doctor.title, firstName: sickNote.doctor.firstName, lastName: sickNote.doctor.lastName }) }}
                <br />
                {{ t('issuingDoctor.number', { number: sickNote.doctor.number }) }}
                <br />
                {{ t('issuingDoctor.workplaceNumber', { workspaceNumber: sickNote.doctor.workplaceNumber }) }}
            </p>
        </div>

        <div class="print:hidden">
            <big-ui-button
                [buttonStyle]="'primary'"
                [classList]="['flex', 'items-center']"
                (clicked)="printSickNote($event)">
                <big-icon
                    [icon]="iconDownload"
                    class="fill-white [&>svg]:!w-auto [&>svg]:!h-5 mr-2"
                    [attr.aria-label]="t('downloadBtn')" />
                {{ t('downloadBtn') }}
            </big-ui-button>
        </div>
    </div>
    <div class="p-8 bg-gray-400 border-t border-gray-300 print:hidden">
        <button
            (click)="popoverRef.close()"
            role="button"
            class="flex items-center gap-2 mx-auto text-primary">
            <big-icon
                [icon]="iconClose"
                class="primary"
                [attr.aria-label]="t('closeOverlayBtn')" />
            {{ t('closeOverlayBtn') }}
        </button>
    </div>
</div>

<ng-template
    #listItem
    let-title="title"
    let-id="id"
    let-infoText="infoText">
    <li class="group">
        <div class="flex">
            <div class="w-5 mr-4">
                <big-icon
                    [icon]="iconCheckMark"
                    class="w-5 h-5 primary [&>svg]:!w-full [&>svg]:!h-auto translate-y-0.5" />
            </div>
            <span class="-translate-y-0.5">{{ title }}</span>
            @if (id && infoText) {
                <big-ui-info-button
                    [scrollAnchorId]="id"
                    (infoLayerOpened)="onInfoLayerOpen($event, id)"
                    class="ml-4 print:hidden"></big-ui-info-button>
            }
        </div>

        @if (openInfoTextIds().includes(id) && infoText) {
            <div
                [id]="id"
                class="p-3 mt-2 mb-2 bg-gray-400 border-2 border-gray-300 rounded-3 group-last:mb-0 print:hidden">
                {{ infoText }}
            </div>
        }
    </li>
</ng-template>
