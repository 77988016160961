import { Pipe, type PipeTransform } from '@angular/core';
import { TokenService } from '../../services/token/token.service';

@Pipe({
    name: 'replaceTokenRef',
    // eslint-disable-next-line @angular-eslint/no-pipe-impure
    pure: false,
    standalone: true,
})
export class ReplaceTokenRefPipe implements PipeTransform {
    public constructor(private readonly tokenService: TokenService) {}

    public transform(value = '', parents: string[] = []): string {
        return this.tokenService.findReplacement(value, parents);
    }
}
