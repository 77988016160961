@if (control?.enabled) {
    <div class="form-check">
        <input
            [id]="settings.id"
            class="form-check-input"
            type="radio"
            [name]="settings.name"
            [value]="settings.returnValue"
            [formControl]="control"
            [ngClass]="{
                'is-valid': isValid,
                'is-invalid': isInvalid
            }"
            [required]="control?.isRequired"
            [formFieldTracking]="settings" />
        <big-form-label [settings]="settings" />
    </div>
}
