import { Component, ViewChild, inject } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { KeyValuePair } from '@big-direkt/form/contracts';
import { type AutocompleteOption } from '@big-direkt/ui/input';
import { SelectBaseComponent } from '../../../base-components/select-base/select-base.component';
import { FilterOptionsService } from '../../../services/filter-options.service';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

// eslint-disable-next-line @angular-eslint/prefer-standalone-component
@Component({
    selector: 'big-form-searchable-select',
    templateUrl: './searchable-select.component.html',
})
export class SearchableSelectComponent extends SelectBaseComponent {
    private readonly filterOptionsService = inject(FilterOptionsService);

    @ViewChild(MatAutocompleteTrigger) public autocomplete?: MatAutocompleteTrigger;

    public searchableOptions: AutocompleteOption<string>[] = [];
    public errorOverrides = { required: 'ftbForm.validation.mustBeAValueFromTheList' };

    public static register(): ComponentMap {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            big_webform_searchable_select: SearchableSelectComponent,
        };
    }

    public override init(): void {
        super.init();

        this.searchableOptions = (this.settings.options ?? []).map((option: KeyValuePair) => ({
            ...option,
            label: option.value,
        }));
    }

    public onAutocompleteInputChange(value: string): void {
        this.searchableOptions = this.filterOptionsService.filterByTerm(value, this.settings.options ?? []);
    }

    public transformOptionValue(option: AutocompleteOption<string>): string {
        return option.label;
    }
}
