import { type AbstractControl, type ValidationErrors } from '@angular/forms';
import { BigUntypedFormGroup } from '../../form-control/big-form-group';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class FormGroupRequiredValidator {
    public static validate(formGroup: AbstractControl): ValidationErrors | null {
        const hasValue: boolean = FormGroupRequiredValidator.hasValue(formGroup);

        FormGroupRequiredValidator.updateErrors(formGroup, hasValue);

        if (hasValue) {
            // eslint-disable-next-line no-null/no-null
            return null;
        }

        return {
            required: {
                valid: false,
            },
        };
    }

    public static unsetErrors(formGroup: BigUntypedFormGroup): void {
        FormGroupRequiredValidator.updateErrors(formGroup, true);
    }

    private static hasValue(control: AbstractControl): boolean {
        if (control instanceof BigUntypedFormGroup) {
            for (const childControl of Object.values(control.controls)) {
                if (FormGroupRequiredValidator.hasValue(childControl)) {
                    return true;
                }
            }

            return false;
        }

        return !!control.value;
    }

    private static updateErrors(control: AbstractControl, hasValue: boolean): void {
        if (control instanceof BigUntypedFormGroup) {
            for (const childControl of Object.values(control.controls)) {
                FormGroupRequiredValidator.updateErrors(childControl, hasValue);
            }

            return;
        }

        // We only set an error if there is no other errors
        if (!hasValue && !control.errors) {
            control.setErrors({}); // Setting an empty object defines an error without message

            return;
        }

        // We only unset errors if the errors object is empty (has no keys)
        if (hasValue && control.errors && !Object.keys(control.errors).length) {
            // eslint-disable-next-line no-null/no-null
            control.setErrors(null);
        }
    }
}
