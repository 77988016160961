import { formatDate, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, EventEmitter, HostBinding, inject, input, LOCALE_ID, Output, signal, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumKalender, IconComponent } from '@big-direkt/utils/icons';
import { BaseControlValueAccessor } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@Component({
    selector: 'big-ui-date',
    standalone: true,
    templateUrl: './date.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        FormsModule,
        IconComponent,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInput,
        NgClass,
        NgxMaskDirective,
        ReactiveFormsModule,
        TranslocoDirective,
    ],
    providers: [
        provideNativeDateAdapter(),
        provideNgxMask(),
        provideTranslationScope('uiDate', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`)),
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DateComponent,
            multi: true,
        },
    ],
})
export class DateComponent extends BaseControlValueAccessor<string> {
    private readonly local = inject(LOCALE_ID);
    public readonly datePickerControl = new FormControl('', []);
    public readonly formControlChanges = toSignal(this.formControl.valueChanges);
    public readonly hasFocus = signal(false);
    public readonly iconCalendar = IconBigMediumKalender;
    public readonly id = input.required<string>();
    public readonly isInvalid = input.required<boolean>();
    public readonly isRequired = input.required<boolean>();
    public readonly isValid = input<boolean>(false);
    public readonly maxDate = input<Date>(new Date());
    public readonly minDate = input<Date>(this.defaultMinDate());
    public readonly showMaskTyped = input<boolean>(false);
    public readonly showValidation = input<boolean>(false);

    @Output() public readonly errors = new EventEmitter<Record<string, unknown> | null>();

    @HostBinding('class') public classList = 'block';

    @ViewChild('datePicker') public datePicker!: MatDatepicker<Date>;

    public constructor() {
        super();

        effect(
            () => {
                // eslint-disable-next-line no-null/no-null
                const value = this.formControlChanges() ?? null;
                const [day, month, year] = value?.split('.') ?? [];

                this.datePickerControl.setValue(`${year}-${month}-${day}`);
                this.datePickerControl.updateValueAndValidity();
                this.formControl.updateValueAndValidity();
                this.onChange(value);
                this.emitErrors();
            },
            { allowSignalWrites: true },
        );
    }

    public openDatepicker(event: Event): void {
        event.preventDefault();

        this.datePicker.open();
    }

    public datePickerClose(): void {
        const dateValue = this.datePickerControl.value;

        if (!dateValue) {
            this.formControl.setValue('');

            return;
        }

        try {
            const dateObject = new Date(dateValue);

            this.formControl.setValue(formatDate(dateObject, 'dd.MM.YYYY', this.local));
        } catch {
            this.formControl.setValue('');

            return;
        }
    }

    private emitErrors(): void {
        const errors = {
            ...this.datePickerControl.errors,
            ...this.formControl.errors,
        };

        if (!this.isRequired() && !this.formControl.value) {
            // eslint-disable-next-line no-null/no-null
            this.errors.emit(null);

            return;
        }

        // eslint-disable-next-line no-null/no-null
        this.errors.emit(Object.keys(errors).length ? errors : null);
    }

    private defaultMinDate(): Date {
        const currentDate = new Date();

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return new Date(currentDate.getFullYear() - 100, currentDate.getMonth(), currentDate.getDate());
    }
}
