import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EventBusService } from '@big-direkt/event-bus';

export interface MultipleGroupHandler {
    onItemAdded: (index: number) => void;
    onItemRemoved: (index: number) => void;
}

@Injectable({
    providedIn: 'root',
})
export class MultipleGroupService {
    private readonly eventBus = inject(EventBusService);
    private readonly destroyRef = inject(DestroyRef);
    private multipleGroupHandlerRegistry: Record<string, MultipleGroupHandler[]> = {};
    private addedItems: string[] = [];

    public constructor() {
        this.eventBus.on('form_unloaded').pipe(
            takeUntilDestroyed(this.destroyRef),
        ).subscribe(() => {
            this.multipleGroupHandlerRegistry = {};
            this.addedItems = [];
        });
    }

    public subscribe(groupKey: string, handler: MultipleGroupHandler): void {
        if (groupKey in this.multipleGroupHandlerRegistry) {
            this.multipleGroupHandlerRegistry[groupKey].push(handler);
        } else {
            this.multipleGroupHandlerRegistry[groupKey] = [handler];
        }
        this.addedItems
            .filter(x => x === groupKey)
            .forEach((_, index) => {
                handler.onItemAdded(index);
            });
    }

    public unsubscribe(groupKey?: string): void {
        if (!groupKey) {
            return;
        }
        if (groupKey in this.multipleGroupHandlerRegistry) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete this.multipleGroupHandlerRegistry[groupKey];
        }

        this.addedItems = this.addedItems.filter(x => x !== groupKey);
    }

    public handleGroupItemAdded(groupKey: string, id: number): void {
        this.addedItems.push(groupKey);
        if (groupKey in this.multipleGroupHandlerRegistry) {
            this.multipleGroupHandlerRegistry[groupKey].forEach(handler => {
                handler.onItemAdded(id);
            });
        }
    }

    public handleGroupItemRemoved(groupKey: string, index: number): void {
        this.addedItems.splice(index, 1);
        if (groupKey in this.multipleGroupHandlerRegistry) {
            this.multipleGroupHandlerRegistry[groupKey].forEach(handler => {
                handler.onItemRemoved(index);
            });
        }
    }
}
