import { Injectable, inject } from '@angular/core';
import { type FormConfigElement } from '@big-direkt/form/contracts';
import { FormService } from '../form/form.service';
import { type ConditionalStateCallbackType } from './conditional-state-callback';
import { ConditionalStateParserService } from './conditional-state-parser.service';

@Injectable({
    providedIn: 'root',
})
export class ConditionalFieldsService {
    private readonly formService = inject(FormService);
    private readonly stateParser = inject(ConditionalStateParserService);

    public createStateHandlers(...settings: FormConfigElement[]): void {
        settings.forEach(s => {
            this.createStateHandlersRecursively(s);
        });
    }

    private createStateHandlersRecursively(formElement: FormConfigElement, currentForm?: FormConfigElement): void {
        if (formElement.type === 'big_webform_page_repeating') {
            return;
        }
        if (formElement.states && this.formService.getFormControl(formElement.arrayParents)) {

            for (const stateType of Object.keys(formElement.states)) {
                this.stateParser.buildStateHandler(formElement, stateType as ConditionalStateCallbackType);
            }
        }

        // We handle embedded webforms as independent entities by overriding the 'currentForm' element (scope of search)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const context: FormConfigElement = formElement.type === 'big_webform_embedded_form' ? formElement : currentForm!;

        formElement.children.forEach(child => {
            this.createStateHandlersRecursively(child, context);
        });
    }
}
