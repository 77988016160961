import { type SubmissionValueState } from '../../models/form-submission/submission-value-state.model';
import { type AccessorFn } from '../drupal-webform-types';

export const VALUE_ACCESSOR_CHECKBOX: AccessorFn = value => {
    if ((value as SubmissionValueState | undefined)?.isDisabled) {
        return undefined;
    }

    return value ? 'Ja' : 'Nein';
};
