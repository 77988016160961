import { inject, Injectable } from '@angular/core';
import { SearchResult, SearchService } from '@big-direkt/search';
import { AutocompleteOption } from '@big-direkt/ui/input';
import { UiResultFilterModel } from '@big-direkt/ui/result-filter';
import { LinkListItem, ParagraphTypes } from '@big-direkt/utils/shared';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ContactFormSearchService {
    private readonly maxSearchResults = 5;
    private readonly maxSuggestions = 20;
    private readonly maxDefaultOptions = 4;

    private readonly suggestionFacets: UiResultFilterModel[] = [
        {
            facet: 'category',
            isActive: true,
            title: 'Leistungen',
        },
        {
            facet: 'category',
            isActive: true,
            title: 'meineBIG',
        },
    ];

    private readonly resultFacets: UiResultFilterModel[] = [
        {
            facet: 'category',
            isActive: true,
            title: 'meineBIG',
        },
    ];

    public readonly searchService = inject(SearchService);

    public getPromotedContactTopics(): Observable<SearchResult> {
        const sortByTitleAsc = ['title:asc'];

        return this.triggerMeilisearch('*', this.maxDefaultOptions, [], 'promoted_contact_topic = true', sortByTitleAsc);
    }

    public getResults(text: string): Observable<SearchResult | null> {
        if (!text) {
            // eslint-disable-next-line no-null/no-null
            return of(null);
        }

        return this.triggerMeilisearch(text, this.maxSearchResults, this.resultFacets);
    }

    public getSuggestions(text: Record<string, string> | string | undefined | null): Observable<SearchResult | null> {
        if (!text || typeof text !== 'string') {
            // eslint-disable-next-line no-null/no-null
            return of(null);
        }

        return this.triggerMeilisearch(text, this.maxSuggestions, this.suggestionFacets);
    }

    public mapToLinkItems(result: SearchResult): LinkListItem[] {
        return result.items.map(item => ({
            type: ParagraphTypes.LinkListItem,
            href: item.url,
            title: item.title,
        }));
    }

    public transformToOptions(result: SearchResult): AutocompleteOption<string>[] {
        return result.items.map(item => ({
            label: item.title,
            key: item.title,
            value: item.title,
        }));
    }

    private triggerMeilisearch(
        text: string,
        maxAmount: number,
        facets: UiResultFilterModel[],
        customFilter?: string,
        customSort?: string[],
    ): Observable<SearchResult> {
        return this.searchService.search(text, 0, maxAmount, facets, customFilter, customSort);
    }
}
