/* eslint-disable
  @typescript-eslint/unbound-method,
  @typescript-eslint/no-explicit-any,
  no-null/no-null
*/
import { type BigUntypedFormControl } from '../../../form-control/big-form-control';
import { type BooleanEvaluator } from './boolean-evaluator';

export class ConditionNode implements BooleanEvaluator {
    private readonly control: BigUntypedFormControl;
    private readonly evaluatorFn: (value: any, reference?: any) => boolean;
    private readonly referenceValue?: boolean | number | string;

    private constructor(control: BigUntypedFormControl, evaluatorFn: (value: any, reference?: any) => boolean, referenceValue?: boolean | number | string) {
        this.control = control;
        this.evaluatorFn = evaluatorFn;
        this.referenceValue = referenceValue;
    }

    public static create(control: BigUntypedFormControl, conditionDef: any): ConditionNode {
        if (undefined !== conditionDef.checked) {
            return new ConditionNode(control, ConditionNode.checked);
        }

        if (undefined !== conditionDef.unchecked) {
            return new ConditionNode(control, ConditionNode.unchecked);
        }

        if (undefined !== conditionDef.filled) {
            return new ConditionNode(control, ConditionNode.filled);
        }

        if (undefined !== conditionDef.empty) {
            return new ConditionNode(control, ConditionNode.empty);
        }

        if (undefined !== conditionDef['!value']) {
            return new ConditionNode(control, ConditionNode.unequal, conditionDef['!value']);
        }

        if (undefined !== conditionDef.value) {
            if (undefined !== conditionDef.value.pattern) {
                return new ConditionNode(control, ConditionNode.match, conditionDef.value.pattern);
            }

            if (undefined !== conditionDef.value['!pattern']) {
                return new ConditionNode(control, ConditionNode.mismatch, conditionDef.value['!pattern']);
            }

            if (undefined !== conditionDef.value.less) {
                return new ConditionNode(control, ConditionNode.less, parseFloat(conditionDef.value.less.replace(',', '.')));
            }

            if (undefined !== conditionDef.value.greater) {
                return new ConditionNode(control, ConditionNode.greater, parseFloat(conditionDef.value.greater.replace(',', '.')));
            }

            return new ConditionNode(control, ConditionNode.equal, conditionDef.value);
        }

        throw new Error('Given condition is not supported');
    }

    public evaluate(): boolean {
        return this.evaluatorFn(this.control.value, this.referenceValue);
    }

    private static checked(value: any): boolean {
        return value === true;
    }

    private static unchecked(value: any): boolean {
        return value === false;
    }

    private static filled(value: any): boolean {
        return value !== null && value !== '' && value !== undefined;
    }

    private static empty(value: any): boolean {
        return value === null || value === '';
    }

    private static equal(value: any, reference: any): boolean {
        return value === reference;
    }

    private static unequal(value: any, reference: any): boolean {
        return value !== reference;
    }

    private static less(value: any, reference: number): boolean {
        return ConditionNode.filled(value) && value < reference;
    }

    private static greater(value: any, reference: number): boolean {
        return ConditionNode.filled(value) && value > reference;
    }

    private static match(value: any, pattern: string): boolean {
        return typeof value === 'string' && RegExp(pattern).exec(value) !== null;
    }

    private static mismatch(value: any, pattern: string): boolean {
        return typeof value !== 'string' || RegExp(pattern).exec(value) === null;
    }
}
