import { Component, HostBinding, Input } from '@angular/core';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { StructureBaseComponent } from '../../../base-components/structure-base/structure-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-fieldset',
    templateUrl: './fieldset.component.html',
    providers: [provideTranslationScope('ftbFieldset', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
})
export class FieldsetComponent extends StructureBaseComponent {
    @HostBinding('class') @Input() public override classList = 'block [&:not(:last-child)]:mb-14';

    public static register(): ComponentMap {
        return {
            fieldset: FieldsetComponent,
        };
    }
}
