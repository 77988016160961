/* eslint-disable @typescript-eslint/no-magic-numbers */
import { type AbstractControl, type ValidationErrors, type ValidatorFn } from '@angular/forms';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class HinValidator {
    // eslint-disable-next-line sonarjs/concise-regex
    private static readonly hinPattern: RegExp = /^[A-Za-z]{1}[0-9]{9}$/;

    public static hinValidator(): ValidatorFn {
        return (hinField: AbstractControl): ValidationErrors | null => {
            const { value }: AbstractControl = hinField;

            return HinValidator.validateHin(value);
        };
    }

    private static characterToNumber(character: string): number {
        const charCode: number = character.charCodeAt(0);

        // Number string
        if (charCode <= 57) {
            return charCode - 48;
        }

        // Lowercase character
        if (charCode >= 97) {
            return charCode - 96;
        }

        // Uppercase character
        return charCode - 64;
    }

    private static calculateCrossSum(input: number): number {
        let sum = 0;
        let restValue: number = input;

        while (restValue > 0) {
            sum += restValue % 10;
            restValue = Math.floor(restValue / 10);
        }

        return sum;
    }

    private static isHinChecksumValid(hin: string): boolean {
        const hinArray: string[] = Array.from<string>(hin);
        const charCode: number = HinValidator.characterToNumber(hinArray[0]);
        const data: number[] = [Math.floor(charCode / 10), charCode % 10];

        const digits: number[] = hinArray
            .slice(1, -1) // Ignore first letter (special handling) and last letter (checksum)
            .map((character: string): number => parseInt(character, 10)); // Convert each character to integer

        data.push(...digits);

        const sum: number = data
            .map((value: number, index: number): number => value * ((index % 2) + 1)) // Multiply each entry by 1 or 2 based on index
            .reduce((total: number, value: number): number => total + HinValidator.calculateCrossSum(value), 0); // Add the cross-sum of each value

        const checksum: number = sum % 10; // Get the last digit of the sum as checksum

        return parseInt(hinArray[hinArray.length - 1], 10) === checksum;
    }

    private static createError(type: string, hin: string): ValidationErrors {
        return {
            [type]: {
                valid: false,
                hin,
            },
        };
    }

    private static validateHin(hin: string): ValidationErrors | null {
        if (hin === '') {
            // eslint-disable-next-line no-null/no-null
            return null;
        }

        if (!HinValidator.hinPattern.test(hin)) {
            return HinValidator.createError('hinPattern', hin);
        }

        if (!HinValidator.isHinChecksumValid(hin)) {
            return HinValidator.createError('hinChecksum', hin);
        }

        // eslint-disable-next-line no-null/no-null
        return null;
    }
}
