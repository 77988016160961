<big-ui-form-row
    *transloco="let t; prefix: 'ftbDateRange'"
    [id]="settings.id"
    [label]="label"
    [isRequired]="control?.isRequired"
    [errors]="control?.errors"
    [showError]="isInvalid"
    [onlyRenderFirstError]="true"
    [errorOverrides]="errorOverrides"
    [showInfo]="!!settings.help"
    [infoTextTemplate]="help"
    (infoLayerOpened)="trackFormsInfoIconEvent($event)">
    <big-ui-date-range
        [minDate]="settings.attributes?.min"
        [maxDate]="settings.attributes?.max"
        [showValidation]="isPageValidationFailed"
        [formControl]="control"
        [formFieldTracking]="settings"
        [id]="settings.id"
        [isInvalid]="isInvalid"
        [isRequired]="settings.required"
        [isValid]="isValid"
        (errors)="updateErrors($event)" />
</big-ui-form-row>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
