import { Injectable } from '@angular/core';
import { ParagraphTypes, type TextModel } from '@big-direkt/utils/shared';
import { type ErrorNodeMessage } from '../models/error-node-message';
import { type ErrorNodeModel } from '../models/error-node-model';

@Injectable({
    providedIn: 'root',
})
export class ErrorNodeMessageExtractorService {
    public extract(node: ErrorNodeModel): ErrorNodeMessage {
        const firstTextParagraph = node.paragraphs?.find(item => item.type === ParagraphTypes.Text) as TextModel | undefined;

        return {
            title: node.heading,
            content: firstTextParagraph?.content,
            alertType: node.alertType ?? 'danger',
        } as ErrorNodeMessage;
    }
}
