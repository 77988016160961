import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { FormConfigElement, type PreviewValue } from '@big-direkt/form/contracts';

@Component({
    selector: 'big-form-preview-group',
    templateUrl: './preview-group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewGroupComponent {
    @Input() public settings?: FormConfigElement;
    @Input() public submissionPreviewNode!: PreviewValue;
    @HostBinding('class') public classList = 'block';
}
