@if (currentPage?.isWizardVisible) {
    <div class="flex items-center gap-4 text-xs font-bold leading-3">
        <big-ui-chart-circular-progress-bar
            size="34"
            [angle]="(360 * nodeIndex) / wizardPageCount"
            [colorMap]="{ primarySpan: { min: 0, max: 360 } }"
            class="text-[10px] font-bold leading-[10px]">
            {{ nodeIndex }}/{{ wizardPageCount }}
        </big-ui-chart-circular-progress-bar>
        {{ mobileWizardLabel | replaceTokenRef: currentPage?.settings.arrayParents }}
    </div>
}
