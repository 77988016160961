<big-ui-form-row
    [id]="settings.id"
    [label]="label"
    [isRequired]="control?.isRequired"
    [errors]="control?.errors"
    [showError]="isInvalid"
    [showInfo]="!!settings.help"
    [infoTextTemplate]="help"
    (infoLayerOpened)="trackFormsInfoIconEvent($event)">
    <div class="grid flex-wrap grid-cols-12 gap-x-3">
        @for (child of settings.children; track $index) {
            <div
                class="col-span-12"
                [ngClass]="{
                    'md:col-span-6 [&:not(:first-child)]:mt-3 md:[&:not(:first-child)]:mt-0': hasTimeField
                }">
                <ng-container
                    appElementHost
                    [id]="child.name"></ng-container>
            </div>
        }
    </div>
</big-ui-form-row>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
