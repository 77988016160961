import { type SubmissionValueState } from '../../models/form-submission/submission-value-state.model';
import { type AccessorFn, type SubmissionValueAccessorFn } from '../drupal-webform-types';

export const VALUE_ACCESSOR_NUMBER: AccessorFn = v => {
    if ((v as SubmissionValueState | undefined)?.isDisabled) {
        return undefined;
    }

    if (!v || typeof v !== 'string') {
        return '0';
    }
    // eslint-disable-next-line prefer-const
    let [ones, decimals] = v.split('.');
    ones = Number.parseInt(ones, 10).toLocaleString('de-DE');

    return decimals ? `${ones},${decimals}` : ones;
};

export const SUBMISSION_VALUE_ACCESSOR_NUMBER: SubmissionValueAccessorFn = (v, { name }) => {
    if (v === undefined || isNaN(parseInt(v as string, 10))) {
        return { [name]: undefined };
    }

    // eslint-disable-next-line @typescript-eslint/no-base-to-string,sonarjs/no-base-to-string
    return { [name]: v.toString().replaceAll('.', '').replace(',', '.') };
};
