<div
    *transloco="let t; prefix: 'uiModalInfo'"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true"
    class="flex flex-col h-full">
    <div class="bg-gray-400">
        <div class="flex items-center h-20 px-6 !py-4">
            <h4 class="flex-1 m-0 text-primary">{{ title }}</h4>
            <big-ui-button
                [buttonStyle]="'icon'"
                [id]="'close-overlay-icon'"
                (clicked)="popoverRef.close({ leaveForm: false })"
                [title]="t('closeOverlayBtn')">
                <big-icon
                    [icon]="iconClose"
                    class="primary"
                    [attr.aria-label]="t('closeOverlayBtn')" />
            </big-ui-button>
        </div>
    </div>
    <div class="flex-1 p-6">
        <div [innerHTML]="description | sanitize: securityContext"></div>
        @if (link) {
            <big-ui-link
                [href]="link.href"
                (click)="onLinkClick()"
                [target]="link.target">
                {{ link.title }}
            </big-ui-link>
        }
    </div>
</div>
