import { type AbstractControl, type ValidationErrors, type ValidatorFn } from '@angular/forms';
import { BigUntypedFormGroup } from '../../form-control/big-form-group';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class CheckboxesValidator {
    public static validateMaxAllowed(maxAllowedCheckboxes = -1): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition,sonarjs/different-types-comparison
            if (!(control instanceof BigUntypedFormGroup) || control.controls === undefined || maxAllowedCheckboxes === -1) {
                // eslint-disable-next-line no-null/no-null
                return null;
            }

            const checked: number = CheckboxesValidator.countCheckControls(control);

            if (checked > maxAllowedCheckboxes) {
                const error: ValidationErrors = {
                    maxAllowedInvalid: {
                        max: maxAllowedCheckboxes,
                    },
                };

                Object.values(control.controls).forEach((ctrl: AbstractControl) => {
                    ctrl.setErrors({
                        error: 'limit',
                    });
                });

                return error;
            } else {
                Object.values(control.controls).forEach(ctrl => {
                    if (ctrl.errors?.error === 'limit') {
                        // eslint-disable-next-line no-null/no-null
                        ctrl.setErrors(null);
                        ctrl.updateValueAndValidity();
                    }
                });
            }

            // eslint-disable-next-line no-null/no-null
            return null;
        };
    }

    private static countCheckControls(control: BigUntypedFormGroup): number {
        let checked = 0;

        for (const checkboxControl of Object.values(control.controls)) {
            if (checkboxControl.value as boolean) {
                checked = checked + 1;
            }
        }

        return checked;
    }
}
