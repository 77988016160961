import { type SubmissionObject } from '../../models/form-submission/submission-object.model';
import { type SubmissionValueState } from '../../models/form-submission/submission-value-state.model';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const SUBMISSION_MAPPER_MULTIPLE = (data: SubmissionObject, propName: string): void => {
    const submissionObject = data[propName] as SubmissionObject | undefined;
    if (submissionObject !== undefined) {
        Object.keys(submissionObject).forEach(key => {
            const submissionValue = submissionObject[key];
            if (submissionValue !== undefined) {
                for (const [k, v] of Object.entries(submissionValue)) {
                    if ((v as SubmissionValueState).isDisabled) {
                        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete, @typescript-eslint/no-explicit-any
                        delete (data as any)[propName][key][k];

                        continue;
                    }

                    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete, @typescript-eslint/no-explicit-any
                    const propValue = (data as any)[propName][key][k];

                    if ((typeof propValue === 'object' && Array.isArray(propValue))) {
                        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete, @typescript-eslint/no-explicit-any
                        (data as any)[propName][key][k] = (propValue as string[]).map(item => {
                            if ((typeof item !== 'string')) {
                                return item;
                            }

                            return item.trim();
                        });
                    }

                    if ((typeof propValue === 'string')) {
                        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete, @typescript-eslint/no-explicit-any
                        (data as any)[propName][key][k] = propValue.trim();
                    }
                }
            }
        });
    }
};
