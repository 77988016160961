import { type FormConfigElement } from '@big-direkt/form/contracts';
import { filter } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { type BigAnyFormControl } from '../../form-control/big-any-form-control';
import { type BigUntypedFormControl } from '../../form-control/big-form-control';
import { type FormService } from '../form/form.service';
import { type BooleanEvaluator } from './state-tree/boolean-evaluator';

export class ConditionalStateHandler {
    public static readonly lockedControls: BigAnyFormControl[] = [];

    public constructor(
        private readonly affectedElement: FormConfigElement,
        private readonly triggeringControls: BigUntypedFormControl[],
        private readonly stateEvaluator: BooleanEvaluator,
        private readonly handlerCallback: (settings: FormConfigElement, newState: boolean) => void,
        private readonly formService: FormService,
        private readonly registeredStateHandlers: Record<string, ConditionalStateHandler[] | undefined>,
    ) {
        this.initialize();
    }

    private initialize(): void {
        this.triggeringControls.forEach((control): void => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            control.valueChanges
                .pipe(
                    distinctUntilChanged(),
                    // this filters out any empty object, allows everything else though
                    filter((object: unknown) => {
                        if (object?.constructor === Object) {
                            return Object.keys(object).length > 0;
                        }

                        return true;
                    }),
                )
                .subscribe((): void => {
                    ConditionalStateHandler.lockedControls.push(control);
                    this.handle();
                    ConditionalStateHandler.lockedControls.splice(ConditionalStateHandler.lockedControls.indexOf(control), 1);
                });
        });

        this.handle();
    }

    private handle(): void {
        const affectedControl = this.formService.getFormControl(this.affectedElement.arrayParents);
        if (affectedControl && ConditionalStateHandler.lockedControls.includes(affectedControl)) {
            return;
        }

        const result = this.stateEvaluator.evaluate();
        this.handlerCallback(this.affectedElement, result);
        this.updateChildStates(this.affectedElement);
    }

    private updateChildStates(element: FormConfigElement): void {
        element.children.forEach(child => {
            const childHandlers = this.registeredStateHandlers[child.id];
            if (childHandlers) {
                childHandlers.forEach((handler): void => {
                    handler.handle();
                });
            }

            this.updateChildStates(child);
        });
    }
}
