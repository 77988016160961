import { FormConfigElement } from '@big-direkt/form/contracts';
import { type ComponentService } from '../component/component.service';
import { type ConditionalFieldsService } from '../conditional-fields/conditional-fields.service';
import { type FormRelationService } from '../form-relation/form-relation.service';
import { type FormService } from '../form/form.service';
import { type MultipleGroupHandler, type MultipleGroupService } from '../multiple-group/multiple-group.service';
import { type MultiplesService } from '../multiples/multiples.service';
import { type PageNavigationService } from '../page-navigation/page-navigation.service';
import { type TokenService } from './../token/token.service';
import { MyDestroyRef } from './my-destroy-ref';

export class RepeatingPageHandler implements MultipleGroupHandler {
    private readonly destroyRefs: Record<number, MyDestroyRef> = {};
    private readonly settingsTemplate!: FormConfigElement;
    private readonly childrenTemplates: FormConfigElement[] = [];

    // eslint-disable-next-line @typescript-eslint/max-params
    public constructor(
        private readonly settings: FormConfigElement,
        private readonly multiGroupService: MultipleGroupService,
        private readonly tokenService: TokenService,
        private readonly formService: FormService,
        private readonly pageNavigationService: PageNavigationService,
        private readonly conditionalFieldsService: ConditionalFieldsService,
        private readonly formRelationService: FormRelationService,
        private readonly componentService: ComponentService,
        private readonly multiplesService: MultiplesService,
    ) {
        this.childrenTemplates = settings.children;
        this.childrenTemplates.forEach((child: FormConfigElement): void => {
            this.removeParentNodes(child);
        });
        settings.children = [];

        this.settingsTemplate = new FormConfigElement(settings);

        if (settings.referencedMultiple) {
            this.multiGroupService.subscribe(settings.referencedMultiple, this);
        }

        this.settingsTemplate.children.forEach((child: FormConfigElement): void => {
            this.removeParentNodes(child);
        });

        settings.showInWizard = false;
    }

    public onItemAdded = (index: number): void => {
        this.createItem(index);
    };

    public onItemRemoved = (index: number): void => {
        this.removeItem(index);
    };

    public destroy(): void {
        this.multiGroupService.unsubscribe(this.settings.referencedMultiple);
        Object.values(this.destroyRefs).forEach(x => {
            x.destroy();
        });
    }

    private createItem(groupIndex: number): FormConfigElement {
        const newPage = new FormConfigElement(this.settingsTemplate);
        const index = this.settings.parent?.children.indexOf(this.settings) ?? 0;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const predecessor = this.settings.parent!.children[index - 1];
        this.settings.parent?.children.splice(index, 0, newPage);
        newPage.repeatedElement = newPage.name;
        newPage.repeatedIndex = groupIndex;
        newPage.name = `${newPage.name}_${groupIndex}`;
        newPage.type = 'big_webform_repeated_page';
        newPage.arrayParents = [...this.settings.arrayParents, newPage.name];
        newPage.id = newPage.arrayParents.join('-');
        newPage.parent = this.settings;

        newPage.children = [];
        this.childrenTemplates.forEach((child: FormConfigElement): void => {
            const newChild = child.clone();
            newChild.arrayParents = [...newPage.arrayParents, newChild.name];
            newChild.id = newChild.arrayParents.join('-');
            newPage.children.push(newChild);
        });

        this.tokenService.replaceIndexedLabels(newPage, groupIndex);

        this.adjustArrayParents(newPage);

        this.formService.createControls(newPage);

        this.pageNavigationService.addPage(newPage, predecessor);
        this.multiplesService.registerMultiples(newPage);

        this.conditionalFieldsService.createStateHandlers(newPage);
        this.destroyRefs[groupIndex] = new MyDestroyRef();
        this.formRelationService.initializeRelations(newPage, this.destroyRefs[groupIndex]);

        return newPage;
    }

    private removeItem(groupIndex: number): void {
        const name = `${this.settingsTemplate.name}_${groupIndex}`;
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const index = this.settings.parent!.children.findIndex((setting: FormConfigElement) => setting.name === name);
        const [item] = this.settings.parent!.children.splice(index, 1);
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
        this.pageNavigationService.deletePage(name);
        this.formService.removeControl(this.settings.arrayParents, name);
        this.componentService.removeComponent(item.id);
        this.destroyRefs[groupIndex].destroy();
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.destroyRefs[groupIndex];
    }

    private removeParentNodes(element: FormConfigElement): void {
        delete element.parent;
        element.children.forEach((child: FormConfigElement): void => {
            this.removeParentNodes(child);
        });
    }

    private adjustArrayParents(settings: FormConfigElement): void {
        settings.children.forEach((child: FormConfigElement): void => {
            child.arrayParents = settings.arrayParents.concat(child.name);
            child.parent = settings;
            child.id = child.arrayParents.join('-');
            this.adjustArrayParents(child);
        });
    }
}
