import { type SubmissionValueState } from '../../models/form-submission/submission-value-state.model';
import { type AccessorFn } from '../drupal-webform-types';

export const VALUE_ACCESSOR_TIME: AccessorFn = v => {
    if ((v as SubmissionValueState | undefined)?.isDisabled) {
        return undefined;
    }

    const value = v as string;

    return value ? `${value} Uhr` : '';
};
