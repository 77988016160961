import { JsonPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UserRepository, UserService } from '@big-direkt/state/user';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'big-ui-user-data',
    templateUrl: './user-data.component.html',
    standalone: true,
    imports: [TranslocoDirective, NgClass, JsonPipe],
    providers: [provideTranslationScope('uiUserData', /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`))],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDataComponent {
    private readonly userRepository = inject(UserRepository);
    private readonly userService = inject(UserService);
    private readonly userProfile = toSignal(this.userService.getProfile());
    public readonly userData = computed(() => (this.userRepository.isLoggedIn() ? this.userProfile() : undefined));

    public readonly idTranslationKey = computed(() =>
        this.userData()?.identifier.distributionPartnerNumber ? 'distributionPartnerId' : 'insuranceNumber',
    );
    public readonly id = computed(
        () => this.userData()?.identifier.distributionPartnerNumber ?? this.userData()?.identifier.healthInsuranceNumber ?? '',
    );

    @Input({ required: true }) public viewMode?: 'breaking-left' | 'inline-center';
    @HostBinding('class') @Input() public classList = 'block';
}
