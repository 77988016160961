import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormConfigElement, type SubmissionObject } from '@big-direkt/form/contracts';
import { EnvironmentService } from '@big-direkt/utils/environment';
import { type WebformSubmissionResponse } from '../../interfaces/webform-submission-response';
import { PageNavigationService } from '../page-navigation/page-navigation.service';
import { SubmissionValueProviderService } from '../submission-value-provider/submission-value-provider.service';

@Injectable({
    providedIn: 'root',
})
export class FormStateService {
    private readonly pageNavigationService = inject(PageNavigationService);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly environmentService = inject(EnvironmentService);
    private readonly router = inject(Router);
    private readonly submissionValueProvider = inject(SubmissionValueProviderService);

    private form?: FormConfigElement;
    private response?: WebformSubmissionResponse;

    public get submissionData(): SubmissionObject | undefined {
        if (!this.form || !this.pageNavigationService.getCurrentPage()) {
            // eslint-disable-next-line no-null/no-null
            return undefined;
        }

        const result = this.submissionValueProvider.getSubmissionValue(this.form);

        if (result) {
            result._page = this.pageNavigationService.getCurrentPage()?.settings.name;
        }

        return result;
    }

    public registerResponse(response: WebformSubmissionResponse): void {
        this.response = response;
    }

    public getResponse(): WebformSubmissionResponse | undefined {
        return this.response;
    }

    public getForm(): FormConfigElement | undefined {
        return this.form;
    }

    /**
     * Create a new form config and push it to the formState array.
     * The active state of the first element is true.
     * @param form
     */
    public initialize(form: FormConfigElement): void {
        this.form = form;
    }

    public handleDestinationRedirection(): void {
        const { privatePath } = this.environmentService;

        let destination: string = (this.activatedRoute.snapshot.queryParams.destination as string | undefined) ?? privatePath;

        if (destination === `${privatePath}/login`) {
            destination = privatePath;
        }

        void this.router.navigateByUrl(destination);
    }
}
