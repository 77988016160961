<big-ui-form-row
    [showInfo]="!!settings.help"
    [infoTextTemplate]="help"
    [id]="settings.id"
    [label]="label"
    [isRequired]="control?.isRequired"
    [errors]="control?.errors"
    [showError]="isInvalid"
    class="mb-2"
    infoClass="ml-4 md:ml-8"
    (infoLayerOpened)="trackFormsInfoIconEvent($event)">
    <big-ui-currency-input
        [id]="settings.id"
        [title]="label"
        [currencyType]="settings.currencyType"
        [isTouched]="control?.touched"
        [isRequired]="control?.isRequired"
        [isValid]="isValid"
        [showValidation]="isPageValidationFailed"
        [formControl]="control"
        [formFieldTracking]="settings" />
</big-ui-form-row>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
