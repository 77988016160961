<big-ui-form-row
    [id]="settings.id"
    [label]="label"
    [isRequired]="control?.isRequired"
    [errors]="control?.errors"
    [showError]="isInvalid"
    [showInfo]="!!settings.help"
    [infoTextTemplate]="help"
    (infoLayerOpened)="trackFormsInfoIconEvent($event)">
    <textarea
        bigSharedTrimOnBlur
        [formControl]="control"
        [ngClass]="{
            'is-valid': isValid,
            'is-invalid': isInvalid
        }"
        class="form-control min-h-[168px]"
        [id]="settings.id"
        [formFieldTracking]="settings"
        [attr.disabled]="isDisabled"
        [attr.readonly]="isReadOnly"></textarea>
</big-ui-form-row>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
