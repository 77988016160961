<big-ui-form-row
    [showInfo]="!!settings.help"
    [infoTextTemplate]="help"
    [id]="settings.id"
    [label]="!minimal ? label : undefined"
    [isRequired]="control?.isRequired"
    [errors]="control?.errors"
    [showError]="isInvalid"
    (infoLayerOpened)="trackFormsInfoIconEvent($event)">
    <!-- why h-14? Chrome Browsers render time fields 4px higher than other input fields :sigh: -->
    <input
        class="form-control h-14"
        type="time"
        pattern="[0-9]{2}:[0-9]{2}"
        [id]="settings.id"
        [formControl]="control"
        [ngClass]="{
            'is-valid': isValid && control?.valid && formService.isPageValidationFailed,
            'is-invalid': isInvalid && formService.isPageValidationFailed
        }"
        [appDrupalWebformElementAttributes]="settings"
        [formFieldTracking]="settings" />
</big-ui-form-row>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
