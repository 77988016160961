import { ChangeDetectorRef, Component, QueryList, ViewChildren, inject, type OnInit } from '@angular/core';
import { FormConfigElement, type AllowedDrupalType } from '@big-direkt/form/contracts';
import { NestedValuesBaseComponent } from '../../../base-components/nested-value-base/nested-values-base.component';
import { ElementHostDirective } from '../../../directives/element-host/element-host.directive';
import { ComponentService } from '../../../services/component/component.service';
import { type ComponentMap } from '../../../utilities/component-map/component-map';
import { DatetimeValidator } from '../../../utilities/datetime-validator/datetime-validator';
import { type DateComponent } from '../date/date.component';
import { type TimeComponent } from '../time/time.component';

@Component({
    selector: 'big-form-date-time',
    templateUrl: './datetime.component.html',
})
export class DateTimeComponent extends NestedValuesBaseComponent implements OnInit {
    public readonly changeDetector = inject(ChangeDetectorRef);
    public readonly componentService = inject(ComponentService);

    @ViewChildren(ElementHostDirective) private readonly childContainers!: QueryList<ElementHostDirective>;

    public hasTimeField = false;

    public static register(): ComponentMap {
        return {
            datetime: DateTimeComponent,
        };
    }

    public override init(): void {
        super.init();
        this.componentService.registerForManualChildHandling(Object.keys(DateTimeComponent.register()) as AllowedDrupalType[]);
    }

    public ngOnInit(): void {
        // TODO: Refactor component creation so that this ChangeDetector is not needed (bad practice).
        this.changeDetector.detectChanges();

        // Remove group validators because each sub component brings it's own validator
        this.control?.clearValidators();

        this.settings.children.forEach((childSettings: FormConfigElement): void => {
            const childRef: ElementHostDirective | undefined = this.childContainers.find(({ id }: ElementHostDirective): boolean => id === childSettings.name);

            if (!childRef) {
                return;
            }

            const { instance: component } = this.componentService.createComponent<DateComponent | TimeComponent>(childSettings);
            this.componentService.insertViewChildren([childSettings], childRef.viewContainerRef);

            component.minimal = true;

            if (childSettings.type === 'webform_time') {
                this.hasTimeField = true;
            }
        });

        if (!this.control?.isRequired) {
            this.control?.addValidators(DatetimeValidator.requiredIfOneFieldIsFilled(this.settings.name));
        }
    }
}
