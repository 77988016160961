<big-ui-alert
    *transloco="let t; prefix: 'ftbPreview'"
    class="mb-10 md:mb-20"
    type="info"
    [title]="t('headline')"
    [classList]="
        'flex text-center border-2 rounded-md p-4 flex-column items-center [&>h3]:text-xl [&>h3]:font-sans [&>h3]:font-normal [&>h3]:flex [&>h3]:gap-4 [&>h3]:items-center'
    " />

@for (topLevelPage of pageNavigationService.pages | previewVisible: true; track $index) {
    <div class="mb-12">
        <h3 class="mb-4">{{ topLevelPage.previewLabel | replaceTokenRef: topLevelPage.settings.arrayParents }}</h3>
        @for (pageNode of topLevelPage.descendantsAndSelf | previewVisible; track $index; let isLast = $last) {
            @if (submissionPreviewBuilderService.buildSinglePage(pageNode, pageNode !== topLevelPage); as submissionPreviewNode) {
                @if (submissionPreviewNode.children?.length > 0 && submissionPreviewNode.label) {
                    <h4>
                        {{ submissionPreviewNode.label }}
                    </h4>
                }

                <div>
                    @for (child of submissionPreviewNode.children; track $index) {
                        @if (!child.children) {
                            <big-form-preview-element [submissionPreviewNode]="child" />
                        } @else {
                            <big-form-preview-group
                                [submissionPreviewNode]="child"
                                class="mt-12 mb-12 last-of-type:mb-0 first:mt-0" />
                        }
                    }
                </div>
            }

            @if (pageNode.hasInputs) {
                <div
                    class="mt-6 text-right"
                    [class.mb-12]="!isLast">
                    <big-ui-button
                        (click)="onClick($event, pageNode)"
                        [buttonStyle]="'link'"
                        [type]="'button'"
                        class="font-semibold group text-primary hover:text-primary-light"
                        [classList]="['no-underline']">
                        <div class="flex items-center">
                            <div class="mr-2">
                                {{ 'components.preview.editDetails' | stringReplacePipe }}
                            </div>
                            <big-icon
                                [icon]="iconEdit"
                                class="text-sm primary group-hover:fill-primary-light"
                                [attr.aria-label]="'components.preview.editDetails' | stringReplacePipe" />
                        </div>
                    </big-ui-button>
                </div>
            }
        }
    </div>
}
