<input
    class="form-control"
    type="text"
    bigSharedTrimOnBlur
    [id]="id"
    [name]="id"
    [required]="isRequired"
    [placeholder]="placeholder"
    (blur)="onBlur()"
    (ngModelChange)="onChange(formControl.value)"
    [formControl]="formControl"
    [ngClass]="{
        'is-invalid': !isValid && showValidation,
        'is-valid': isValid && showValidation,
    }" />
<ng-content />
