import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BaseControlValueAccessor, TrimInputOnBlurDirective } from '@big-direkt/utils/shared';

@Component({
    selector: 'big-ui-input',
    standalone: true,
    templateUrl: './input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, ReactiveFormsModule, TrimInputOnBlurDirective],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputComponent,
            multi: true,
        },
    ],
})
export class InputComponent extends BaseControlValueAccessor<string> {
    @HostBinding('class') public classList = 'block';

    @Input() public id?: string;
    @Input() public placeholder = '';
    @Input() public isRequired = false;
    @Input() public isValid = true;
    @Input() public showValidation = false;

    @Output() public readonly blurred = new EventEmitter<void>();

    public onBlur(): void {
        this.blurred.emit();
    }
}
