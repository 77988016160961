import { Injectable, inject } from '@angular/core';
import { DRUPAL_WEBFORM_TYPE_CONFIG, type FormConfigElement, type SubmissionObject, type SubmissionValue } from '@big-direkt/form/contracts';
import { FormElementValueServiceBase } from '@big-direkt/form/preview';
import { FormService } from '../form/form.service';
import { SubmissionValueProviderService } from '../submission-value-provider/submission-value-provider.service';

@Injectable({
    providedIn: 'root',
})
export class FormElementValueService extends FormElementValueServiceBase {
    private readonly formService = inject(FormService);
    private readonly submissionValueProvider = inject(SubmissionValueProviderService);

    public getLinkValue(element: FormConfigElement): { label: string; src?: string }[] | undefined {
        const accessor = DRUPAL_WEBFORM_TYPE_CONFIG[element.type].linkAccessor;
        const value = this.submissionValue(element);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return accessor?.(value, element);
    }

    // eslint-disable-next-line sonarjs/function-return-type
    public override submissionValue(element: FormConfigElement): SubmissionObject | SubmissionValue {
        return this.submissionValueProvider.getSubmissionValue(element)?.[element.name];
    }

    public isDisabled(element: FormConfigElement): boolean {
        return !!this.formService.getFormControl(element.arrayParents)?.disabled;
    }
}
