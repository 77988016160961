@if (pdfDownloadLink) {
    <a
        [href]="pdfDownloadLink"
        target="_blank"
        (click)="onClick($event)"
        [download]="filename"
        class="inline-flex items-center font-semibold no-underline text-primary hover:!text-primary-light"
        rel="noopener noreferrer">
        <div class="mr-2">
            {{ label }}
        </div>
        <big-icon
            [icon]="iconDownload"
            class="text-sm fill-primary"
            [attr.aria-label]="label" />
    </a>
}
