/* eslint-disable @angular-eslint/template/cyclomatic-complexity, max-lines*/
import { DomPortal } from '@angular/cdk/portal';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import {
    Component,
    DestroyRef,
    EnvironmentInjector,
    EventEmitter,
    HostListener,
    Injector,
    Input,
    Output,
    SecurityContext,
    ViewChild,
    effect,
    inject,
    signal,
    type AfterViewInit,
    type ElementRef,
    type OnDestroy,
    type OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router, type Params } from '@angular/router';
import { EventBusService } from '@big-direkt/event-bus';
import { FormConfigElement, MARKUP_TYPES } from '@big-direkt/form/contracts';
import { MobileAppRepository } from '@big-direkt/state/mobile-app';
import { UiRepository } from '@big-direkt/state/ui';
import { FeatureFlagsService, ScrollService } from '@big-direkt/utils/environment';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumPfeilLinks } from '@big-direkt/utils/icons';
import { ExternalEventService, PlatformService } from '@big-direkt/utils/shared';
import { BehaviorSubject, EMPTY, firstValueFrom, type Observable } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { BigAnyFormControl } from './form-control/big-any-form-control';
import { type RequestOptions } from './interfaces/request-options';
import { type WebformResponse } from './interfaces/webform-response';
import { type WebformSubmissionResponse } from './interfaces/webform-submission-response';
import { ApiService } from './services/api-service/api.service';
import { ComponentService } from './services/component/component.service';
import { ConditionalFieldsService } from './services/conditional-fields/conditional-fields.service';
import { ErrorMessageService } from './services/error-message/error-message.service';
import { FormRelationService } from './services/form-relation/form-relation.service';
import { FormStateService } from './services/form-state/form-state.service';
import { FormService } from './services/form/form.service';
import { MultiplesService } from './services/multiples/multiples.service';
import { type PageHierarchyFormNode } from './services/page-navigation/page-hierarchy-node';
import { PageNavigationService } from './services/page-navigation/page-navigation.service';
import { RepeatingPageService } from './services/repeating-page/repeating-page.service';
import { StringReplaceService } from './services/string-replace/string-replace.service';
import { SubmissionResponseHandlerService } from './services/submission-response-handler/submission-response-handler.service';
import { SubmissionResponseType } from './services/submission-response-handler/submission-response-type';
import { findHiddenElements } from './utilities/config-helpers/find-hidden-elements';
import { markControlAsTouched } from './utilities/form-control-handler/form-control-handler';
const PERF_ON_INIT = 'ng-on-init';
const PERF_ON_INIT_END = 'ng-on-init-end';
const PERF_FORM = 'form-init';
const PERF_FORM_END = 'form-init-end';
const PERF_COMPONENT = 'form-init-create-component';
const PERF_COMPONENT_END = 'form-init-create-component-end';
const PERF_REST = 'form-init-rest';
const PERF_REST_END = 'form-init-rest-end';

@Component({
    selector: 'big-form-holder',
    templateUrl: './form-view.component.html',
    providers: [provideTranslationScope('ftbForm', async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
})
export class FormViewComponent implements OnInit, OnDestroy, AfterViewInit {
    public readonly iconArrowLeft = IconBigMediumPfeilLinks;
    public readonly formService = inject(FormService);
    private readonly uiRepository = inject(UiRepository);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly apiService = inject(ApiService);
    private readonly componentService = inject(ComponentService);
    private readonly conditionalFieldsService = inject(ConditionalFieldsService);
    private readonly destroyRef = inject(DestroyRef);
    private readonly externalEventService = inject(ExternalEventService);
    private readonly errorMessageService = inject(ErrorMessageService);
    private readonly eventBus = inject(EventBusService);
    private readonly formRelationService = inject(FormRelationService);
    private readonly formStateService = inject(FormStateService);
    private readonly pageNavigationService = inject(PageNavigationService);
    private readonly repeatingPageService = inject(RepeatingPageService);
    private readonly platformService = inject(PlatformService);
    private readonly scrollService = inject(ScrollService);
    private readonly stringReplaceService = inject(StringReplaceService);
    private readonly submissionResponseHandler = inject(SubmissionResponseHandlerService);
    private readonly mobileAppRepository = inject(MobileAppRepository);
    private readonly featureFlagService = inject(FeatureFlagsService);
    private readonly environmentInjector = inject(EnvironmentInjector);
    private readonly injector = inject(Injector);
    private readonly multiplesService = inject(MultiplesService);

    public readonly securityContext: SecurityContext = SecurityContext.HTML;
    public readonly showFormState = this.featureFlagService.isFeatureEnabled('big-app-form-state');

    @Input() public webformId!: string;

    /** Custom text for the complete form button */
    @Input() public afterCompletedText = 'Schließen';

    /** Custom callback that is executed on the complete form button displayed after successful form transmission */
    @Input() public afterCompletedCallback?: () => void;

    @ViewChild('buttonBarPortalContent') public readonly buttonBarPortalContent: ElementRef<HTMLElement> | undefined;

    /** EventEmitter that can be used for custom logic before the thank-you page */
    @Output() public readonly successfulFormSubmit = new EventEmitter<void>();

    public isLoadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public isTransmitting = signal<boolean>(false);
    public buttonDisabled!: Observable<boolean>;
    public isTransmissionSuccessful = false;
    public beforeUnloadMessage = 'You have unsaved changes.';
    public isEmbeddedInMobileApp = this.mobileAppRepository.isEmbeddedInMobileApp;
    public isFormShown = this.uiRepository.isFormShown;
    public isWizardShown = this.uiRepository.isWizardShown;
    public errorCode: number | undefined = undefined;
    public topLevelMarkups: FormConfigElement[] = [];
    private scrollSelector: string = ScrollService.defaultSelector;

    public get currentPage(): PageHierarchyFormNode | undefined {
        return this.pageNavigationService.getCurrentPage();
    }

    public get wizardPageCount(): number {
        return this.pageNavigationService.wizardPageCount;
    }

    public get nextButtonLabel(): string {
        if (this.currentPage?.settings.nextButtonLabel !== undefined) {
            return this.currentPage.settings.nextButtonLabel;
        }

        if (this.currentPage?.isSubmitPage === true) {
            return 'navigation.submit';
        }

        return 'navigation.next';
    }

    public get skipFormStepButtonLabel(): string | undefined {
        return this.currentPage?.settings.skipFormStepButtonLabel;
    }

    public get backButtonLabel(): string {
        if (this.currentPage?.settings.backButtonLabel !== undefined) {
            return this.currentPage.settings.backButtonLabel;
        }

        return 'navigation.back';
    }

    public get isNextPageAvailable(): boolean {
        return this.pageNavigationService.getCurrentPage()?.settings.isSubmitPage ?? this.pageNavigationService.getNextPage() !== undefined;
    }

    public get isSkipFormStepButtonAvailable(): boolean {
        return !!this.pageNavigationService.getCurrentPage()?.settings.skipFormStepButtonLabel;
    }

    public get isBackButtonAvailable(): boolean {
        const previousPage: PageHierarchyFormNode | undefined = this.pageNavigationService.getPreviousPage();

        if (!previousPage) {
            return false;
        }

        return !previousPage.isSubmitPage;
    }

    @HostListener('window:beforeunload', ['$event'])
    public onLeave(e: BeforeUnloadEvent): boolean {
        this.trackFormFieldDropoff();

        if (
            this.isEmbeddedInMobileApp() ||
            this.isTransmissionSuccessful ||
            !this.formService.currentForm.dirty ||
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (e.target as any).activeElement?.href?.includes('mailto')
        ) {
            return true;
        }

        // eslint-disable-next-line @typescript-eslint/no-deprecated,sonarjs/deprecation
        e.returnValue = this.beforeUnloadMessage;

        return false;
    }

    public ngOnInit(): void {
        performance.mark(PERF_ON_INIT);
        if (this.platformService.isPlatformServer()) {
            return;
        }

        effect(
            () => {
                const isEmbedded = this.isEmbeddedInMobileApp();
                this.formService.pdfDownloadClickDisabled = isEmbedded;
                this.scrollSelector = isEmbedded ? 'body' : 'big-form-holder';
            },
            { injector: this.injector },
        );

        this.buttonDisabled = this.pageNavigationService.isBusy;

        if (!this.webformId) {
            this.errorCode = HttpStatusCode.NotFound;

            return;
        }

        /**
         * In some cases a "Vertriebspartner" comes with a uuid called "distributionpartner".
         * We take this value and pass it via parameter to the drupal backend.
         * We also check for current login session and send it as header parameter
         */
        const distributionpartner = this.activatedRoute.snapshot.queryParamMap.get('distributionpartner') ?? undefined;
        const webformRequestOptions: RequestOptions = {
            ...(distributionpartner && {
                params: {
                    distributionpartner,
                },
            }),
        };

        this.apiService
            .drupalWebformConfig(this.webformId, webformRequestOptions)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                map((response: WebformResponse) => {
                    performance.mark(PERF_FORM);
                    const { config: webform, strings: strings } = response;

                    if (strings !== undefined) {
                        this.stringReplaceService.initialize(strings);
                        this.beforeUnloadMessage = this.stringReplaceService.get('general.warnings.formLeaving');
                    }

                    const form = this.convertToFormElementSettings(webform);
                    this.extractTopLevelMarkups(form);

                    this.componentService.environmentInjector = this.environmentInjector;
                    performance.mark(PERF_COMPONENT);
                    this.repeatingPageService.registerRepeatingPages(form);
                    this.formService.createControls(form);
                    this.formService.initializeFormValueState(this.webformId);
                    this.multiplesService.registerMultiples(form);
                    performance.mark(PERF_COMPONENT_END);

                    performance.mark(PERF_REST);
                    this.formStateService.initialize(form);
                    this.pageNavigationService.initialize(form);
                    this.formRelationService.initializeRelations(form, this.destroyRef);
                    this.conditionalFieldsService.createStateHandlers(...this.topLevelMarkups, form);
                    this.eventBus.emit({
                        key: 'forms_form_initialize_event',
                        data: {
                            webformId: this.webformId,
                        },
                    });
                    performance.mark(PERF_REST_END);

                    this.isLoadingData.next(false);
                    performance.mark(PERF_FORM_END);

                    // We wrap this with an timeout because BIG App shows an loading screen after the form has been loaded. More see: BIGDEV-442
                    setTimeout(() => {
                        this.externalEventService.dispatch('formLoaded', {});
                    });
                    performance.mark(PERF_ON_INIT_END);
                    if (this.featureFlagService.isFeatureEnabled('big-app-form-performance')()) {
                        /* eslint-disable no-console */
                        console.log(`ngOnInit took ${performance.measure(PERF_ON_INIT, PERF_ON_INIT, PERF_ON_INIT_END).duration} miliseconds`);
                        console.log(`form init took ${performance.measure(PERF_FORM, PERF_FORM, PERF_FORM_END).duration} miliseconds`);
                        console.log(`component init took ${performance.measure(PERF_COMPONENT, PERF_COMPONENT, PERF_COMPONENT_END).duration} miliseconds`);
                        console.log(`rest init took ${performance.measure(PERF_REST, PERF_REST, PERF_REST_END).duration} miliseconds`);
                        /* eslint-enable no-console */
                    }
                }),
                catchError((error: Error | HttpErrorResponse): typeof EMPTY => {
                    this.errorCode = 'status' in error ? error.status : HttpStatusCode.InternalServerError;

                    this.isLoadingData.next(false);

                    return EMPTY;
                }),
            )
            .subscribe();
    }

    public ngAfterViewInit(): void {
        this.formService.buttonBarPortalContent = new DomPortal(this.buttonBarPortalContent);
    }

    public ngOnDestroy(): void {
        this.trackFormFieldDropoff();
        const hiddenElements = findHiddenElements(this.formStateService.getForm());
        this.formService.tryPersistFormValueState(this.webformId, hiddenElements.map(x => x.arrayParents));
        this.pageNavigationService.reset();
        this.repeatingPageService.reset();
        this.multiplesService.reset();
        this.eventBus.emit({ key: 'form_unloaded' });
    }

    public onSubmit($event: SubmitEvent): void {
        $event.preventDefault();
        const eventTargetName: string = $event.submitter?.tagName ?? this.nextButtonLabel;
        this.handleSubmit(eventTargetName);
    }

    public skipFormStepButtonHandler($event: Event): void {
        $event.preventDefault();
        this.formStateService.handleDestinationRedirection();
    }

    public backButtonHandler($event: Event): void {
        $event.preventDefault();

        this.formService.isPageValidationFailed = false;

        this.eventBus.emit({
            key: 'forms_previous_step_event',
            data: {
                triggerElementText: ($event.target as HTMLElement).outerText,
                stepName: this.pageNavigationService.getCurrentPage()?.wizardLabel,
                targetStepName: this.pageNavigationService.getPreviousPage()?.wizardLabel,
                triggerElement: ($event.target as HTMLInputElement).tagName,
            },
        });

        this.pageNavigationService.gotoPreviousPage();
        this.errorMessageService.reset();
        this.scrollService.scroll(this.scrollSelector);
    }

    private handleSubmit(eventTargetName: string): void {
        if (this.currentPage?.isSubmitPage === true) {
            void this.submitData(eventTargetName);
        } else {
            this.goToNextPage(eventTargetName);
        }
    }

    private convertToFormElementSettings(configElement: FormConfigElement): FormConfigElement {
        const settings = new FormConfigElement(configElement);

        settings.children = settings.children.map(child => {
            const newChild = this.convertToFormElementSettings(child);
            newChild.parent = settings;

            if (newChild.prePopulate === true) {
                this.activatedRoute.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params: Params): void => {
                    if (params[newChild.name] !== undefined) {
                        newChild.defaultValue = params[newChild.name];
                    }
                });
            }

            return newChild;
        });

        return settings;
    }

    private extractTopLevelMarkups(form: FormConfigElement): void {
        this.topLevelMarkups.push(...form.children.filter(x => MARKUP_TYPES.includes(x.type)));
        this.topLevelMarkups.forEach(x => {
            this.formService.initializeControl(x);
            form.children.splice(form.children.indexOf(x), 1);
        });
    }

    private getTitlesForIds(ids: string[], node?: FormConfigElement): string[] {
        if (!node) {
            return [];
        }

        if (node.id && ids.includes(node.id)) {
            return [node.title ?? node.id];
        }

        return node.children.reduce<string[]>((results: string[], child: FormConfigElement) => [...results, ...this.getTitlesForIds(ids, child)], []);
    }

    private findFirstElementInNgInvalidElement(element: HTMLElement | null): HTMLElement | undefined {
        if (!element) {
            return undefined;
        }

        // For file uploads the input is hidden, therefor return the parent element
        if (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'file') {
            return element.parentElement ?? undefined;
        }

        const isFocusableElement = element.tagName === 'INPUT' || element.tagName === 'SELECT' || element.tagName === 'TEXTAREA';
        const isCheckboxOrRadio =
            element.tagName === 'INPUT' && ((element as HTMLInputElement).type === 'checkbox' || (element as HTMLInputElement).type === 'radio');

        if ((isFocusableElement && element.classList.contains('ng-invalid')) || (isCheckboxOrRadio && !element.classList.contains('!border-success'))) {
            return element;
        }

        const children = Array.from(element.children);
        for (const child of children) {
            const input = this.findFirstElementInNgInvalidElement(child as HTMLElement);
            if (input) {
                return input;
            }
        }

        return undefined;
    }

    private validateCurrentPage(triggerElement: string): boolean {
        if (!this.currentPage) {
            return false;
        }
        const control = this.formService.getFormControl(this.currentPage.settings.arrayParents);
        if (!this.validatePage(control)) {
            this.scrollOnInvalid();
            this.trackOnValidationError(triggerElement);

            return false;
        }

        return true;
    }

    private goToNextPage(triggerElement: string): void {
        if (!this.validateCurrentPage(triggerElement)) {
            return;
        }

        this.scrollService.scroll(this.scrollSelector);
        this.trackFormNextStep(triggerElement);
        this.pageNavigationService.gotoNextPage();
    }

    private validatePage(control: BigAnyFormControl | undefined): boolean {
        markControlAsTouched(control);
        this.formService.isPageValidationFailed = !control?.valid;

        return !!control?.valid;
    }

    private trackFormAbandonment(): void {
        this.eventBus.emit({
            key: 'forms_form_abandonment_event',
            data: {
                webformId: this.webformId,
                targetUrl: this.currentPage?.settings.title,
                triggerElement: this.nextButtonLabel,
            },
        });
    }

    private trackFormFieldDropoff(): void {
        if (!this.isTransmissionSuccessful) {
            this.eventBus.emit({
                key: 'forms_field_dropoff_event',
                data: {
                    webformId: this.webformId,
                    stepName: this.pageNavigationService.getCurrentPage()?.wizardLabel,
                },
            });
        }
    }

    private trackFormNextStep(triggerElement: string, validationResult: 'failed' | 'ok' = 'ok', fieldName?: string): void {
        this.eventBus.emit({
            key: 'forms_next_step_event',
            data: {
                validationResult,
                stepName: this.pageNavigationService.getCurrentPage()?.wizardLabel,
                targetStepName: this.pageNavigationService.getNextPage()?.wizardLabel,
                fieldName,
                triggerElement,
            },
        });
    }

    private trackFormSubmit(triggerElement: string, validationResult: 'failed' | 'ok' = 'ok', errorMessage?: string): void {
        this.eventBus.emit({
            key: 'forms_form_submit_event',
            data: {
                validationResult,
                errorMessage,
                stepName: this.pageNavigationService.getCurrentPage()?.wizardLabel,
                targetStepName: this.pageNavigationService.getNextPage()?.wizardLabel,
                triggerElement,
            },
        });
    }

    private async submitData(triggerElement: string): Promise<void> {
        this.errorMessageService.reset();

        if (!this.validateCurrentPage(triggerElement)) {
            return;
        }

        this.isTransmitting.set(true);

        try {
            const response: WebformSubmissionResponse = await firstValueFrom<WebformSubmissionResponse>(
                this.apiService.submit(this.webformId, this.formStateService.submissionData ?? {}),
            );

            if (response.error) {
                throw response.error;
            }

            this.isTransmissionSuccessful = true;
            this.submissionResponseHandler.handle(response);
            this.externalEventService.dispatch('formSubmitted', {});
            this.pageNavigationService.gotoNextPage();
            this.uiRepository.setIsFormShown(this.isNextPageAvailable);
            this.formService.clearFormValueState();

            this.trackFormSubmit(triggerElement);
            this.pageNavigationService.trackPage();
            this.trackFormAbandonment();
            this.successfulFormSubmit.emit();
            this.scrollOnSubmit(response);
        } catch (error: unknown) {
            this.isTransmissionSuccessful = false;

            if (error instanceof HttpErrorResponse) {
                this.errorMessageService.process(error);
            }

            this.scrollService.scroll(this.scrollSelector);
            this.trackFormSubmit(triggerElement, 'failed', error instanceof Error ? error.message : 'unkown');
            this.pageNavigationService.trackPage(`${this.pageNavigationService.getCurrentPage()?.wizardLabel ?? ''} Error`);

            // eslint-disable-next-line no-console
            console.error(error);
        } finally {
            this.isTransmitting.set(false);
        }
    }

    private scrollOnInvalid(): void {
        const invalidSelector = 'form big-form-page:not(.hidden) .ng-invalid:not(.hidden)';
        const firstElementWithError: HTMLElement | null = document.querySelector<HTMLElement>(invalidSelector);

        if (!firstElementWithError) {
            return;
        }

        this.scrollService.scroll(invalidSelector, { scrollIntoView: true });

        const firstInvalidInput = this.findFirstElementInNgInvalidElement(firstElementWithError);
        firstInvalidInput?.focus({ preventScroll: true });
    }

    private trackOnValidationError(triggerElement: string): void {
        const allElementsWithError: NodeListOf<HTMLInputElement> = document.querySelectorAll<HTMLInputElement>('.ng-invalid[id]');
        const ids: string[] = [];
        allElementsWithError.forEach((element: HTMLInputElement) => ids.push(element.id));

        const titles: string[] = this.getTitlesForIds(ids, this.currentPage?.settings);
        this.trackFormNextStep(triggerElement, 'failed', titles.join(','));
    }

    private scrollOnSubmit(response: WebformSubmissionResponse): void {
        if (Array.isArray(response.data)) {
            return;
        }

        const responseType = response.data?.type;
        if (responseType !== SubmissionResponseType.Login && responseType !== SubmissionResponseType.Redirect) {
            this.scrollService.scrollTop();

            return;
        }

        this.router.events
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                filter(event => event instanceof NavigationEnd),
                take(1),
            )
            .subscribe(() => {
                this.scrollService.scrollTop();
            });
    }
}
