import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { BaseControlValueAccessor } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { type SelectOption } from '../../models/select-option';

@Component({
    selector: 'big-ui-select',
    standalone: true,
    imports: [NgClass, ReactiveFormsModule, TranslocoDirective],
    templateUrl: './select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SelectComponent,
            multi: true,
        },
        provideTranslationScope('uiSelect', /* istanbul ignore next */ async (lang: string, root: string) => import(`../../${root}/${lang}.json`)),
    ],
})
export class SelectComponent<T> extends BaseControlValueAccessor<T> {
    @HostBinding('class') public classList = 'block';

    @Input() public title?: string;
    @Input() public id?: string;
    @Input() public name?: string;
    @Input() public isValid = true;
    @Input() public showValidation = false;
    @Input() public options: SelectOption<T>[] = [];
    @Input() public disableTransloco = false;
    @Input() public isRequired? = false;
    @Input() public showNoSelectionOption = true;
    @Input() public customNoSelectionOption?: SelectOption<T>;
    // This is specificially for disabling all options in order for the element to become readonly
    @Input() public optionsDisabled = false;

    @Output() public readonly valueSelected = new EventEmitter<T | null>();

    public override onChange(value: T | null | undefined): void {
        super.onChange(value);
        this.valueSelected.emit(value);
    }
}
