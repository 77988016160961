import { type SubmissionFileUpload } from '../../models/form-submission/submission-file-upload.model';
import { type SubmissionObject } from '../../models/form-submission/submission-object.model';
import { type SubmissionValueState } from '../../models/form-submission/submission-value-state.model';
import { type UploadFile } from '../../models/upload-file';
import { type AccessorFn, type LinkAccessorFn, type SubmissionValueAccessorFn, type TypeDef } from '../drupal-webform-types';
import { getLastNestedParent } from '../nested-submission-value.helper';

const VALUE_ACCESSOR_FILE_UPLOAD: AccessorFn = v => {
    if ((v as SubmissionValueState | undefined)?.isDisabled) {
        return undefined;
    }

    return (v as SubmissionFileUpload[] | undefined)?.map(x => x.name);
};

const SUBMISSION_VALUE_ACCESSOR_FILE_UPLOAD: SubmissionValueAccessorFn = (v, { name }) => {
    const files = v as UploadFile[];
    const value: SubmissionFileUpload[] = files
        .filter((file: UploadFile): boolean => !!file.fileId)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map(file => ({ fileId: file.fileId!, name: file.name, downloadSrc: file.downloadSrc! }));

    return { [name]: value };
};

const LINK_ACCESSOR_FILE_UPLOAD: LinkAccessorFn = v => (v as SubmissionFileUpload[] | undefined)?.map(x => ({ label: x.name, src: x.downloadSrc }));

const SUBMISSION_MAPPER_FILE_UPLOAD = (data: SubmissionObject, propName: string, arrayParents: string[]): void => {
    const parent = getLastNestedParent(data, propName, ...arrayParents);

    const value = parent?.[propName] as SubmissionFileUpload[] | undefined;
    if (value && parent) {
        parent[propName] = value.map(x => x.fileId).join(' ');
    }
};

export const TYPE_DEF_FILE_UPLOAD: TypeDef = {
    isInput: true,
    preventChildProcessing: true,
    previewAccessor: VALUE_ACCESSOR_FILE_UPLOAD,
    linkAccessor: LINK_ACCESSOR_FILE_UPLOAD,
    submissionMapper: SUBMISSION_MAPPER_FILE_UPLOAD,
    submissionValueAccessor: SUBMISSION_VALUE_ACCESSOR_FILE_UPLOAD,
};
