import { Injectable, inject } from '@angular/core';
import { EventBusService } from '@big-direkt/event-bus';
import { type SearchTrackingInfoState } from './../models/search-tracking-info-state';

@Injectable({ providedIn: 'root' })
export class SearchTrackingService {
    private readonly eventBus = inject(EventBusService);
    private state: SearchTrackingInfoState = {};

    public set trackingInfoState(state: SearchTrackingInfoState) {
        this.state = state;
    }

    public trackKeywordSearch(resultCount: number): void {
        if (!this.state.cat || !this.state.entryType || !this.state.q || !this.state.src || !this.state.isNewSearchSubmit) {
            return;
        }

        this.eventBus.emit({
            key: 'internal_keyword_search_event',
            data: {
                entryType: this.state.entryType,
                q: this.state.q,
                cat: this.state.cat,
                res: resultCount,
                src: this.state.src,
            },
        });
    }
}
