<big-ui-form-row
    [id]="settings.id"
    [label]="!minimal ? label : undefined"
    [isRequired]="control?.isRequired"
    [errors]="control?.errors"
    [showError]="isInvalid"
    [showInfo]="!!settings.help"
    [errorOverrides]="errorOverrides"
    [infoTextTemplate]="help"
    (infoLayerOpened)="trackFormsInfoIconEvent($event)">
    <big-ui-select
        [id]="settings.id"
        [options]="settings.options"
        [customNoSelectionOption]="settings.emptyOption"
        [isValid]="isValid"
        [isRequired]="control?.isRequired"
        [showValidation]="isPageValidationFailed"
        [formControl]="control"
        [optionsDisabled]="!!settings.attributes?.disabled"
        [formFieldTracking]="settings" />
</big-ui-form-row>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
