/* eslint-disable @typescript-eslint/no-explicit-any */
import { type AllowedDrupalType } from '../../element-type-def/allowed-drupal-type';
import { type KeyValuePair } from '../key-value-pair';
import { type FormConfigElementAttributes } from './form-config-element-attributes.model';

type TitleDisplay = 'before' | 'invisible';
type MarkupDisplayOption = 'all' | 'form' | 'preview';

export class FormConfigElement {
    public children: FormConfigElement[] = [];

    public type!: AllowedDrupalType;
    public id!: string;
    public name!: string;
    public arrayParents: string[] = [];

    public title?: string;
    public maxlength?: number;
    public minlength?: number;
    public required?: boolean;
    public states?: any; // TODO: remove any
    public description?: string;
    public help?: string;
    public helpLayerIsOpen?: boolean;
    public attributes?: FormConfigElementAttributes;
    public step?: number;
    public size?: number;
    public placeholder?: string;
    public max?: number;
    public min?: number;
    public options?: KeyValuePair[];
    public emptyOption?: KeyValuePair;
    // eslint-disable-next-line no-null/no-null
    public excludedOptions?: string | null;
    public value?: any;
    public returnValue?: string;
    public titleDisplay?: TitleDisplay;
    public multiplePdfId?: string;
    public multipleOperations?: boolean;
    public multipleAddMore?: boolean;
    public multipleAddMoreButtonLabel?: string;
    public defaultValue?: any;
    public webform?: string;
    public webformTitle?: string;
    public markup?: string;
    public tokenRefs?: Record<string, string> | string[];
    public referencedFieldKey?: string;
    public isStateLocked?: boolean;
    public maxFileSize?: number;
    public fileExtensions?: string;
    public fileMimeTypes?: string;
    public uploadId?: string;
    public referencedFormId?: string;
    public multiple?: boolean | number;
    public parentContextOnly?: boolean;
    public extendedWebformContext?: boolean;
    public parent?: FormConfigElement;
    public previewLabel?: string;
    public wizardLabel?: string;
    public wrapperAttributes?: FormConfigElementAttributes;
    public faqGroupNode?: string;
    public faqTaxonomyFilter?: string;
    public mask?: string;
    public wizardVariant?: '' | 'default' | 'hidden';
    public showInWizard?: boolean;
    public nextButtonLabel?: string;
    public backButtonLabel?: string;
    public skipFormStepButtonLabel?: string;
    public isSubmitPage?: boolean;
    public hideTitleOnPreview?: boolean;
    public display?: string;
    public linkTitle?: string;
    public linkPath?: string;
    public inputmode?: string;
    public showMarkupOn?: MarkupDisplayOption;
    public valueAsLabelInPdf?: boolean;
    public prePopulate?: boolean;
    public localStorageKey?: string;
    public referencedMultiple?: string;
    public currencyType?: 'decimal' | 'integer';
    // frontend properties for repeating page
    public repeatedElement?: string;
    public repeatedIndex?: number;

    public get isPageElement(): boolean {
        return this.type.endsWith('_page');
    }

    public get formPath(): string[] {
        const parentPath: string[] = this.parent?.formPath ?? [];

        if (this.webform === undefined) {
            return parentPath;
        }

        // Containers belonging to the same form should not add the webform multiple times to the path
        if (!parentPath.length || parentPath[parentPath.length - 1] !== this.webform) {
            parentPath.push(this.webform);
        }

        return parentPath;
    }

    public constructor(props?: Partial<FormConfigElement>) {
        if (props) {
            Object.assign(this, props);
        }
    }

    public clone(): FormConfigElement {
        const newSettings = new FormConfigElement(this);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        newSettings.children = this.children.map(x => x.clone());

        return newSettings;
    }
}
