import { Component, HostBinding, Input, ViewChild, inject } from '@angular/core';
import { EventBusService } from '@big-direkt/event-bus';
import { FormConfigElement } from '@big-direkt/form/contracts';
import { ElementHostDirective } from '../../directives/element-host/element-host.directive';
import { type BigAnyFormControl } from '../../form-control/big-any-form-control';
import { FormElementValueService } from '../../services/form-element-value/form-element-value.service';
import { FormService } from '../../services/form/form.service';
import { PageNavigationService } from '../../services/page-navigation/page-navigation.service';
import { TokenService } from '../../services/token/token.service';

@Component({
    selector: 'big-form-base',
    template: '<ng-container appElementHost />',
})
export class BaseComponent {
    public readonly formService = inject(FormService);
    public readonly eventBus = inject(EventBusService);
    public readonly pageNavigationService = inject(PageNavigationService);
    public readonly valueService = inject(FormElementValueService);
    public readonly tokenService = inject(TokenService);

    @ViewChild(ElementHostDirective, { static: true }) public elementHost?: ElementHostDirective;

    public control?: BigAnyFormControl;
    public settings = new FormConfigElement();
    public classList = 'block [&:not(:last-child)]:mb-6';

    @HostBinding('class')
    @Input('class')
    public get hostClasses(): string {
        if (this.settings.wrapperAttributes?.ngClass) {
            return `${this.classList} ${this.settings.wrapperAttributes.ngClass.join(' ')}`;
        }

        return this.classList;
    }

    @HostBinding('class.hidden')
    public get isHidden(): boolean {
        if (!this.control) {
            return true;
        }

        if (this.control.disabled) {
            this.control.markAsUntouched();
        }

        return this.control.disabled;
    }

    public get hasPrintableErrors(): boolean {
        // eslint-disable-next-line no-null/no-null, @typescript-eslint/no-non-null-assertion
        return this.control?.errors !== null && Object.keys(this.control!.errors).length > 0;
    }

    public get isReadOnly(): string | null {
        // eslint-disable-next-line no-null/no-null
        return this.settings.attributes?.readonly ? 'readonly' : null;
    }

    public get isDisabled(): string | null {
        // eslint-disable-next-line no-null/no-null
        return this.settings.attributes?.disabled ? 'disabled' : null;
    }

    public get label(): string | undefined {
        return this.tokenService.findReplacement(this.settings.title ?? '', this.settings.arrayParents);
    }

    public static initChildControls(settings: FormConfigElement, formService: FormService): void {
        settings.children.forEach(child => {
            formService.createControls(child);
        });
    }

    public init(): void {
        this.control = this.formService.getFormControl(this.settings.arrayParents);
    }

    public trackFormsInfoIconEvent(isOpen: boolean): void {
        this.eventBus.emit({
            key: 'forms_info_icon_event',
            data: {
                isActive: isOpen,
                stepName: this.pageNavigationService.getCurrentPage()?.wizardLabel,
                fieldName: this.label,
                required: this.control?.isRequired,
            },
        });
    }
}
