import { NgStyle } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    HostBinding,
    inject,
    Input,
    SecurityContext,
    ViewChild,
    type AfterViewInit,
    type ElementRef,
    type OnDestroy,
    type OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, type Params } from '@angular/router';
import { UserRepository, UserService } from '@big-direkt/state/user';
import { WindowService } from '@big-direkt/utils/environment';
import { DomService, IframeService, PlatformService, SanitizePipe } from '@big-direkt/utils/shared';
import { take, tap } from 'rxjs/operators';
import { IframeModel } from '../iframe.model';

const EPORTRAIT_FALLBACK_HEIGHT = '700';
const LAMAPOLL_SCRIPT_POLLING_TIMER = 100;
const LAMAPOLL_SCRIPT_URL = 'https://survey.lamapoll.de/lp/js/lp.ext.js';

@Component({
    selector: 'big-ui-iframe',
    templateUrl: './iframe.component.html',
    standalone: true,
    imports: [NgStyle, SanitizePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IframeComponent implements AfterViewInit, OnDestroy, OnInit {
    public readonly sanitizeContext: SecurityContext = SecurityContext.RESOURCE_URL;

    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly destroyRef = inject(DestroyRef);
    private readonly iframeService = inject(IframeService);
    private readonly domService = inject(DomService);
    private readonly platform = inject(PlatformService);
    private readonly userRepository = inject(UserRepository);
    private readonly userService = inject(UserService);
    private readonly windowService = inject(WindowService);

    @HostBinding('class') @Input() public classList = 'block';

    @Input() public allowedUrlParams: string[] = [];
    @Input() public allowPermissionPolicies = '';
    @Input() public autoResize = false;
    @Input() public data!: IframeModel;
    @Input() public height?: number;

    @ViewChild('iframe') public iframeElement: ElementRef<HTMLIFrameElement> | undefined;

    private _lamapollId?: string;

    public get isLamapoll(): boolean {
        return this.data.url.includes('survey.lamapoll.de');
    }

    public get lamapollId(): string {
        if (!this._lamapollId) {
            this._lamapollId = `lp_survey_${this.iframeService.nextId()}`;
        }

        return this._lamapollId;
    }

    public ngOnInit(): void {
        if (this.isLamapoll) {
            this.domService.createScriptTag(LAMAPOLL_SCRIPT_URL, {
                target: this.windowService.document().head,
                async: false,
            });
        }

        this.activatedRoute.queryParams
            .pipe(
                take(1),
                tap((params: Params): void => {
                    const url: URL = new URL(this.data.url, this.windowService.document().location.origin);

                    if (Object.entries(params).length) {
                        Object.entries(params).forEach(([name, value]): void => {
                            if (this.allowedUrlParams.includes(name)) {
                                url.searchParams.set(name, value);
                            }
                        });
                    }

                    this.data.url = url.href;
                }),
            )
            .subscribe();
    }

    public ngAfterViewInit(): void {
        if (!this.platform.isPlatformServer() && this.isLamapoll) {
            this.loadSurvey();
        }

        if (this.platform.isPlatformServer() || !this.iframeElement || !this.autoResize || this.height !== undefined) {
            return;
        }

        if (this.data.url.includes('eportrait')) {
            this.windowService.nativeWindow()?.addEventListener('message', this.eportraitEventHandler);
            this.allowPermissionPolicies = 'camera';
        }

        this.applyPermissionsAndReloadIframe();
    }

    public ngOnDestroy(): void {
        if (this.platform.isPlatformServer()) {
            /* istanbul ignore next */
            return;
        }

        this.windowService.nativeWindow()?.removeEventListener('message', this.eportraitEventHandler);
    }

    public onIframeLoaded(): void {
        if (!this.iframeElement?.nativeElement.contentWindow || !this.data.forwardSession) {
            return;
        }

        this.iframeService.postMessage(
            this.iframeElement.nativeElement.contentWindow,
            { key: 'vintage-auth-bridge', payload: { token: this.userRepository.getToken() } },
            this.windowService.document().location.origin,
        );
    }

    private loadSurvey(): void {
        /* istanbul ignore next */
        setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const w = this.windowService.nativeWindow();
            if (w?.lp_load_survey) {
                w.lp_load_survey(this.lamapollId, this.data.url, 'auto');
            } else {
                this.loadSurvey();
            }
        }, LAMAPOLL_SCRIPT_POLLING_TIMER);
    }

    private applyPermissionsAndReloadIframe(): void {
        if (this.platform.isPlatformServer() || !this.iframeElement) {
            /* istanbul ignore next */
            return;
        }

        this.iframeElement.nativeElement.setAttribute('allow', this.allowPermissionPolicies);

        // Needed for applying the permission policies correctly
        // eslint-disable-next-line no-self-assign
        this.iframeElement.nativeElement.src = this.iframeElement.nativeElement.src;
    }

    private readonly eportraitEventHandler = (event: MessageEvent<{ from: string; height: number; isLoaded: boolean }>): void => {
        if (event.data.from === 'EPORTRAIT_UI' && this.iframeElement) {
            // Resize iframe
            this.iframeElement.nativeElement.height = event.data.height ? event.data.height.toString() : EPORTRAIT_FALLBACK_HEIGHT;

            // post user data to iframe
            if (event.data.isLoaded) {
                this.userService
                    .getProfile()
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe((profile): void => {
                        if (!this.iframeElement?.nativeElement.contentWindow || !profile) {
                            return;
                        }

                        this.iframeService.postMessage(
                            this.iframeElement.nativeElement.contentWindow,
                            {
                                type: 'POST_FIELDS',
                                fields: {
                                    bkvnr: profile.identifier.healthInsuranceNumber,
                                    birthdate: profile.birthdate,
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    user_mail_rejected_image: '', // must be empty due to data protection reasons
                                },
                            },
                            new URL(this.data.url).origin,
                        );
                    });
            }
        }
    };
}
