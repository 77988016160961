import { type SubmissionAddress } from '../../models/form-submission/submission-address.model';
import { type SubmissionValueState } from '../../models/form-submission/submission-value-state.model';
import { type AccessorFn, type TypeDef } from '../drupal-webform-types';
import { SUBMISSION_MAPPER_COMPOSITE, SUBMISSION_VALUE_ACCESSOR_COMPOSITE } from './value-accessor-composite';

// eslint-disable-next-line sonarjs/function-return-type
export const VALUE_ACCESSOR_ADDRESS: AccessorFn = (v, element) => {
    if (!element || (v as SubmissionValueState | undefined)?.isDisabled) {
        return undefined;
    }

    const value = v as SubmissionAddress | undefined;
    const dynamicFormName = element.name;

    const address = value?.[`${dynamicFormName}[address]`] ?? '';
    const address2 = value?.[`${dynamicFormName}[address_2]`] ?? '';
    const postBox = value?.[`${dynamicFormName}[post_box]`] ?? '';
    const addressAddition = value?.[`${dynamicFormName}[address_addition]`] ?? '';
    const city = value?.[`${dynamicFormName}[city]`] ?? '';
    const country = value?.[`${dynamicFormName}[country]`] ?? '';
    const postalCode = value?.[`${dynamicFormName}[postal_code]`] ?? '';

    const lines: string[] = [`${address} ${address2}`, `${postBox} ${addressAddition}`, `${postalCode} ${city}`, country]
        .map((line?: string): string => line?.trim() ?? '')
        .filter(Boolean);

    return lines.length ? lines : '';
};

export const TYPE_DEF_ADDRESS: TypeDef = {
    isInput: true,
    isStructure: true,
    preventChildProcessing: true,
    previewAccessor: VALUE_ACCESSOR_ADDRESS,
    submissionValueAccessor: SUBMISSION_VALUE_ACCESSOR_COMPOSITE,
    submissionMapper: SUBMISSION_MAPPER_COMPOSITE,
};
