<ng-container *transloco="let t; prefix: 'ftbRadios'">
    <big-ui-form-row
        [id]="settings.id"
        [label]="label"
        [showInfoButtonInLabel]="true"
        [isRequired]="control?.isRequired"
        [formGroup]="control"
        [errors]="control?.errors"
        [showError]="isInvalid"
        [showInfo]="!!settings.help"
        [errorOverrides]="errorOverrides"
        [infoTextTemplate]="help"
        (infoLayerOpened)="trackFormsInfoIconEvent($event)">
        <big-ui-radios
            [id]="settings.id"
            [name]="settings.name"
            [items]="settings.children"
            [formControl]="control"
            [isValid]="isValid"
            [isInvalid]="isInvalid"
            [isRequired]="control?.isRequired"
            [formFieldTracking]="settings"
            (infoLayerOpened)="onHelpLayerClick($event)" />
    </big-ui-form-row>
</ng-container>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
