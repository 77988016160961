import { Component } from '@angular/core';
import { StructureBaseComponent } from '../../../base-components/structure-base/structure-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-container',
    templateUrl: './container.component.html',
})
export class ContainerComponent extends StructureBaseComponent {
    public static register(): ComponentMap {
        return {
            container: ContainerComponent,
            webform_section: ContainerComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }
}
