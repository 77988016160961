import { Component } from '@angular/core';
import { HiddenComponent } from '../../../fields/components/hidden/hidden.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-relation',
    template: '',
})
export class FormRelationComponent extends HiddenComponent {
    public static override  register(): ComponentMap {
        return {
            big_webform_form_element_ref: FormRelationComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }
}
