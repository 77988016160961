import { inject, Injectable, signal, type Signal } from '@angular/core';
import { EventBusService } from '@big-direkt/event-bus';
import { type FormConfigElement, type PageHierarchyNode, type PageLocationContract } from '@big-direkt/form/contracts';
import { BehaviorSubject, type Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { FormService } from '../form/form.service';
import { PageHierarchyFormNode } from './page-hierarchy-node';

@Injectable({
    providedIn: 'root',
})
export class PageNavigationService implements PageLocationContract {
    private readonly formService = inject(FormService);
    private readonly currentNode = signal<PageHierarchyFormNode | undefined>(undefined);
    private readonly _pendingStates: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    public rootNode?: PageHierarchyFormNode;

    public get pages(): PageHierarchyFormNode[] {
        return this.rootNode?.children ?? [];
    }

    public get wizardPages(): PageHierarchyNode[] {
        return this.pages.filter((node: PageHierarchyFormNode): boolean => node.hasWizardEntry && node.isAccessible);
    }

    public get wizardPageCount(): number {
        return this.wizardPages.length;
    }

    public get isBusy(): Observable<boolean> {
        return this._pendingStates.pipe(map((states: string[]): boolean => Boolean(states.length)));
    }

    public constructor(private readonly eventBus: EventBusService) {}

    public initialize(root: FormConfigElement): void {
        this.rootNode = new PageHierarchyFormNode(root, undefined, this.formService);
        this.currentNode.set(this.rootNode.firstAccessibleLeaf);
        this.trackPage();
    }

    public reset(): void {
        this.rootNode = undefined;
        this.currentNode.set(undefined);
    }

    public addPage(page: FormConfigElement, predecessor: FormConfigElement): void {
        this.rootNode?.addPageNode(page, predecessor);
    }

    public deletePage(name: string): void {
        this.rootNode?.deletePageNode(name);
    }

    public getCurrentPage(): PageHierarchyFormNode | undefined {
        return this.currentNode();
    }

    public getCurrentPageSignal(): Signal<PageHierarchyFormNode | undefined> {
        return this.currentNode.asReadonly();
    }

    public getNextPage(): PageHierarchyFormNode | undefined {
        return this.currentNode()?.nextAccessibleLeaf;
    }

    public getPreviousPage(): PageHierarchyFormNode | undefined {
        return this.currentNode()?.previousAccessibleLeaf;
    }

    public isBeforeCurrentPage(page: PageHierarchyNode): boolean {
        const currentPage: PageHierarchyNode | undefined = this.getCurrentPage();

        return !currentPage || page.isBefore(currentPage);
    }

    public gotoPage(node: PageHierarchyFormNode): void {
        this.isBusy
            .pipe(
                first(),
                filter((isBusy: boolean): boolean => !isBusy),
            )
            .subscribe((): void => {
                const target: PageHierarchyFormNode | undefined = node.firstAccessibleLeaf;

                if (!target) {
                    return;
                }

                this.currentNode.set(target);

                if (this.currentNode()?.hasNextAccessibleLeaf) {
                    this.trackPage();
                }
            });
    }

    public gotoNextPage(): void {
        const nextNode: PageHierarchyFormNode | undefined = this.getNextPage();

        if (nextNode) {
            this.gotoPage(nextNode);
        }
    }

    public gotoPreviousPage(): void {
        const previousNode: PageHierarchyFormNode | undefined = this.getPreviousPage();

        if (previousNode) {
            this.gotoPage(previousNode);
        }
    }

    public setBusy(stateName: string): void {
        this._pendingStates.next([...this._pendingStates.getValue(), stateName]);
    }

    public setIdle(stateName: string): void {
        this._pendingStates.next(this._pendingStates.getValue().filter((stateKey: string): boolean => stateKey !== stateName));
    }

    public trackPage(customStepName?: string): void {
        let stepName: string | undefined = this.currentNode()?.wizardLabel;
        const multiplePageIndex: number | undefined = this.currentNode()?.getMultiplePageIndex();

        // If current node is a child of an repeatable element use the parents index to differ between each child
        if (multiplePageIndex !== undefined) {
            stepName = `${this.currentNode()?.wizardLabel ?? ''} (Child-Element ${multiplePageIndex + 1})`;
        }

        this.eventBus.emit({
            key: 'form_page_event',
            data: {
                formId: this.rootNode?.firstAccessibleLeaf?.settings.webform,
                formTitle: this.rootNode?.settings.webformTitle,
                stepName: customStepName ?? stepName,
            },
        });
    }
}
