<big-ui-user-data [viewMode]="'breaking-left'" />

<div class="flex flex-col mr-2">
    @for (page of pages | wizardVisible; track pageIndex; let pageIndex = $index; let isLast = $last) {
        <div class="flex items-center gap-3 relative min-h-[88px] group">
            <!-- the line before -->
            <div
                class="-z-[1] w-1 h-1/2 bottom-1/2 left-[20px] -translate-x-1/2 absolute transition-[background] bg-alt group-first:hidden"
                [ngClass]="{
                    '!bg-primary': isPageActive(page) || !currentPage?.isBefore(page),
                }"></div>
            <div>
                <big-form-wizard-step-item
                    [active]="isPageActive(page) || !currentPage?.isBefore(page)"
                    [isLast]="isLast"
                    [page]="pageIndex + 1" />
            </div>
            <div
                class="overflow-auto break-words hyphens-auto"
                lang="de"
                [ngClass]="{
                    'fw-bold': isPageActive(page),
                    'py-2 after:content-[attr(data-after)] after:block after:font-normal': !isLast,
                }"
                [attr.data-after]="isPageActive(page) && currentPage?.level > 1 ? currentPage?.wizardLabel : undefined">
                @if (isClickableWizardEnabled() && !isPageActive(page) && !currentPage?.isBefore(page) && !isLastPageActive) {
                    <button
                        (click)="goToPage(page)"
                        type="button"
                        class="text-left underline cursor-pointer text-primary hover:text-primary-light">
                        {{ page.wizardLabel | replaceTokenRef: page.settings.arrayParents }}
                    </button>
                } @else {
                    {{ page.wizardLabel | replaceTokenRef: page.settings.arrayParents }}
                }
            </div>
            <!-- the line after -->
            <div
                class="-z-[1] w-1 h-1/2 top-1/2 left-[20px] -translate-x-1/2 absolute transition-[background] bg-alt group-last:hidden"
                [ngClass]="{
                    '!bg-primary': currentPage?.isAfter(page),
                }"></div>
        </div>
    }
</div>
