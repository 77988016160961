import { type AbstractControl, type ValidationErrors } from '@angular/forms';
import { parsePhoneNumber, type ParsedPhoneNumber } from 'awesome-phonenumber';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class LibPhoneNumber {
    private static readonly phoneNumberInvalidResult: ValidationErrors = {
        phoneNumber: {
            valid: false,
        },
    };

    public static validateFormControl(fc: AbstractControl): ValidationErrors | null {
        // eslint-disable-next-line no-null/no-null
        if (fc.value === null || typeof fc.value !== 'string' || fc.value === '') {
            // eslint-disable-next-line no-null/no-null
            return null;
        }

        const phoneNumber: ParsedPhoneNumber = parsePhoneNumber(fc.value, { regionCode: 'DE' });

        // eslint-disable-next-line no-null/no-null
        return phoneNumber.valid ? null : LibPhoneNumber.phoneNumberInvalidResult;
    }
}
