import { type SubmissionValueState } from '../../models/form-submission/submission-value-state.model';
import { type AccessorFn } from '../drupal-webform-types';

// lists all filled inner control titles (usually only contains checkboxes)
// eslint-disable-next-line sonarjs/function-return-type
export const VALUE_ACCESSOR_FIELD_GROUP: AccessorFn = (v, settings) => {
    if ((v as SubmissionValueState | undefined)?.isDisabled) {
        return undefined;
    }

    const value = v as Record<string, SubmissionValueState | boolean | string>;
    const selectedLabels = settings?.children
        // eslint-disable-next-line sonarjs/function-return-type
        .filter(child => {
            const childValue = value[child.name];

            if ((childValue as SubmissionValueState | undefined)?.isDisabled) {
                return false;
            }

            return childValue;
        })
        .map(child => child.title ?? '');

    return selectedLabels?.length ? selectedLabels : '';
};
