import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MobileAppRepository } from '@big-direkt/state/mobile-app';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { ExternalEventService, MinAppVersionPipe } from '@big-direkt/utils/shared';
import { BaseComponent } from '../../../base-components/base/base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-navigation-button',
    templateUrl: './navigation-button.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [UiButtonComponent, MinAppVersionPipe, AsyncPipe],
})
export class NavigationButtonComponent extends BaseComponent {
    private readonly router = inject(Router);
    private readonly mobileAppRepository = inject(MobileAppRepository);
    private readonly externalEvents = inject(ExternalEventService);

    public readonly isEmbeddedInMobileApp = this.mobileAppRepository.isEmbeddedInMobileApp;
    public readonly version = this.mobileAppRepository.version;

    public static register(): ComponentMap {
        return {
            big_webform_navigation_button: NavigationButtonComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }

    public async navigate(): Promise<void> {
        const isEmbedded = this.mobileAppRepository.isEmbeddedInMobileApp();
        if (this.settings.linkPath) {
            if (isEmbedded) {
                this.externalEvents.dispatch('requestNavigation', { target: this.settings.attributes?.navigation_key });
            } else {
                await this.router.navigateByUrl(this.settings.linkPath);
            }
        }
    }
}
