import { type DestroyRef } from '@angular/core';

export class MyDestroyRef implements DestroyRef {
    private readonly callbacks: (() => void)[] = [];

    public destroy(): void {
        this.callbacks.forEach(c => {
            c();
        });
    }

    public onDestroy(callback: () => void): () => void {
        this.callbacks.push(callback);

        return () => {
            this.destroy();
        };
    }
}
