import { Injectable, inject } from '@angular/core';
import { DRUPAL_WEBFORM_TYPE_CONFIG, FormConfigElement, SUBMISSION_VALUE_ACCESSOR_DEFAULT, SubmissionObject } from '@big-direkt/form/contracts';
import { BigFileUploadFormControl } from '../../form-control/big-form-control';
import { deepMerge } from '../form-element-value/deep-merge';
import { FormService } from '../form/form.service';

@Injectable({
    providedIn: 'root',
})
export class SubmissionValueProviderService {
    private readonly formService = inject(FormService);

    public getSubmissionValue(settings: FormConfigElement): SubmissionObject | undefined {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const control = this.formService.getFormControl(settings.arrayParents)!;
        const { value, disabled } = control;

        if (disabled) {
            return { [settings.name]: { isDisabled: true } };
        }

        const typeConfigDef = DRUPAL_WEBFORM_TYPE_CONFIG[settings.type];

        // dirty workaround because file-upload control misuses value
        const accessorValue = (control as BigFileUploadFormControl | undefined)?.files ?? value;

        let parsedData: SubmissionObject | undefined;
        if (typeConfigDef.isStructure) {
            parsedData = {};
            settings.children.forEach(child => {
                deepMerge(parsedData, this.getSubmissionValue(child));
            });
        }

        if (typeConfigDef.submissionValueAccessor) {
            return typeConfigDef.submissionValueAccessor(accessorValue, settings, parsedData);
        }

        return SUBMISSION_VALUE_ACCESSOR_DEFAULT(parsedData ?? value, settings);
    }
}
