/* eslint-disable @typescript-eslint/naming-convention */
import { type SubmissionObject } from '../../models/form-submission/submission-object.model';
import { type SubmissionTimeRange } from '../../models/form-submission/submission-time-range.model';
import { type SubmissionValueState } from '../../models/form-submission/submission-value-state.model';
import { type AccessorFn, type SubmissionValueAccessorFn } from '../drupal-webform-types';

export const WEEKDAYS: Record<number, string> = {
    0: 'Montag',
    1: 'Dienstag',
    2: 'Mittwoch',
    3: 'Donnerstag',
    4: 'Freitag',
    5: 'Samstag',
    6: 'Sonntag',
};

export const VALUE_ACCESSOR_TIME_RANGE: AccessorFn = v => {
    const value = v as SubmissionTimeRange | undefined;
    if (!value || (v as SubmissionValueState | undefined)?.isDisabled) {
        return undefined;
    }

    const valueKeys = Object.keys(value);

    const lines: string[] = [];
    Object.values(WEEKDAYS).forEach((day, index) => {
        const dayKey = `day_${index}_field_`;
        const fields = valueKeys.filter(key => key.startsWith(dayKey));

        if (fields.length) {
            lines.push(`${day}:`);
        }
        fields
            .filter(key => key.endsWith('start'))
            .forEach(startKey => {
                const endKey = startKey.replace('start', 'end');
                if (fields.includes(endKey)) {
                    lines.push(`${value[startKey]} Uhr - ${value[endKey]} Uhr`);
                }
            });
    });

    return lines.length ? lines : undefined;
};

export const SUBMISSION_VALUE_ACCESSOR_TIME_RANGE: SubmissionValueAccessorFn = (v, settings) => {
    const { name, value: valueTemplate } = settings;
    const value = v as SubmissionObject;

    const parsedData: Record<string, SubmissionObject> = {};
    parsedData[name] = {};

    Object.keys(valueTemplate).forEach((key: string): void => {
        const compositeKey = `${name}[${key}]`;
        if (value[compositeKey]) {
            parsedData[name][key] = value[compositeKey];
        }
    });

    return parsedData;
};
