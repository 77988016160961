<ng-container *transloco="let t; prefix: 'ftbCheckboxes'"></ng-container>

<big-ui-form-row
    [id]="settings.id"
    [label]="label"
    [isRequired]="control?.isRequired"
    [formGroup]="control"
    [errors]="control?.errors"
    [showError]="formService.isPageValidationFailed"
    [errorOverrides]="errorOverrides"
    [showInfo]="!!settings.help"
    [infoTextTemplate]="help"
    (infoLayerOpened)="trackFormsInfoIconEvent($event)">
    <ng-template appElementHost />
</big-ui-form-row>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
