import { Component, inject, LOCALE_ID } from '@angular/core';
import { InputBaseComponent } from '../../../base-components/input-base/input-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-number',
    templateUrl: './numberfield.component.html',
})
export class NumberfieldComponent extends InputBaseComponent {
    public readonly locale = inject(LOCALE_ID);

    public static register(): ComponentMap {
        return {
            number: NumberfieldComponent,
        };
    }
}
