import { type SubmissionValueState } from '../../models/form-submission/submission-value-state.model';
import { type AccessorFn } from '../drupal-webform-types';

export const VALUE_ACCESSOR_CURRENCY: AccessorFn = v => {
    if ((v as SubmissionValueState | undefined)?.isDisabled) {
        return undefined;
    }

    if (!v || typeof v !== 'number') {
        return '0 €';
    }
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const valueAsString = v.toFixed(2);
    // eslint-disable-next-line prefer-const
    let [ones, decimals] = valueAsString.split('.');
    ones = Number.parseInt(ones, 10).toLocaleString('de-DE');

    return decimals ? `${ones},${decimals} €` : `${ones} €`;
};
