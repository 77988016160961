import { type FormConfigElement } from '@big-direkt/form/contracts';

export const findHiddenElements = (element: FormConfigElement | undefined): FormConfigElement[] => {
    if (!element) {
        return [];
    }

    if (element.type === 'hidden') {
        return [element];
    }

    return element.children.flatMap(child => findHiddenElements(child));
};
