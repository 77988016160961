/* eslint-disable @typescript-eslint/unbound-method */
import { Injectable, inject } from '@angular/core';
import { Validators, type ValidatorFn } from '@angular/forms';
import { DRUPAL_WEBFORM_TYPE_CONFIG, type FormConfigElement } from '@big-direkt/form/contracts';
import { type BigUntypedFormGroup } from '../../form-control/big-form-group';
import { FormGroupRequiredValidator } from '../../utilities/form-group-required-validator/form-group-required-validator';
import { FormService } from '../form/form.service';

export type ConditionalStateCallbackFunction = (element: FormConfigElement, state: boolean) => void;

export type ConditionalStateCallbackType =
    | 'disabled'
    | 'enabled'
    | 'invisible'
    | 'optional'
    | 'readonly'
    | 'readwrite'
    | 'required'
    | 'checked'
    | 'unchecked'
    | 'visible';

@Injectable({
    providedIn: 'root',
})
export class ConditionalStateCallbackService {
    private readonly formService = inject(FormService);

    private readonly supportedStates: Record<ConditionalStateCallbackType, ConditionalStateCallbackFunction> = {
        /* eslint-disable @typescript-eslint/no-confusing-void-expression */
        disabled: (element, state) => this.enabled(element, !state),
        enabled: (element, state) => this.enabled(element, state),
        invisible: (element, state) => this.visible(element, !state),
        optional: (element, state) => this.required(element, !state),
        readonly: (element, state) => this.readOnly(element, state),
        readwrite: (element, state) => this.readOnly(element, !state),
        required: (element, state) => this.required(element, state),
        unchecked: (element, state) => this.unchecked(element, state),
        checked: (element, state) => this.checked(element, state),
        visible: (element, state) => this.visible(element, state),
        /* eslint-enable @typescript-eslint/no-confusing-void-expression */
    };

    public get(stateType: ConditionalStateCallbackType): ConditionalStateCallbackFunction {
        const handlerFn = this.supportedStates[stateType];

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition,sonarjs/different-types-comparison
        if (handlerFn !== undefined) {
            return handlerFn;
        }

        throw new Error(`The state '${stateType}' is not supported`);
    }

    private enabled(element: FormConfigElement, setEnabled: boolean): void {
        const disabled = setEnabled ? undefined : 'disabled';

        element.attributes = { ...element.attributes, disabled };
    }

    private visible(element: FormConfigElement, setVisible: boolean): void {
        const control = this.formService.getFormControl(element.arrayParents);

        if (control?.parent && !control.parent.enabled && element.type !== 'big_webform_repeated_page') {
            return;
        }

        if (setVisible) {
            control?.enable({ onlySelf: true });

            return;
        }

        this.formService.resetValue(element);
        control?.disable({ emitEvent: false, onlySelf: true });
    }

    private required(element: FormConfigElement, setRequired: boolean): void {
        const control = this.formService.getFormControl(element.arrayParents);
        if (!control || control.isRequired === setRequired) {
            return;
        }

        control.isRequired = setRequired;

        const { isStructure } = DRUPAL_WEBFORM_TYPE_CONFIG[element.type];

        const validator = ConditionalStateCallbackService.getRequiredValidator(element, isStructure);
        if (setRequired) {
            control.rawValidators = [...control.rawValidators, validator];
        } else {
            const validatorIndex = control.rawValidators.findIndex(v => v === validator);
            control.rawValidators.splice(validatorIndex, 1);
        }

        if (!setRequired && isStructure) {
            FormGroupRequiredValidator.unsetErrors(control as BigUntypedFormGroup);
        }

        control.setValidators(control.rawValidators);
        control.updateValueAndValidity();
    }

    private readOnly(element: FormConfigElement, setReadOnly: boolean): void {
        const readonly = setReadOnly ? 'readonly' : undefined;

        element.attributes = { ...element.attributes, readonly };
    }

    private checked(element: FormConfigElement, setChecked: boolean): void {
        if (!setChecked) {
            return;
        }

        const control = this.formService.getFormControl(element.arrayParents);
        control?.setValue(setChecked);
    }

    private unchecked(element: FormConfigElement, setUnchecked: boolean): void {
        if (!setUnchecked) {
            return;
        }

        const control = this.formService.getFormControl(element.arrayParents);
        if (control?.value !== (element.defaultValue ?? '')) {
            this.formService.resetValue(element);
        }
    }

    private static getRequiredValidator(settings: FormConfigElement, isStructure: boolean | undefined): ValidatorFn {
        const validatorKey = DRUPAL_WEBFORM_TYPE_CONFIG[settings.type].requiredValidatorKey ?? 'required';
        const requiredValidator = validatorKey === 'required' ? Validators.required : Validators.requiredTrue;

        return isStructure ? FormGroupRequiredValidator.validate : requiredValidator;
    }
}
