/* eslint-disable no-null/no-null */
import { type ValidatorFn } from '@angular/forms';
import { type FormConfigElement } from '@big-direkt/form/contracts';
import { type CustomFormControlValidatorEntry } from '../../interfaces/custom-form-control-validator.token';
import { TimeRangeComponent } from '../components/time-range/time-range.component';

export const timeRangeChildValidatorFactory: () => CustomFormControlValidatorEntry = (): CustomFormControlValidatorEntry =>
    ({
        type: 'webform_time',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, sonarjs/cognitive-complexity
        handle: (settings: FormConfigElement, validators: ValidatorFn[]): void => {
            if (settings.parent?.type !== 'big_webform_time_range') {
                return;
            }

            const index = settings.parent.children.indexOf(settings);
            // The field naming is `fieldname[day_0_field_0_start]`, so we match for _start and _end
            if (settings.name.includes('_start')) {
                const end = settings.parent.children[index + 1];
                validators.push(TimeRangeComponent.validateTimeRange(settings, end, 'isStartTime'));
            }

            if (settings.name.includes('_end')) {
                const start = settings.parent.children[index - 1];
                validators.push(TimeRangeComponent.validateTimeRange(start, settings, 'isEndTime'));
            }
        },
    }) as CustomFormControlValidatorEntry;
