<big-ui-form-row
    [id]="settings.id"
    [label]="label"
    [isRequired]="control?.isRequired"
    [showInfo]="!!settings.help"
    [infoTextTemplate]="help"
    (infoLayerOpened)="trackFormsInfoIconEvent($event)">
    @if (settings.attributes?.multiline) {
        <!-- TODO: Please make me to a component -->
        <textarea
            class="form-control min-h-[168px]"
            readonly
            >{{ settings.markup }}</textarea
        >
    } @else {
        <input
            class="form-control"
            [id]="settings.id"
            readonly
            [value]="settings.markup" />
    }
</big-ui-form-row>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
