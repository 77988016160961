<big-ui-form-row
    [showInfo]="!!settings.help"
    [infoTextTemplate]="help"
    [id]="settings.id"
    [label]="label"
    [isRequired]="control?.isRequired"
    [errors]="control?.errors"
    [showError]="isInvalid"
    (infoLayerOpened)="trackFormsInfoIconEvent($event)">
    <big-ui-one-time-token
        [id]="settings.id"
        [numberFields]="numberFields"
        [formControl]="control"
        [value]="control?.value ?? ''"
        [isDisabled]="isDisabled"
        [isValid]="control?.valid"
        [isTouched]="control?.touched"
        [showValidation]="control?.touched && !isDisabled"
        [formFieldTracking]="settings"></big-ui-one-time-token>
</big-ui-form-row>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
