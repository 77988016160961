import { type SubmissionDatetime } from '../../models/form-submission/submission-datetime.model';
import { type SubmissionObject } from '../../models/form-submission/submission-object.model';
import { type AccessorFn, type SubmissionValueAccessorFn, type TypeDef } from '../drupal-webform-types';
import { VALUE_ACCESSOR_DATE } from './value-accessor-date';
import { VALUE_ACCESSOR_TIME } from './value-accessor-time';

// eslint-disable-next-line sonarjs/function-return-type
export const VALUE_ACCESSOR_DATETIME: AccessorFn = v => {
    const value = v as SubmissionDatetime | undefined;

    if (!value?.date || isNaN(new Date(value.date).getTime())) {
        return '';
    }

    const dateStr = new Date(value.date).toISOString().split('T')[0];

    if (value.date && !value.time) {
        return VALUE_ACCESSOR_DATE(dateStr);
    }

    return `${VALUE_ACCESSOR_DATE(dateStr) as string}, ${VALUE_ACCESSOR_TIME(value.time) as string}`;
};

export const SUBMISSION_VALUE_ACCESSOR_DATETIME: SubmissionValueAccessorFn = (v, { name }) => {
    const value = v as SubmissionObject;
    const dateValue = value[`${name}[date]`] as number | undefined;
    const timeValue = value[`${name}[time]`] as string | undefined;

    if (!dateValue && !timeValue) {
        return undefined;
    }

    const date: Date = dateValue ? new Date(dateValue) : new Date();

    if (isNaN(date.getTime())) {
        return undefined;
    }

    if (timeValue) {
        const splittedTime = timeValue.split(':').map(str => parseInt(str, 10));
        date.setHours(splittedTime[0], splittedTime[1]);
    }

    return {
        [name]: {
            time: timeValue,
            date: date.toISOString(),
        },
    };
};

export const SUBMISSION_MAPPER_DATETIME = (data: SubmissionObject, propName: string): void => {
    const submissionObject = data[propName] as SubmissionDatetime | undefined;

    if (submissionObject?.date) {
        data[propName] = new Date(submissionObject.date).toISOString();
    }
};

export const TYPE_DEF_DATETIME: TypeDef = {
    isInput: true,
    isStructure: true,
    preventChildProcessing: true,
    previewAccessor: VALUE_ACCESSOR_DATETIME,
    submissionMapper: SUBMISSION_MAPPER_DATETIME,
    submissionValueAccessor: SUBMISSION_VALUE_ACCESSOR_DATETIME,
};
