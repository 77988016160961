import { Component, computed, inject, signal, type OnInit } from '@angular/core';
import { MobileAppRepository } from '@big-direkt/state/mobile-app';
import { type IframeModel } from '@big-direkt/ui/iframe';
import { BaseComponent } from '../../../base-components/base/base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-iframe',
    templateUrl: './iframe.component.html',
})
export class IframeComponent extends BaseComponent implements OnInit {
    private readonly mobileAppRepository = inject(MobileAppRepository);
    private readonly isNetigateSurvey = signal(false);

    public readonly isInAppAndNetigate = computed(() => this.mobileAppRepository.isEmbeddedInMobileApp() && this.isNetigateSurvey());

    public iFrameData!: IframeModel;

    public static register(): ComponentMap {
        return {
            big_webform_iframe: IframeComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }

    public ngOnInit(): void {
        if (this.settings.attributes?.iframeUrl === undefined) {
            return;
        }

        this.isNetigateSurvey.set(this.settings.attributes.iframeUrl.includes('netigate'));

        this.iFrameData = {
            url: this.settings.attributes.iframeUrl,
            forwardSession: false,
        };
    }
}
