<div
    *transloco="let t; prefix: 'uiModalEmbedded'"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true"
    class="flex flex-col h-full">
    <div class="flex-none bg-gray-400">
        <div class="flex mx-6 my-4">
            @if (label) {
                <div class="flex-1 text-primary-dark">
                    {{ label }}
                </div>
            }
            <big-ui-button
                [buttonStyle]="'icon'"
                [id]="'close-overlay-icon'"
                (clicked)="popoverRef.close()"
                [title]="t('close')">
                <big-icon
                    [icon]="iconClose"
                    class="primary text-[1.2em]"
                    [attr.aria-label]="t('close')" />
            </big-ui-button>
        </div>
    </div>
    <div class="flex-1 overflow-auto">
        @if (isImage && url) {
            <img
                [src]="url"
                [alt]="label"
                class="object-contain w-full h-full" />
        } @else {
            @if (url) {
                <embed
                    [src]="url | sanitize: securityContext"
                    class="max-w-full max-h-full"
                    [height]="viewPortSize().height"
                    [width]="viewPortSize().width" />
            }
        }
    </div>
    <div class="grid flex-none my-4 place-items-center">
        <big-ui-button
            [buttonStyle]="'secondary'"
            [id]="'close-overlay-btn'"
            (clicked)="popoverRef.close()"
            [title]="t('close')">
            {{ t('close') }}
        </big-ui-button>
    </div>
</div>
