import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, TemplateRef, signal } from '@angular/core';
import { type ValidationErrors } from '@angular/forms';
import { UiErrorComponent, type MessageTemplate } from '@big-direkt/ui/error';
import { UiInfoButtonComponent } from '@big-direkt/ui/info-button';
import { UiLabelComponent } from '@big-direkt/ui/label';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'big-ui-form-row',
    templateUrl: './form-row.component.html',
    standalone: true,
    imports: [NgTemplateOutlet, NgClass, UiLabelComponent, UiInfoButtonComponent, UiErrorComponent, TranslocoDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [provideTranslationScope('uiFormRow', /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`))],
})
export class FormRowComponent {
    @Input() public showInfo = true;
    @Input() public label?: string;
    @Input({ required: true }) public id!: string;
    @Input() public isRequired = false;
    @Input() public isDisabled = false;
    @Input() public showError = false;
    @Input() public onlyRenderFirstError = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() public errorOverrides?: Partial<Record<string, MessageTemplate<any>>>;
    @Input() public infoTextTemplate?: TemplateRef<unknown>;
    @Input() public showInfoButtonInLabel? = false;
    @Input() public rowClass = 'block [&:not(:last-of-type)]:mb-6';
    @Input() public infoClass = 'w-10 text-right';
    @Input() public radiogroupId?: string;

    @Output() public readonly infoLayerOpened = new EventEmitter<boolean>();
    public isTooltipOpen = false;
    public hasErrors = signal<boolean>(false);
    public errorMessages = signal<ValidationErrors | null | undefined>(undefined);

    @Input() public set errors(value: ValidationErrors | null | undefined) {
        this.hasErrors.set(value ? !!Object.keys(value).length : false);
        this.errorMessages.set(value);
    }

    @HostBinding('class') public get classList(): string {
        return this.rowClass;
    }

    public onInfoLayerOpen(isOpen: boolean): void {
        this.isTooltipOpen = isOpen;
        this.infoLayerOpened.emit(this.isTooltipOpen);
    }
}
