import { Injectable, inject } from '@angular/core';
import { FormConfigElement } from '@big-direkt/form/contracts';
import { ConditionalFieldsService } from '../conditional-fields/conditional-fields.service';
import { FormService } from '../form/form.service';
import { MultipleGroupService } from '../multiple-group/multiple-group.service';
import { TokenService } from '../token/token.service';
import { MultipleHandler } from './multiple.handler';

@Injectable({
    providedIn: 'root',
})
export class MultiplesService {
    private readonly tokenService = inject(TokenService);
    private readonly formService = inject(FormService);
    private readonly conditionalFieldsService = inject(ConditionalFieldsService);
    private readonly multiGroupService = inject(MultipleGroupService);

    private handlers: Record<string, MultipleHandler | undefined> = {};

    public registerMultiples(config: FormConfigElement): void {
        if (config.type === 'big_webform_multiple' && this.handlers[config.id] === undefined) {
            this.handlers[config.id] = new MultipleHandler(
                config,
                this,
                this.tokenService,
                this.formService,
                this.conditionalFieldsService,
                this.multiGroupService,
            );
        }
        config.children.forEach(x => {
            this.registerMultiples(x);
        });
    }

    public getHandler(settings: FormConfigElement): MultipleHandler {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.handlers[settings.id]!;
    }

    public reset(): void {
        this.handlers = {};
    }
}
