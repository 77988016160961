import { HttpStatusCode } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { JsonApiResourceMapper, type ResourceCollection, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { type NodeModel, NodeTypes } from '@big-direkt/utils/shared';
import { type ErrorNodeModel } from '../models/error-node-model';
import { type NodeBigErrorResource } from '../resources/node-big-error.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NodeBigErrorMapper extends ResourceMapper<NodeModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.NodeError;

    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: NodeBigErrorResource, collection: ResourceCollection): ErrorNodeModel {
        const statusCode: number = parseInt(resource.attributes.http_statuscode, 10);

        const node: ErrorNodeModel = {
            type: NodeTypes.Error,
            nodeId: resource.attributes.node_id ?? 0,
            heading: resource.attributes.title,
            paragraphs: this.resourceIdentifierMapper.mapMultiple(resource.relationships.content.data, collection),
            metadata: resource.attributes.metatag ?? [],
            httpStatuscode: !Number.isNaN(statusCode) ? statusCode : HttpStatusCode.NotFound,
            alertType: resource.attributes.alert_type,
        };

        if (resource.relationships.image_introduction.data) {
            node.pageTitle = {
                picture: this.resourceIdentifierMapper.map(resource.relationships.image_introduction.data, collection),
            };
        }

        return node;
    }
}
