import { Validators, type ValidatorFn } from '@angular/forms';
import { type FormConfigElement } from '@big-direkt/form/contracts';
import { type CustomFormControlValidatorEntry } from '../../interfaces/custom-form-control-validator.token';
import { PasswordConfirmComponent } from '../components/password-confirm/password-confirm.component';

export const passwordConfirmValidatorFactory: () => CustomFormControlValidatorEntry = (): CustomFormControlValidatorEntry =>
    ({
        type: 'password',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        handle: (settings: FormConfigElement, validators: ValidatorFn[]): void => {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            if (settings.parent?.type !== 'password_confirm' || settings.parent.children.length !== 2) {
                return;
            }

            // Password Field 1 only
            if (settings === settings.parent.children[0]){
                validators.push(
                    Validators.pattern(PasswordConfirmComponent.hasCorrectLengthRegex),
                    Validators.pattern(PasswordConfirmComponent.hasNumberRegex),
                    Validators.pattern(PasswordConfirmComponent.hasLowerLetterRegex),
                    Validators.pattern(PasswordConfirmComponent.hasUpperLetterRegex),
                    Validators.pattern(PasswordConfirmComponent.hasSpecialCharRegex),
                );
            }

            // Both Password Fields
            validators.push(PasswordConfirmComponent.passwordMatchValidator());
        },
    }) as CustomFormControlValidatorEntry;
