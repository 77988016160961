import { type SubmissionValueState } from '../../models/form-submission/submission-value-state.model';
import { type AccessorFn } from '../drupal-webform-types';

export const VALUE_ACCESSOR_OPTIONS: AccessorFn = (v, settings) => {
    if ((v as SubmissionValueState | undefined)?.isDisabled) {
        return undefined;
    }

    const value = v as string;
    const options = settings?.options;
    if (!options) {
        return '';
    }

    const option = (Array.isArray(options) ? options.find(o => o.key === value)?.value : options[value]) ?? '';

    // We need to split at '--' to seperate the label from the help text
    return option.split('--')[0];
};
