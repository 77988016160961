@if (submissionPreviewNode.children?.length > 0 && submissionPreviewNode.label) {
    <h4>{{ submissionPreviewNode.label }}</h4>
}

<div>
    @for (child of submissionPreviewNode.children; track $index) {
        @if (!child.children) {
            <big-form-preview-element [submissionPreviewNode]="child" />
        } @else {
            <big-form-preview-group [submissionPreviewNode]="child" />
        }
    }
</div>
