@if (faqNodeId) {
    @if (!accordion()) {
        <big-ui-spinner spinnerClass="inline-flex items-center justify-center py-12">Lade häufig gestellte Fragen...</big-ui-spinner>
    } @else {
        @if (accordion().title) {
            <h2 class="text-center text-break">
                {{ accordion().title }}
            </h2>
        }
        <big-ui-accordion>
            @for (item of accordion().items; track index; let index = $index) {
                <big-ui-accordion-item
                    [index]="index"
                    [title]="item.title">
                    <big-paragraphs-holder [data]="item.paragraphs" />
                </big-ui-accordion-item>
            }
        </big-ui-accordion>
    }
}
