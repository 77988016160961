import { type Type } from '@angular/core';
import { type ValidatorFn } from '@angular/forms';
import { type AllowedDrupalType } from '@big-direkt/form/contracts';
import { type BaseComponent } from '../../base-components/base/base.component';
import { type LabelComponent } from '../../structures/components/label/label.component';

export interface ExtendedMapConfig {
    component: Type<BaseComponent>;
    validators?: ValidatorFn[];
}

export type ComponentMapConfig = ExtendedMapConfig | Type<BaseComponent | LabelComponent>;
export type ComponentMap = Partial<Record<AllowedDrupalType, ComponentMapConfig>>;

/**
 * @param config the ComponentMapConfig
 * @returns if config is of type ExtendedMapConfig
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function isExtendedMapConfig(config?: ComponentMapConfig): config is ExtendedMapConfig {
    return !!(config as ExtendedMapConfig | undefined)?.component;
}
