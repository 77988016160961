/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ComponentMap } from '../../utilities/component-map/component-map';

export class RegisterComponentService {
    public componentMap: Partial<ComponentMap> = {};

    public registerComponents(components: any[]): void {
        components.forEach((component: any): void => {
            this.registerComponent(component);
        });
    }

    public registerComponent(component: any): void {
        // eslint-disable-next-line no-prototype-builtins
        if (component.hasOwnProperty('register')) {
            this.componentMap = {
                ...this.componentMap,
                ...component.register(),
            };
        }
    }
}
