import { A11yModule } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, SecurityContext, inject, type OnInit } from '@angular/core';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { UiLinkModule } from '@big-direkt/ui/link';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumSchliessen, IconComponent } from '@big-direkt/utils/icons';
import { SanitizePipe, type Link } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgPopoverRef } from 'ng-overlay-container';
import { type InformationOverlayData } from '../../models/information-overlay-data.model';

@Component({
    selector: 'big-ui-modal-information',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    templateUrl: './modal-information.component.html',
    imports: [A11yModule, IconComponent, TranslocoDirective, UiButtonComponent, SanitizePipe, UiLinkModule],
    providers: [provideTranslationScope('uiModalInfo', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
})
export class ModalInformationComponent implements OnInit {
    public readonly popoverRef = inject<NgPopoverRef<InformationOverlayData>>(NgPopoverRef);
    public readonly iconClose = IconBigMediumSchliessen;

    public readonly securityContext = SecurityContext.HTML;

    public title?: string;
    public link?: Link;
    public description!: string;
    public onLinkClick: () => void = () => void 0;

    public ngOnInit(): void {
        const { title, description, link, onLinkClick } = this.popoverRef.data;
        this.title = title;
        this.link = link;
        this.description = description;
        if (onLinkClick) {
            this.onLinkClick = onLinkClick;
        }
    }
}
