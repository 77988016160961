/* eslint-disable no-null/no-null */
import { type AbstractControl, type ValidationErrors, type ValidatorFn } from '@angular/forms';
import { type BigUntypedFormControl } from '../../form-control/big-form-control';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class DatetimeValidator {
    public static requiredIfOneFieldIsFilled(fieldName: string): ValidatorFn {
        // eslint-disable-next-line sonarjs/no-invariant-returns
        return (control: AbstractControl): ValidationErrors | null => {
            const dateControl = control.get(`${fieldName}[date]`) as BigUntypedFormControl | undefined;
            const timeControl = control.get(`${fieldName}[time]`) as BigUntypedFormControl | undefined;

            if (!dateControl || !timeControl) {
                return null;
            }

            if (!dateControl.value && !timeControl.value) {
                dateControl.setErrors(null);
                timeControl.setErrors(null);

                return null;
            }

            if (dateControl.value && !timeControl.value) {
                timeControl.setErrors({
                    required: true,
                });

                return null;
            }

            if (!dateControl.value && timeControl.value) {
                dateControl.setErrors({
                    required: true,
                });

                return null;
            }

            return null;
        };
    }
}
