import { HttpStatusCode, type HttpErrorResponse } from '@angular/common/http';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EventBusService } from '@big-direkt/event-bus';
import { type ApiErrorItem } from '../../interfaces/api-error';
import { StringReplaceService } from '../string-replace/string-replace.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorMessageService {
    private readonly eventBus = inject(EventBusService);
    private readonly stringReplaceService = inject(StringReplaceService);
    private readonly destroyRef = inject(DestroyRef);

    public messages: string[] = [];

    public constructor() {
        this.eventBus.on('form_unloaded').pipe(
            takeUntilDestroyed(this.destroyRef),
        ).subscribe(() => {
            this.reset();
        })
    }

    public process(response: HttpErrorResponse): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        if (response.status === HttpStatusCode.UnprocessableEntity) {

            this.messages = response.error.errors.map((error: ApiErrorItem): string => error.detail);

            return;
        }

        if (response.status === 0) {
            this.messages = [this.stringReplaceService.get('general.errors.serverUnreachable')];

            return;
        }

        this.messages = [this.stringReplaceService.get('general.errors.serverInternal')];
    }

    public reset(): void {
        if (this.messages.length > 0) {
            this.messages = [];
        }
    }
}
