import { KeyValuePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, inject } from '@angular/core';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { TranslocoDirective } from '@jsverse/transloco';
import { type BigUntypedFormGroup } from '../form-control/big-form-group';
import { FormService } from '../services/form/form.service';

@Component({
    standalone: true,
    selector: 'big-form-state-preview',
    templateUrl: './state-preview.component.html',
    imports: [NgClass, KeyValuePipe, NgTemplateOutlet, TranslocoDirective],
    providers: [provideTranslationScope('ftbStatePreview', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
})
export class StatePreviewComponent {
    private readonly formService = inject(FormService);
    public showFormState = false;

    public get control(): BigUntypedFormGroup {
        return this.formService.currentForm;
    }
}
