import { Directive, Inject, Input, ViewContainerRef } from '@angular/core';

@Directive({
    // TODO: Rename accordingly and refactor all occurences
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[appElementHost]',
})
export class ElementHostDirective {
    @Input() public id?: string;

    public constructor(@Inject(ViewContainerRef) public viewContainerRef: ViewContainerRef) { }
}
