<ng-template appElementHost />
@if (settings.multipleAddMore || settings.multipleOperations) {
    @if (control?.enabled) {
        @if (isLast && settings.multipleAddMore) {
            <button
                (click)="doAdd()"
                type="button"
                class="mr-2 btn btn-primary">
                {{ settings.multipleAddMoreButtonLabel }}
            </button>
        }
        @if (isLast && !isFirst && settings.multipleOperations) {
            <button
                (click)="doDelete()"
                type="button"
                class="btn btn-primary">
                {{ 'components.multiple.removeItem' | stringReplacePipe }}
            </button>
        }
    }
}
