import {
    DRUPAL_WEBFORM_TYPE_CONFIG,
    type FormConfigElement,
    type PageHierarchyNode,
    type PageLocationContract,
    type PreviewValue,
} from '@big-direkt/form/contracts';
import { type FormElementValueServiceBase } from '../../contracts/form-element-value.service.base';
import { PageVisibleService } from '../page-visible/page-visible.service';
import { type TokenRefService } from './../token-ref/token-ref.service';

export class PreviewBuilderService {
    private readonly pageVisibleService: PageVisibleService;

    public constructor(
        pageLocationService: PageLocationContract,
        private readonly valueService: FormElementValueServiceBase,
        private readonly tokenRefService: TokenRefService,
    ) {
        this.pageVisibleService = new PageVisibleService(pageLocationService);
    }

    public buildFullPreview(pages: PageHierarchyNode[]): PreviewValue[] {
        const visiblePages = this.pageVisibleService.getVisiblePages(pages, true);
        const result: PreviewValue[] = visiblePages.map(
            (topLevelPage: PageHierarchyNode): PreviewValue => ({
                label: this.tokenRefService.findReplacement(topLevelPage.previewLabel, topLevelPage.settings.arrayParents),
                children: this.pageVisibleService
                    .getVisiblePages(topLevelPage.descendantsAndSelf, false)
                    .map((page: PageHierarchyNode): PreviewValue => this.buildSinglePage(page, page !== topLevelPage))
                    .filter(child => child.value !== undefined || child.label !== undefined || child.children?.length),
            }),
        );

        // Include the preview page, because it may contain input elements
        const previewPage: PageHierarchyNode | undefined = pages.find((page: PageHierarchyNode): boolean => page.isPreview);

        if (!previewPage) {
            return result;
        }

        const node: PreviewValue = this.buildSinglePage(previewPage, false);

        // eslint-disable-next-line
        if (node.value || (node.children ?? []).length > 0) {
            result.push(node);
        }

        return result;
    }

    public buildSinglePage(page: PageHierarchyNode, withLabel = true): PreviewValue {
        return {
            label: withLabel ? this.tokenRefService.findReplacement(page.previewLabel, page.settings.arrayParents) : undefined,
            children: this.fromSettingsArray(page.settings.children),
        };
    }

    private fromSettingsArray(elements: FormConfigElement[]): PreviewValue[] {
        const result: PreviewValue[] = [];

        elements.forEach((element: FormConfigElement): void => {
            if (element.showMarkupOn === 'all' || element.showMarkupOn === 'preview') {
                result.push(this.fromMarkupElement(element));

                return;
            }

            // Exclude pages and disabled elements
            if (element.isPageElement || this.valueService.isDisabled(element)) {
                return;
            }

            // Create node
            if (DRUPAL_WEBFORM_TYPE_CONFIG[element.type].isInput) {
                const previewValue = this.fromInputElement(element);
                if (!(previewValue.label === '' && previewValue.value === undefined)) {
                    result.push(previewValue);
                }

                return;
            }

            const node: PreviewValue = this.fromElementSettings(element);

            // Exclude empty nodes (the label for input element is always defined)
            if (node.label === undefined && (node.children ?? []).length === 0) {
                return;
            }

            // Flatten children if necessary
            if (node.label === undefined && node.children) {
                result.push(...node.children);

                return;
            }

            result.push(node);
        });

        return result;
    }

    private fromElementSettings(settings: FormConfigElement): PreviewValue {
        const label: string | undefined =
            DRUPAL_WEBFORM_TYPE_CONFIG[settings.type].isPreviewGroupItem && settings.hideTitleOnPreview !== true
                ? this.tokenRefService.findReplacement(settings.title, settings.arrayParents)
                : undefined;

        return {
            label: label === '' ? undefined : label,
            children: this.fromSettingsArray(settings.children),
        };
    }

    private fromInputElement(input: FormConfigElement): PreviewValue {
        return {
            label: input.valueAsLabelInPdf
                ? this.valueService.getFormattedPreviewValue(input)?.toString()
                : this.tokenRefService.findReplacement(input.title, input.arrayParents),
            value: input.valueAsLabelInPdf ? undefined : this.valueService.getFormattedPreviewValue(input),
            linkValue: this.valueService.getLinkValue(input),
            pushValueLeft: input.valueAsLabelInPdf,
        };
    }

    private fromMarkupElement(element: FormConfigElement): PreviewValue {
        return {
            label: this.valueService.getFormattedPreviewValue(element)?.toString(),
            pushValueLeft: true,
        };
    }
}
