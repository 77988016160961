/* eslint-disable jsdoc/check-indentation */
import { InjectionToken } from '@angular/core';
import { type ValidatorFn } from '@angular/forms';
import { type FormConfigElement } from '@big-direkt/form/contracts';

export interface CustomFormControlValidatorEntry {
    type: string;
    handle: (settings: FormConfigElement, validators: ValidatorFn[]) => object;
}

// eslint-disable-next-line jsdoc/check-indentation
/**
 * InjectionToken to provide custom validatiors to the FormService like this:
 *
 * `{
 *      provide: CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN,
 *      useValue: {
 *          type: 'YOUR_COMPONENT_SETTING_TYPE',
 *          handle: (settings: FormConfigElement, validators: ValidatorFn[]) => {
 *              ...
 *          },
 *      } as CustomFormControlValidatorEntry,
 *      multi: true,
 *  },`
 */
export const CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN: InjectionToken<CustomFormControlValidatorEntry> = new InjectionToken<CustomFormControlValidatorEntry>(
    'CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN',
);
