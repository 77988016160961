import { Component } from '@angular/core';
import { TextfieldBaseComponent } from '../../../base-components/textfield-base/textfield-base.component';
import { type ErrorOverrideTemplate } from '../../../interfaces/error-override-template';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

const hinPatternTemplate: ErrorOverrideTemplate<{ hin: string }> = params => ({
    i18nKey: 'ftbForm.validation.hin.pattern',
    params: {
        hin: params.hin,
    },
})


@Component({
    selector: 'big-form-hin',
    templateUrl: './hin.component.html',
})
export class HinComponent extends TextfieldBaseComponent {
    public errorOverrides = {
        hinChecksum: hinPatternTemplate,
        hinPattern: hinPatternTemplate,
    };

    public static register(): ComponentMap {
        return {
            big_webform_hin: HinComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }
}
