import { Component } from '@angular/core';
import { InputBaseComponent } from '../../../base-components/input-base/input-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-radio',
    templateUrl: './radio.component.html',
})
// CAUTION! RadioComponent is never rendered! But the register step is necessary for settings to get mapped
export class RadioComponent extends InputBaseComponent {
    public static register(): ComponentMap {
        return {
            radio: RadioComponent,
        };
    }
}
