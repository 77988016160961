<div
    role="alert"
    class="[&>div>p]:last:mb-0 flex flex-col gap-2"
    [class]="classList"
    [ngClass]="{
        'bg-[#faf3f3] border-danger': type === 'danger',
        'bg-[#fffbf0] border-warning': type === 'warning',
        'bg-[#ebf4fa] border-info': type === 'info',
        'bg-[#f5f9f8] border-success': type === 'success'
    }">
    @if (title) {
        <h3
            class="mb-0"
            [ngClass]="{
            'text-danger': type === 'danger',
            'text-warning': type === 'warning',
            'text-info': type === 'info',
            'text-success': type === 'success'
        }">
            <big-icon
                [icon]="iconMap[type]"
                [ngClass]="'icon icon-' + type" />
            {{ title }}
        </h3>
    }
    <ng-content />
</div>
