/* eslint-disable @typescript-eslint/naming-convention */
import { type FormConfigElement } from '../models/form-config-element/form-config-element.model';
import { type PreviewDisplayValue } from '../models/form-preview/preview-display-value.model';
import { type PreviewValue } from '../models/form-preview/preview-value.model';
import { type SubmissionObject } from '../models/form-submission/submission-object.model';
import { type SubmissionValue } from '../models/form-submission/submission-value.model';
import { type UploadFile } from '../models/upload-file';
import { type AllowedDrupalType } from './allowed-drupal-type';
import { TYPE_DEF_ADDRESS } from './drupal-type-defs/value-accessor-address';
import { VALUE_ACCESSOR_CHECKBOX } from './drupal-type-defs/value-accessor-checkbox';
import { SUBMISSION_VALUE_ACCESSOR_CHECKBOXES, VALUE_ACCESSOR_CHECKBOXES } from './drupal-type-defs/value-accessor-checkboxes';
import { TYPE_DEF_COMPOSITE } from './drupal-type-defs/value-accessor-composite';
import { VALUE_ACCESSOR_CURRENCY } from './drupal-type-defs/value-accessor-currency';
import { SUBMISSION_VALUE_ACCESSOR_DATE, VALUE_ACCESSOR_DATE } from './drupal-type-defs/value-accessor-date';
import { SUBMISSION_VALUE_ACCESSOR_DATE_RANGE, VALUE_ACCESSOR_DATE_RANGE } from './drupal-type-defs/value-accessor-date-range';
import { SUBMISSION_VALUE_ACCESSOR_DATELIST, VALUE_ACCESSOR_DATELIST, VALUE_FORMATTER_DATELIST } from './drupal-type-defs/value-accessor-datelist';
import { TYPE_DEF_DATETIME } from './drupal-type-defs/value-accessor-datetime';
import { VALUE_ACCESSOR_FIELD_GROUP } from './drupal-type-defs/value-accessor-field-group';
import { TYPE_DEF_FILE_UPLOAD } from './drupal-type-defs/value-accessor-file-upload';
import { VALUE_ACCESSOR_ITEM } from './drupal-type-defs/value-accessor-item';
import { SUBMISSION_VALUE_ACCESSOR_SUBMITTING_MARKUP, VALUE_ACCESSOR_MARKUP } from './drupal-type-defs/value-accessor-markup';
import { SUBMISSION_MAPPER_MULTIPLE } from './drupal-type-defs/value-accessor-multiple';
import { SUBMISSION_VALUE_ACCESSOR_NUMBER, VALUE_ACCESSOR_NUMBER } from './drupal-type-defs/value-accessor-number';
import { VALUE_ACCESSOR_OPTIONS } from './drupal-type-defs/value-accessor-options';
import { VALUE_ACCESSOR_PASSWORD } from './drupal-type-defs/value-accessor-password';
import { SUBMISSION_VALUE_ACCESSOR_PASSWORD_CONFIRM } from './drupal-type-defs/value-accessor-password-confirm';
import { VALUE_ACCESSOR_PRIVACY_POLICY } from './drupal-type-defs/value-accessor-privacy-policy';
import { SUBMISSION_VALUE_ACCESSOR_REPEATED_PAGE } from './drupal-type-defs/value-accessor-repeated-page';
import { VALUE_ACCESSOR_SELECT } from './drupal-type-defs/value-accessor-select';
import { SUBMISSION_MAPPER_STRUCTURE, TYPE_DEF_STRUCTURE } from './drupal-type-defs/value-accessor-structure';
import { VALUE_ACCESSOR_TIME } from './drupal-type-defs/value-accessor-time';
import { SUBMISSION_VALUE_ACCESSOR_TIME_RANGE, VALUE_ACCESSOR_TIME_RANGE } from './drupal-type-defs/value-accessor-time-range';

export type AccessorFn = (value: SubmissionValue, settings?: FormConfigElement) => PreviewDisplayValue;
export type LinkAccessorFn = (value: SubmissionValue, settings?: FormConfigElement) => PreviewValue['linkValue'];
export type SubmissionValueAccessorFn = (
    value: SubmissionObject | SubmissionValue | UploadFile[],
    settings: FormConfigElement,
    parsedChildValues?: SubmissionObject | SubmissionValue,
) => SubmissionObject | undefined;

export interface TypeDef {
    isInput: boolean;
    isStructure?: boolean;
    isPreviewGroupItem?: boolean;
    preventChildProcessing?: boolean;
    requiredValidatorKey?: 'required-true' | 'required';
    previewAccessor?: AccessorFn;
    linkAccessor?: LinkAccessorFn;
    submissionValueAccessor?: SubmissionValueAccessorFn;
    submissionMapper?: (data: SubmissionObject, propName: string, arrayParents: string[]) => void;
    valueFormatter?: (value: SubmissionObject, settings: FormConfigElement) => string;
}

export const DRUPAL_WEBFORM_TYPE_CONFIG: Record<AllowedDrupalType, TypeDef> = {
    big_webform_address: TYPE_DEF_ADDRESS,
    big_webform_alert: { isInput: false },
    big_webform_bank_data: TYPE_DEF_COMPOSITE,
    big_webform_bic: { isInput: true },
    big_webform_contact_data: TYPE_DEF_COMPOSITE,
    big_webform_contact_form_search: { isInput: true },
    big_webform_currency: { isInput: true, previewAccessor: VALUE_ACCESSOR_CURRENCY },
    big_webform_date_range: {
        isInput: true,
        preventChildProcessing: true,
        previewAccessor: VALUE_ACCESSOR_DATE_RANGE,
        submissionValueAccessor: SUBMISSION_VALUE_ACCESSOR_DATE_RANGE,
    },
    big_webform_dsgvo_element: { isInput: false, previewAccessor: VALUE_ACCESSOR_MARKUP },
    big_webform_embedded_form: { isInput: false, isStructure: true },
    // eslint-disable-next-line max-len
    big_webform_excludable_checkboxes: {
        isInput: true,
        isStructure: true,
        preventChildProcessing: true,
        previewAccessor: VALUE_ACCESSOR_CHECKBOXES,
        submissionValueAccessor: SUBMISSION_VALUE_ACCESSOR_CHECKBOXES,
    },
    big_webform_field_group: { isInput: true, isStructure: true, previewAccessor: VALUE_ACCESSOR_FIELD_GROUP, submissionMapper: SUBMISSION_MAPPER_STRUCTURE },
    big_webform_form_element_ref: { isInput: false },
    big_webform_hin: { isInput: true },
    big_webform_iban: { isInput: true },
    big_webform_iframe: { isInput: false, preventChildProcessing: true },
    big_webform_mobile_app_info: { isInput: true },
    big_webform_multiple: { isInput: false, isStructure: true, preventChildProcessing: true, submissionMapper: SUBMISSION_MAPPER_MULTIPLE },
    big_webform_navigation_button: { isInput: false },
    big_webform_page_repeating: { isInput: false, isStructure: true },
    big_webform_page: TYPE_DEF_STRUCTURE,
    big_webform_preview: { isInput: false },
    big_webform_privacy_policy: { isInput: true, requiredValidatorKey: 'required-true', previewAccessor: VALUE_ACCESSOR_PRIVACY_POLICY },
    big_webform_progress_bar: { isInput: false },
    big_webform_quantity_select: { isInput: true },
    big_webform_repeated_page: { ...TYPE_DEF_STRUCTURE, submissionValueAccessor: SUBMISSION_VALUE_ACCESSOR_REPEATED_PAGE },
    big_webform_searchable_select: { isInput: true },
    big_webform_select: { isInput: true, previewAccessor: VALUE_ACCESSOR_SELECT },
    big_webform_submitting_markup: {
        isInput: false,
        previewAccessor: VALUE_ACCESSOR_MARKUP,
        submissionValueAccessor: SUBMISSION_VALUE_ACCESSOR_SUBMITTING_MARKUP,
    },
    big_webform_tan: { isInput: true },
    big_webform_telephone: { isInput: true },
    big_webform_time_range: {
        isInput: true,
        isStructure: true,
        preventChildProcessing: true,
        previewAccessor: VALUE_ACCESSOR_TIME_RANGE,
        submissionValueAccessor: SUBMISSION_VALUE_ACCESSOR_TIME_RANGE,
    }, // missing in mockoon
    big_webform_transactional_file: TYPE_DEF_FILE_UPLOAD,
    checkbox: { isInput: true, requiredValidatorKey: 'required-true', previewAccessor: VALUE_ACCESSOR_CHECKBOX },
    checkboxes: {
        isInput: true,
        preventChildProcessing: true,
        isStructure: true,
        previewAccessor: VALUE_ACCESSOR_CHECKBOXES,
        submissionValueAccessor: SUBMISSION_VALUE_ACCESSOR_CHECKBOXES,
    },
    container: TYPE_DEF_STRUCTURE,
    date: { isInput: true, preventChildProcessing: true, previewAccessor: VALUE_ACCESSOR_DATE, submissionValueAccessor: SUBMISSION_VALUE_ACCESSOR_DATE },
    datelist: {
        isInput: true,
        isStructure: true,
        preventChildProcessing: true,
        previewAccessor: VALUE_ACCESSOR_DATELIST,
        submissionValueAccessor: SUBMISSION_VALUE_ACCESSOR_DATELIST,
        valueFormatter: VALUE_FORMATTER_DATELIST,
    },
    datetime: TYPE_DEF_DATETIME,
    email: { isInput: true },
    fieldset: { ...TYPE_DEF_STRUCTURE, isPreviewGroupItem: true },
    file: { isInput: true }, // not found in any webform in CMS, no component registered
    form: TYPE_DEF_STRUCTURE,
    hidden: { isInput: false },
    item: { isInput: false, previewAccessor: VALUE_ACCESSOR_ITEM }, // not found in any webform in CMS
    label: { isInput: false },
    managed_file: TYPE_DEF_FILE_UPLOAD,
    number: { isInput: true, previewAccessor: VALUE_ACCESSOR_NUMBER, submissionValueAccessor: SUBMISSION_VALUE_ACCESSOR_NUMBER },
    password_confirm: { isInput: false, isStructure: true, submissionValueAccessor: SUBMISSION_VALUE_ACCESSOR_PASSWORD_CONFIRM },
    password: { isInput: true, previewAccessor: VALUE_ACCESSOR_PASSWORD },
    radio: { isInput: true }, // not found in any webform in CMS
    radios: { isInput: true, preventChildProcessing: true, previewAccessor: VALUE_ACCESSOR_OPTIONS },
    select: { isInput: true, previewAccessor: VALUE_ACCESSOR_SELECT },
    submit: { isInput: true }, // no component registered
    tel: { isInput: true },
    textarea: { isInput: true },
    textfield: { isInput: true },
    url: { isInput: true },
    webform_address: TYPE_DEF_ADDRESS,
    webform_audio_file: TYPE_DEF_FILE_UPLOAD,
    webform_autocomplete: { isInput: true },
    webform_contact: TYPE_DEF_STRUCTURE,
    webform_current_bank_data_element: { isInput: false, isStructure: true, isPreviewGroupItem: true }, // not found in any webform in CMS
    webform_custom_composite: TYPE_DEF_STRUCTURE, // not found in any webform in CMS
    webform_document_file: TYPE_DEF_FILE_UPLOAD,
    webform_entity_checkboxes: TYPE_DEF_STRUCTURE,
    webform_entity_radios: TYPE_DEF_STRUCTURE,
    webform_entity_select: TYPE_DEF_STRUCTURE, // missing in mockoon
    webform_image_file: TYPE_DEF_FILE_UPLOAD,
    webform_link: TYPE_DEF_STRUCTURE,
    webform_markup: { isInput: false, previewAccessor: VALUE_ACCESSOR_MARKUP },
    webform_multiple_item: { isInput: false, isStructure: true }, // no component registered
    webform_pdf_download: { isInput: false },
    webform_personal_data_element: { isInput: false, isStructure: true, isPreviewGroupItem: true },
    webform_section: TYPE_DEF_STRUCTURE,
    webform_time: { isInput: true, previewAccessor: VALUE_ACCESSOR_TIME },
    webform_video_file: TYPE_DEF_FILE_UPLOAD,
};

export const DRUPAL_WEBFORM_TYPES = Object.keys(DRUPAL_WEBFORM_TYPE_CONFIG);
