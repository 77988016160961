import { type PageHierarchyNode, type PageLocationContract } from '@big-direkt/form/contracts';

export class PageVisibleService {
    public constructor(private readonly pageLocationService: PageLocationContract) {}

    public getVisiblePages(pages: PageHierarchyNode[], includeDescendants = false): PageHierarchyNode[] {
        return pages.filter(page => this.isPageVisibleRecursive(page, includeDescendants));
    }

    private isPageVisibleRecursive(page: PageHierarchyNode, includeDescendants: boolean): boolean {
        if (!page.isAccessible) {
            return false;
        }

        if (includeDescendants) {
            return page.descendantsAndSelf.some(node => this.isPageVisible(node));
        }

        return this.isPageVisible(page);
    }

    private isPageVisible(page: PageHierarchyNode): boolean {
        return (page.hasInputs && page.isAccessible && this.pageLocationService.isBeforeCurrentPage(page)) || (page.hasVisibleMarkup && !page.hasInputs);
    }
}
