import { ChangeDetectorRef, Component, QueryList, ViewChildren, inject, type OnInit } from '@angular/core';
import { FormConfigElement, type AllowedDrupalType } from '@big-direkt/form/contracts';
import { type BaseComponent } from '../../../base-components/base/base.component';
import { InputBaseComponent } from '../../../base-components/input-base/input-base.component';
import { ElementHostDirective } from '../../../directives/element-host/element-host.directive';
import { type BigUntypedFormGroup } from '../../../form-control/big-form-group';
import { ComponentService } from '../../../services/component/component.service';
import { type ComponentMap } from '../../../utilities/component-map/component-map';
import { SelectComponent } from '../select/select.component';
import { TextfieldComponent } from '../textfield/textfield.component';

@Component({
    selector: 'big-form-datelist',
    templateUrl: './datelist.component.html',
})
export class DatelistComponent extends InputBaseComponent implements OnInit {
    public readonly changeDetector = inject(ChangeDetectorRef);
    public readonly componentService = inject(ComponentService);

    @ViewChildren(ElementHostDirective) private readonly childContainers!: QueryList<ElementHostDirective>;

    public declare control?: BigUntypedFormGroup;

    public children: BaseComponent[] = [];

    public constructor() {
        super();
    }

    public static register(): ComponentMap {
        return {
            datelist: DatelistComponent,
        };
    }

    public override init(): void {
        super.init();
        this.componentService.registerForManualChildHandling(Object.keys(DatelistComponent.register()) as AllowedDrupalType[]);

        this.settings.children.forEach((childSettings: FormConfigElement): void => {
            this.formService.createControls(childSettings);
            const { instance: component } = this.componentService.createComponent<BaseComponent | SelectComponent | TextfieldComponent>(childSettings);

            if (component instanceof SelectComponent || component instanceof TextfieldComponent) {
                component.minimal = true;
                component.classList = 'block [&:not(:last-child)]:mb-3';
            }
        });
    }

    public ngOnInit(): void {
        // TODO: Refactor component creation so that this ChangeDetector is not needed (bad practice).
        this.changeDetector.detectChanges();

        this.settings.children.forEach((childSettings: FormConfigElement): void => {
            const childRef: ElementHostDirective | undefined = this.childContainers.find(({ id }: ElementHostDirective): boolean => id === childSettings.name);

            this.componentService.insertSingleViewChild(childSettings, childRef?.viewContainerRef);
        });

        this.changeDetector.markForCheck();
    }
}
