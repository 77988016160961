/* eslint-disable @angular-eslint/template/cyclomatic-complexity, @angular-eslint/template/no-call-expression */
import { Component, HostBinding, Input } from '@angular/core';
import { UiChartCircularProgressBarComponent } from '@big-direkt/ui/chart';
import { WizardBaseComponent } from '../../../base-components/wizard-base/wizard-base.component';
import { ReplaceTokenRefPipe } from '../../../pipes/replace-token-ref/replace-token-ref.pipe';

@Component({
    selector: 'big-form-mobile-wizard',
    templateUrl: './mobile-wizard.component.html',
    imports: [ReplaceTokenRefPipe, WizardBaseComponent, UiChartCircularProgressBarComponent],
    standalone: true,
})
export class MobileWizardComponent extends WizardBaseComponent {
    @HostBinding('class') @Input() public override classList = 'block md:hidden';

    public get nodeIndex(): number {
        let page = this.currentPage?.level === 1 ? this.currentPage : this.currentPage?.parent;
        while (page && (!page.hasWizardEntry || !page.isAccessible)) {
            page = page.previousAccessibleLeaf;
        }

        return page ? this.pageNavigationService.wizardPages.indexOf(page) + 1 : 0;
    }

    public get mobileWizardLabel(): string | undefined {
        if (!this.currentPage?.parent) {
            return undefined;
        }

        if (this.currentPage.level === 1) {
            return this.currentPage.wizardLabel ?? '';
        }

        const visibleChildren = this.currentPage.parent.children.filter(x => x.isAccessible);

        return `${this.nodeIndex}.${visibleChildren.indexOf(this.currentPage) + 1} ${this.currentPage.wizardLabel ?? ''}`;
    }
}
