<big-ui-form-row
    [showInfo]="!!settings.help"
    [infoTextTemplate]="help"
    [id]="settings.id"
    class="mb-2"
    [label]="label"
    [errors]="control?.errors"
    [showError]="isInvalid"
    [isRequired]="control?.isRequired"
    [errorOverrides]="errorOverrides"
    (infoLayerOpened)="trackFormsInfoIconEvent($event)">
    <big-ui-autocomplete-input
        [name]="settings.id"
        [id]="settings.id"
        [formControl]="control"
        [options]="searchableOptions"
        [minCharacters]="0"
        [requireSelection]="true"
        [isValid]="control?.touched && isValid"
        [isInvalid]="control?.touched && isInvalid"
        [isRequired]="control?.isRequired"
        [optionsValueTransformer]="transformOptionValue"
        (inputChanges)="onAutocompleteInputChange($event)" />
</big-ui-form-row>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
