import { type BigAnyFormControl } from '../../form-control/big-any-form-control';
import { BigUntypedFormGroup } from '../../form-control/big-form-group';

export const markControlAsTouched = (control: BigAnyFormControl | undefined): void => {
    if (!control || control.disabled) {
        return;
    }

    if (control instanceof BigUntypedFormGroup) {
        for (const key of Object.keys(control.controls)) {
            const child = control.controls[key];
            markControlAsTouched(child);
        }
    }

    control.markAsTouched();
};

export const markControlAsDirty = (control: BigAnyFormControl | undefined): void => {
    if (!control || control.disabled) {
        return;
    }

    if (control instanceof BigUntypedFormGroup) {
        for (const key of Object.keys(control.controls)) {
            const child = control.controls[key];
            markControlAsDirty(child);
        }
    }

    control.markAsDirty();
};
