<label
    [attr.for]="for"
    [attr.disabled]="isDisabled ? true : null"
    [attr.aria-disabled]="isDisabled"
    class="block break-words aria-disabled:text-gray-200 sm:break-normal"
    *transloco="let t; prefix: 'uiLabel'">
    <span
        class="[&>p]:inline"
        [innerHTML]="(labelHtml ?? label) + (isRequired ? ' ' + t('required') : '')"></span>
</label>
