/* eslint-disable no-null/no-null */
import { type AbstractControl, type ValidationErrors, type ValidatorFn } from '@angular/forms';
import { type FormConfigElement } from '@big-direkt/form/contracts';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class CustomPatternValidator {
    public static validatePattern(settings: FormConfigElement): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!settings.attributes?.pattern || control.value === '') {
                return null;
            }

            if (!new RegExp(settings.attributes.pattern).test(control.value)) {
                if (settings.attributes.patternError) {
                    return {
                        customPatternMessage: settings.attributes.patternError,
                    }
                }

                return {
                    customPattern: true,
                };
            }

            return null;
        };
    }
}
