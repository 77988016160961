import { FormConfigElement } from '@big-direkt/form/contracts';
import { type BigUntypedFormGroup } from '../../form-control/big-form-group';
import { type ConditionalFieldsService } from '../conditional-fields/conditional-fields.service';
import { type FormService } from '../form/form.service';
import { type MultipleGroupService } from '../multiple-group/multiple-group.service';
import { type TokenService } from '../token/token.service';
import { type MultiplesService } from './multiples.service';

export class MultipleHandler {
    public childrenTemplates: FormConfigElement[] = [];

    public constructor(
        private readonly settings: FormConfigElement,
        private readonly multiplesService: MultiplesService,
        private readonly tokenService: TokenService,
        private readonly formService: FormService,
        private readonly conditionalFieldsService: ConditionalFieldsService,
        private readonly multiGroupService: MultipleGroupService,
    ) {
        this.childrenTemplates = settings.children;
        this.childrenTemplates.forEach(child => {
            this.removeParentNodes(child);
        });

        this.settings.children = [];

        this.initializeItems();
    }

    public deleteItem(item: FormConfigElement): void {
        const index = this.settings.children.indexOf(item);
        this.settings.children.splice(index, 1);

        const parentControl = this.formService.getFormControl(this.settings.arrayParents) as BigUntypedFormGroup | undefined;
        parentControl?.removeControl(item.name);
        this.multiGroupService.handleGroupItemRemoved(this.settings.name, index);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createItem(defaultValue?: any): FormConfigElement {
        const multipleItemSettings = new FormConfigElement({
            webform: this.settings.webform,
            type: 'webform_multiple_item',
            states: this.settings.states,
            multipleOperations: this.settings.multipleOperations,
            multipleAddMore: this.settings.multipleAddMore,
            multipleAddMoreButtonLabel: this.settings.multipleAddMoreButtonLabel,
            parent: this.settings,
        });
        const itemsCount = this.settings.children.push(multipleItemSettings);
        multipleItemSettings.name = (itemsCount - 1).toString();
        multipleItemSettings.arrayParents = this.settings.arrayParents.concat(multipleItemSettings.name);
        multipleItemSettings.id = `${this.settings.id}-${multipleItemSettings.name}`;

        this.childrenTemplates.forEach(child => {
            // eslint-disable-next-line no-param-reassign
            const newChild = child.clone();

            this.tokenService.replaceTokenRefs(newChild, itemsCount - 1);

            if (defaultValue?.[child.name]) {
                newChild.defaultValue = defaultValue[child.name];
            }

            multipleItemSettings.children.push(newChild);
        });

        this.adjustArrayParents(multipleItemSettings);
        this.createParentNodes(multipleItemSettings);

        this.formService.createControls(multipleItemSettings);
        this.multiplesService.registerMultiples(multipleItemSettings);
        this.conditionalFieldsService.createStateHandlers(multipleItemSettings);

        this.multiGroupService.handleGroupItemAdded(this.settings.name, itemsCount - 1);

        return multipleItemSettings;
    }

    private adjustArrayParents(settings: FormConfigElement): void {
        settings.children.forEach(child => {
            child.arrayParents = settings.arrayParents.concat(child.name);
            child.id = child.arrayParents.join('-');
            this.adjustArrayParents(child);
        });
    }

    private createParentNodes(element: FormConfigElement): void {
        element.children.forEach(child => {
            child.parent = element;
            this.createParentNodes(child);
        });
    }

    private initializeItems(): void {
        if (!this.settings.defaultValue) {
            this.createItem();

            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.settings.defaultValue.forEach((value: any): void => {
            this.createItem(value);
        });
    }

    private removeParentNodes(element: FormConfigElement): void {
        delete element.parent;
        element.children.forEach(child => {
            this.removeParentNodes(child);
        });
    }
}
