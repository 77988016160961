import { formatDate } from '@angular/common';
import { Injectable, LOCALE_ID, inject } from '@angular/core';
import { FormConfigElement } from '@big-direkt/form/contracts';
import { type FormFieldUpdate } from '../../interfaces/form-field-update';
import { FormStateService } from '../form-state/form-state.service';
import { FormService } from '../form/form.service';

const DATE_FORMAT = 'dd.MM.yyyy';
@Injectable({
    providedIn: 'root',
})
export class FormUpdateService {
    private readonly locale = inject(LOCALE_ID);
    private readonly formService = inject(FormService);
    private readonly formStateService = inject(FormStateService);

    public updateFormFields(fields: FormFieldUpdate[]): void {
        fields.forEach((field: FormFieldUpdate): void => {
            const element: FormConfigElement | undefined = this.findElementByParents(field.arrayParents);

            if (!element) {
                return;
            }

            if (field.options) {
                element.options = Array.isArray(field.options) ? field.options : Object.entries(field.options).map(([key, value]) => ({ key, value }));

                const allowedValues = element.options.map(x => x.key);
                if (field.value && !allowedValues.includes(field.value as string)) {
                    delete field.value;
                }

                const emptyOptionIndex = element.options.findIndex(item => item.key === '');
                if (emptyOptionIndex >= 0) {
                    [element.emptyOption] = element.options.splice(emptyOptionIndex, 1);
                }
            }

            if (field.value !== undefined) {
                // TODO get rid of this special case handling for date
                // => FormFieldUpdate and formControl in date have different format.
                // => should ui-date-component map values to JSON format?
                const value = element.type === 'date' ? formatDate(field.value as string, DATE_FORMAT, this.locale).toString() : field.value;
                this.formService.getFormControl(element.arrayParents)?.setValue(value);
            }

            if (field.markup !== undefined) {
                element.markup = field.markup;
            }
        });
    }

    private findElementByParents(arrayParents: string[]): FormConfigElement | undefined {
        let element: FormConfigElement | undefined = this.formStateService.getForm();

        arrayParents.forEach((parent: string): void => {
            element = element?.children.find((child: FormConfigElement): boolean => child.name === parent);
        });

        return element;
    }
}
