import { Component } from '@angular/core';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { StructureBaseComponent } from '../../../base-components/structure-base/structure-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-field-group',
    templateUrl: './field-group.component.html',
    providers: [provideTranslationScope('ftbFieldGroup', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
})
export class FieldGroupComponent extends StructureBaseComponent {
    public readonly errorOverrides = { required: 'ftbFieldGroup.required' };

    public static register(): ComponentMap {
        return {
            big_webform_field_group: FieldGroupComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }
}
