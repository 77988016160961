import { Component, type OnInit } from '@angular/core';
import { TextfieldBaseComponent } from '../../../base-components/textfield-base/textfield-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-one-time-token',
    templateUrl: './one-time-token.component.html',
})
export class OneTimeTokenComponent extends TextfieldBaseComponent implements OnInit {
    public numberFields!: number;

    public static register(): ComponentMap {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            big_webform_tan: OneTimeTokenComponent,
        };
    }

    public ngOnInit(): void {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        this.numberFields = this.settings.maxlength ?? 5;

        // TODO: Disabled handling
    }
}
