import { inject, Injectable } from '@angular/core';
import { JsonApiClientService, ResourceTypes } from '@big-direkt/json-api-client';
import { EMPTY, map, throwError, type Observable } from 'rxjs';
import { type ErrorNodeModel } from '../models/error-node-model';

@Injectable({
    providedIn: 'root',
})
export class ErrorNodeLoaderService {
    private readonly jsonApiClient = inject(JsonApiClientService);

    public messages: string[] = [];

    public get(errorCode: number, type = 'general'): Observable<ErrorNodeModel> {
        if (errorCode === 0) {
            return EMPTY;
        }

        return this.jsonApiClient
            .find(ResourceTypes.NodeError, {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                http_statuscode: String(errorCode),
                // eslint-disable-next-line @typescript-eslint/naming-convention
                error_type: type,
                langcode: 'de',
            })
            .pipe(
                map((res: unknown[]): ErrorNodeModel => {
                    if (res.length === 0) {
                        throwError(() => new Error('empty api response'));
                    }

                    return res.at(0) as ErrorNodeModel;
                }),
            );
    }
}
