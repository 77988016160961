<big-ui-form-row
    [id]="settings.id"
    [label]="label"
    [isRequired]="control?.isRequired"
    [errors]="control?.errors"
    [showError]="isInvalid"
    [showInfo]="!!settings.help"
    [infoTextTemplate]="help"
    (infoLayerOpened)="trackFormsInfoIconEvent($event)">
    <!-- We manually set the ng-invalid class because theyre usually set by the formControl depending on its validators
    the "model" we bind to has no validators. We could also set [required]="settings.required", that however only checks if
    the input has any character, and therefore sets ng-valid while nothing was selected.
    We only set the ng-invalid class because the scroll/focus is dependent on it -->
    <div class="relative">
        <input
            class="form-control"
            [id]="settings.id"
            type="text"
            autocomplete="off"
            [appDrupalWebformElementAttributes]="settings"
            [inputFormatter]="formatter"
            [ngClass]="{
                'is-valid': isValid && !isSearching,
                'is-invalid': isInvalid && !isSearching,
                'ng-invalid': isInvalid
            }"
            [ngbTypeahead]="search"
            [resultFormatter]="formatter"
            (selectItem)="itemSelected($event)"
            (focusout)="updateControl()"
            [(ngModel)]="model"
            [formFieldTracking]="settings" />
        @if (isSearching) {
            <big-ui-spinner spinnerClass="absolute top-[50%] right-4 translate-y-[-50%]">
                <span class="visually-hidden">
                    {{ 'components.autocomplete.loading' | stringReplacePipe }}
                </span>
            </big-ui-spinner>
        }
    </div>
</big-ui-form-row>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
