import { type ValidatorFn } from '@angular/forms';
import { type FormConfigElement } from '@big-direkt/form/contracts';
import { type CustomFormControlValidatorEntry } from '../../interfaces/custom-form-control-validator.token';
import { CheckboxesValidator } from '../../utilities/checkboxes-validator/checkboxes-validator';

export const checkboxesValidatorFactory: () => CustomFormControlValidatorEntry = (): CustomFormControlValidatorEntry =>
    ({
        type: 'checkboxes',
        handle: (settings: FormConfigElement, validators: ValidatorFn[]): void => {
            if (typeof settings.multiple === 'number') {
                validators.push(CheckboxesValidator.validateMaxAllowed(settings.multiple));
            }
        },
    }) as CustomFormControlValidatorEntry;
