import { formatDate, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, EventEmitter, HostBinding, inject, input, LOCALE_ID, Output, signal, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule, MatDateRangeInput, MatDateRangePicker } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumKalender, IconComponent } from '@big-direkt/utils/icons';
import { BaseControlValueAccessor } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { dateRangeStringToObject } from '../date-range-string-to-object';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [FormsModule, IconComponent, MatFormFieldModule, MatDatepickerModule, NgxMaskDirective, ReactiveFormsModule, TranslocoDirective, NgClass],
    providers: [
        provideNativeDateAdapter(),
        provideNgxMask(),
        provideTranslationScope('uiDateRange', /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`)),
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DateRangeComponent,
            multi: true,
        },
    ],
    selector: 'big-ui-date-range',
    standalone: true,
    templateUrl: './date-range.component.html',
})
export class DateRangeComponent extends BaseControlValueAccessor<string> {
    private readonly local = inject(LOCALE_ID);
    public readonly dateRangePickerControl = new FormGroup({
        start: new FormControl(),
        end: new FormControl(),
    });
    public readonly formControlChanges = toSignal(this.formControl.valueChanges);
    public readonly hasFocus = signal(false);
    public readonly iconCalendar = IconBigMediumKalender;
    public readonly id = input.required<string>();
    public readonly isInvalid = input.required<boolean>();
    public readonly isRequired = input.required<boolean>();
    public readonly isValid = input.required<boolean>();
    public readonly maxDate = input<Date>(new Date());
    public readonly minDate = input<Date>(this.defaultMinDate());
    public readonly showMaskTyped = input<boolean>(false);
    public readonly showValidation = input<boolean>(false);

    @Output() public readonly errors = new EventEmitter<Record<string, unknown> | null>();

    @HostBinding('class') public classList = 'block';

    @ViewChild('dateRangePicker') public dateRangePicker!: MatDateRangePicker<Date>;
    @ViewChild('dateInput') public dateInput!: MatDateRangeInput<string>;

    public constructor() {
        super();

        effect(
            () => {
                const value = this.formControlChanges();

                this.dateRangePickerControl.setValue(dateRangeStringToObject(value));
                this.dateRangePickerControl.updateValueAndValidity();
                this.formControl.updateValueAndValidity();
                this.onChange(value);
                this.emitErrors();
            },
            { allowSignalWrites: true },
        );
    }

    public datePickerOpen(event: Event): void {
        event.preventDefault();

        this.dateRangePicker.open();
    }

    public datePickerClose(): void {
        const dateRangeValue = this.dateRangePickerControl.value;
        // eslint-disable-next-line no-null/no-null
        const startDate = dateRangeValue.start !== null ? formatDate(new Date(dateRangeValue.start), 'dd.MM.YYYY', this.local) : '';
        // eslint-disable-next-line no-null/no-null
        const endDate = dateRangeValue.end !== null ? formatDate(new Date(dateRangeValue.end), 'dd.MM.YYYY', this.local) : '';

        if (!startDate && !endDate) {
            // eslint-disable-next-line no-null/no-null
            this.formControl.setValue(null);

            return;
        }

        this.formControl.setValue(`${startDate} - ${endDate}`);
    }

    private emitErrors(): void {
        const errors = {
            ...this.dateRangePickerControl.get('start')?.errors,
            ...this.dateRangePickerControl.get('end')?.errors,
            ...this.formControl.errors,
        };

        // eslint-disable-next-line no-null/no-null
        this.errors.emit(Object.keys(errors).length ? errors : null);
    }

    private defaultMinDate(): Date {
        const currentDate = new Date();

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return new Date(currentDate.getFullYear() - 100, currentDate.getMonth(), currentDate.getDate());
    }
}
