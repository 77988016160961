import { type SubmissionValueState } from '../../models/form-submission/submission-value-state.model';
import { type AccessorFn, type SubmissionValueAccessorFn } from '../drupal-webform-types';

export const SUBMISSION_VALUE_ACCESSOR_DATE: SubmissionValueAccessorFn = (v, { name }) => {
    if (!v || typeof v !== 'string') {
        return undefined;
    }

    const [day, month, year] = v.split('.');
    if (!day || !month || !year) {
        return undefined;
    }

    return { [name]: `${year}-${month}-${day}` };
};
// Input: 2023-06-15
export const VALUE_ACCESSOR_DATE: AccessorFn = v => {
    if ((v as SubmissionValueState | undefined)?.isDisabled) {
        return undefined;
    }

    if (!v) {
        return '';
    }
    const value = v as string;
    const [year, month, day] = value.split('-');

    return `${day}.${month}.${year}`;
};
