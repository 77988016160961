import { type BooleanEvaluator } from './boolean-evaluator';

export abstract class BinaryOperatorNode implements BooleanEvaluator {
    protected left: BooleanEvaluator;
    protected right: BooleanEvaluator;

    public constructor(left: BooleanEvaluator, right: BooleanEvaluator) {
        this.left = left;
        this.right = right;
    }

    public abstract evaluate(): boolean;
}
