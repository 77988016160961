import { Component } from '@angular/core';
import { InputBaseComponent } from '../input-base/input-base.component';

@Component({
    selector: 'big-form-text-base',
    template: '',
})
export class TextfieldBaseComponent extends InputBaseComponent {
    public minimal = false;
}
