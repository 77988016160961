import { type FormConfigElement } from '../../models/form-config-element/form-config-element.model';
import { type SubmissionDateRange } from '../../models/form-submission/submission-date-range.model';
import { type SubmissionValueState } from '../../models/form-submission/submission-value-state.model';
import { type AccessorFn, type SubmissionValueAccessorFn } from '../drupal-webform-types';
import { SUBMISSION_VALUE_ACCESSOR_DATE, VALUE_ACCESSOR_DATE } from './value-accessor-date';

// INPUT: 24.12.2024-26.12.2024
export const SUBMISSION_VALUE_ACCESSOR_DATE_RANGE: SubmissionValueAccessorFn = (v, { name }) => {
    if (!v || typeof v !== 'string') {
        return undefined;
    }

    const [start, end] = v.split('-').map(x => x.trim());

    if (!start || !end) {
        return undefined;
    }

    return {
        [name]: {
            ...SUBMISSION_VALUE_ACCESSOR_DATE(start, { name: 'start' } as FormConfigElement),
            ...SUBMISSION_VALUE_ACCESSOR_DATE(end, { name: 'end' } as FormConfigElement),
        },
    };
};

export const VALUE_ACCESSOR_DATE_RANGE: AccessorFn = v => {
    if ((v as SubmissionValueState | undefined)?.isDisabled) {
        return undefined;
    }

    const value = v as SubmissionDateRange | undefined;

    return value?.start && value.end ? `${VALUE_ACCESSOR_DATE(value.start) as string} – ${VALUE_ACCESSOR_DATE(value.end) as string}` : '';
};
