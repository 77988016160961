import { Validators, type ValidatorFn } from '@angular/forms';
import { type FormConfigElement } from '@big-direkt/form/contracts';
import { type CustomFormControlValidatorEntry } from '../../interfaces/custom-form-control-validator.token';

export const numberFieldValidatorFactory: () => CustomFormControlValidatorEntry = (): CustomFormControlValidatorEntry =>
    ({
        type: 'number',
        handle: (settings: FormConfigElement, validators: ValidatorFn[]): void => {
            if (settings.min !== undefined) {
                validators.push(Validators.min(settings.min));
            }

            if (settings.max !== undefined) {
                validators.push(Validators.max(settings.max));
            }
        },
    }) as CustomFormControlValidatorEntry;
