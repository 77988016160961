<big-ui-form-row
    [id]="settings.id"
    [label]="undefined"
    [isRequired]="false"
    [errors]="control?.errors"
    [showError]="isInvalid"
    [showInfo]="false">
    <div class="flex flex-row gap-4">
        <div class="flex-1">
            <big-ui-label
                class="mb-2 leading-6 h5"
                [for]="settings.id"
                [label]="settings.title"
                [isDisabled]="control?.disabled"
                [isRequired]="control?.isRequired" />
            <span>
                {{ settings.description }}
            </span>
        </div>
        <big-ui-select
            class="min-w-24"
            [id]="settings.id"
            [options]="settings.options"
            [isValid]="isValid"
            [isRequired]="control?.isRequired"
            [showValidation]="isPageValidationFailed"
            [formControl]="control"
            [optionsDisabled]="!!settings.attributes?.disabled"
            [showNoSelectionOption]="false"
            [formFieldTracking]="settings" />
    </div>
</big-ui-form-row>
