import { Injectable, inject } from '@angular/core';
import { PreviewBuilderService } from '@big-direkt/form/preview';
import { type PreviewValue } from '@big-direkt/form/contracts';
import { type PageHierarchyFormNode } from '../page-navigation/page-hierarchy-node';
import { PageNavigationService } from '../page-navigation/page-navigation.service';
import { TokenService } from '../token/token.service';
import { FormElementValueService } from '../form-element-value/form-element-value.service';

@Injectable({
    providedIn: 'root',
})
export class SubmissionPreviewBuilderService {
    private readonly pageNavigationService = inject(PageNavigationService);
    private readonly tokenService = inject(TokenService);
    private readonly valueService = inject(FormElementValueService);

    private readonly previewBuilderService: PreviewBuilderService;

    public constructor() {
        this.previewBuilderService = new PreviewBuilderService(this.pageNavigationService, this.valueService, this.tokenService.tokenRefService);
    }

    public buildSinglePage(page: PageHierarchyFormNode, withLabel = true): PreviewValue {
        return this.previewBuilderService.buildSinglePage(page, withLabel);
    }
}
