// eslint-disable-next-line max-classes-per-file
import { UntypedFormControl, type ValidatorFn } from '@angular/forms';
import { type UploadFile } from '@big-direkt/form/contracts';

export class BigUntypedFormControl extends UntypedFormControl {

    /**
     * rawValidators are meant to contain all default validators of a control based on it's settings.
     * use rawValidators as baseline to reset validators on a FormControl (i.e. in a conditional-state-callback)
     */
    public rawValidators: ValidatorFn[] = [];
    public isRequired = false;
}

// I'm not happy with this workaround. This should be the value of the control.
// But it doesn't work with formControl binding on a type=file input
export class BigFileUploadFormControl extends BigUntypedFormControl {
    public files: UploadFile[] = [];
}

export class BigFormControl<T> extends BigUntypedFormControl {
    public declare readonly value: T;
}
