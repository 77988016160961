import { Injectable, inject } from '@angular/core';
import { NgOverlayContainerService, type NgPopoverRef } from 'ng-overlay-container';
import { ModalEmbeddedComponent } from '../components/embedded/modal-embedded.component';
import { ModalFormCloseComponent } from '../components/form-close/modal-form-close.component';
import { ModalInformationComponent } from '../components/information/modal-information.component';
import { ModalSickNoteDetailsComponent } from '../components/sick-note-details/sick-note-details.component';
import { DEFAULT_EMBEDDED_OVERLAY_CONFIGURATION } from '../models/default-embedded-overlay-configuration.model';
import { DEFAULT_FORM_CLOSE_OVERLAY_CONFIGURATION } from '../models/default-form-close-overlay-configuration.model';
import { DEFAULT_INFORMATION_OVERLAY_CONFIGURATION } from '../models/default-information-overlay-configuration.model';
import { type EmbeddedOverlayData } from '../models/embedded-overlay-data.model';
import { type FormCloseOverlayData } from '../models/form-close-overlay-data.model';
import { InformationOverlayData } from '../models/information-overlay-data.model';
import { SickNoteDetailsOverlayData } from '../models/sick-note-details-overlay-data.model';

@Injectable({ providedIn: 'root' })
export class ModalService {
    private readonly overlayContainerService = inject(NgOverlayContainerService);

    public showEmbeddedSrc(data: EmbeddedOverlayData, configuration = DEFAULT_EMBEDDED_OVERLAY_CONFIGURATION): NgPopoverRef<EmbeddedOverlayData> {
        return this.overlayContainerService.open<EmbeddedOverlayData>({
            content: ModalEmbeddedComponent,
            data,
            configuration,
        });
    }

    public showFormClose(configuration = DEFAULT_FORM_CLOSE_OVERLAY_CONFIGURATION): NgPopoverRef<undefined, FormCloseOverlayData> {
        return this.overlayContainerService.open<undefined, FormCloseOverlayData>({
            content: ModalFormCloseComponent,
            configuration,
        });
    }

    public showInformation(data: InformationOverlayData, configuration = DEFAULT_INFORMATION_OVERLAY_CONFIGURATION): NgPopoverRef<unknown> {
        return this.overlayContainerService.open<InformationOverlayData>({
            content: ModalInformationComponent,
            data,
            configuration,
        })
    }

    public showSickNoteDetails(data: SickNoteDetailsOverlayData, configuration = DEFAULT_INFORMATION_OVERLAY_CONFIGURATION): NgPopoverRef<unknown> {
        return this.overlayContainerService.open<SickNoteDetailsOverlayData>({
            content: ModalSickNoteDetailsComponent,
            data,
            configuration,
        })
    }
}
