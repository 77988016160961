<input
    type="text"
    [mask]="mask"
    [placeholder]="placeholder"
    class="relative"
    class="form-control"
    [ngClass]="{
        'is-invalid': !isValid && showValidation,
        'is-valid': isValid && showValidation
    }"
    [required]="isRequired"
    [id]="id"
    [name]="id"
    [formControl]="formControl"
    (ngModelChange)="onChange(formControl.value)"
    (blur)="onBlur()"
    inputmode="decimal" />
