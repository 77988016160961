import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormConfigElement } from '@big-direkt/form/contracts';
import { FormService } from '../form/form.service';

@Injectable({
    providedIn: 'root',
})
export class FormRelationService {
    private readonly formService = inject(FormService);

    public initializeRelations(element: FormConfigElement, destroyRef: DestroyRef): void {
        const relationItems: FormConfigElement[] = [];
        this.collectRelationItems(element, relationItems);

        relationItems.forEach(relation => {
            const relatedElement =
                (relation.parentContextOnly ?? false)
                    ? this.findRelatedComponentInParentContext(relation, !(relation.extendedWebformContext ?? false))
                    : this.findRelatedComponentInParentRecursive(element, relation);

            if (!relatedElement) {
                return;
            }

            const relationControl = this.formService.getFormControl(relation.arrayParents);
            const relatedControl = this.formService.getFormControl(relatedElement.arrayParents);
            if (!relatedControl || !relationControl) {
                return;
            }

            relatedControl.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe((value: unknown): void => {
                relationControl.setValue(value);
            });

            if (relatedControl.value) {
                relationControl.setValue(relatedControl.value);
            }
        });
    }

    private collectRelationItems(element: FormConfigElement, relationItems: FormConfigElement[]): void {
        if (element.type === 'big_webform_form_element_ref') {
            relationItems.push(element);

            return;
        }

        element.children.forEach((child: FormConfigElement): void => {
            this.collectRelationItems(child, relationItems);
        });
    }

    private findRelatedItem(context: FormConfigElement, relation: FormConfigElement, currentWebformOnly = false): FormConfigElement | undefined {
        if (context.webform === relation.referencedFormId && context.name === relation.referencedFieldKey) {
            return context;
        }

        for (const child of context.children) {
            if (currentWebformOnly && child.webform !== context.webform) {
                continue;
            }

            const relatedItem = this.findRelatedItem(child, relation);

            if (relatedItem) {
                return relatedItem;
            }
        }

        return undefined;
    }

    private findRelatedComponentInParentRecursive(context: FormConfigElement, relation: FormConfigElement): FormConfigElement | undefined {
        return this.findRelatedItem(context, relation) ?? (context.parent && this.findRelatedComponentInParentRecursive(context.parent, relation));
    }

    private findRelatedComponentInParentContext(relation: FormConfigElement, currentWebformOnly: boolean): FormConfigElement | undefined {
        let excluded: FormConfigElement = relation;

        for (let parent: FormConfigElement | undefined = relation.parent; parent !== undefined; parent = parent.parent) {
            for (const sibling of parent.children) {
                if (sibling === excluded || (currentWebformOnly && sibling.webform !== parent.webform)) {
                    continue;
                }

                const relatedItem = this.findRelatedItem(sibling, relation, currentWebformOnly);

                if (relatedItem) {
                    return relatedItem;
                }
            }

            excluded = parent;
        }

        return undefined;
    }
}
