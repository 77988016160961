import { Component, DestroyRef, inject, type OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { type AbstractControl, type ValidationErrors, type ValidatorFn } from '@angular/forms';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumOk } from '@big-direkt/utils/icons';
import { StructureBaseComponent } from '../../../base-components/structure-base/structure-base.component';
import { type BigUntypedFormGroup } from '../../../form-control/big-form-group';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-password-confirm',
    templateUrl: './password-confirm.component.html',
    providers: [
        provideTranslationScope('ftbPasswordConfirm', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`)),
    ],
})
export class PasswordConfirmComponent extends StructureBaseComponent implements OnInit {
    public static readonly hasCorrectLengthRegex = new RegExp('^.{8,32}$');
    // eslint-disable-next-line sonarjs/concise-regex
    public static readonly hasNumberRegex = new RegExp('[0-9]');
    public static readonly hasLowerLetterRegex = new RegExp('[a-zöäüß]');
    public static readonly hasUpperLetterRegex = new RegExp('[A-ZÄÖÜ]');
    public static readonly hasSpecialCharRegex = new RegExp('[^a-zA-ZäöüÄÖÜß0-9]');

    public readonly iconOk = IconBigMediumOk;
    private readonly destroyRef = inject(DestroyRef);

    public declare control?: BigUntypedFormGroup;
    public hasUpperLetter = false;
    public hasLowerLetter = false;
    public hasNumber = false;
    public hasSpecialChar = false;
    public hasCorrectLength = false;
    public matches = false;

    // eslint-disable-next-line no-null/no-null
    private pass1: string | null = null;
    // eslint-disable-next-line no-null/no-null
    private pass2: string | null = null;

    public static register(): ComponentMap {
        return {
            password_confirm: PasswordConfirmComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }

    public static passwordMatchValidator(): ValidatorFn {
        return (basePasswordControl: AbstractControl): ValidationErrors | null => {
            // eslint-disable-next-line no-null/no-null
            if (basePasswordControl.value === null || basePasswordControl.parent?.controls === undefined) {
                return { passwordsDoNotMatch: true };
            }

            const controls: AbstractControl[] = Object.values(basePasswordControl.parent.controls);

            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            if (controls.length !== 2) {
                return { passwordsDoNotMatch: true };
            }

            const newPassword1: AbstractControl = controls[0];
            const newPassword2: AbstractControl = controls[1];

            if (newPassword1.value === '' && newPassword2.value === '') {
                newPassword1.setErrors({ ...newPassword1.errors, passwordsDoNotMatch: true });
                newPassword2.setErrors({ ...newPassword2.errors, passwordsDoNotMatch: true });

                return { passwordsDoNotMatch: true };
            }

            if (newPassword1.value !== newPassword2.value) {
                newPassword1.setErrors({ ...newPassword1.errors, passwordsDoNotMatch: true });
                newPassword2.setErrors({ ...newPassword2.errors, passwordsDoNotMatch: true });

                return { passwordsDoNotMatch: true };
            }

            // unfortunately programmatically deleting errors leaves an empty object
            // if the control.errors is {} it is still seen as invalid, thus we check the length of errors and null it accordingly
            delete newPassword1.errors?.passwordsDoNotMatch;
            if (newPassword1.errors && Object.keys(newPassword1.errors).length === 0) {
                // eslint-disable-next-line no-null/no-null
                newPassword1.setErrors(null);
            }
            delete newPassword2.errors?.passwordsDoNotMatch;
            if (newPassword2.errors && Object.keys(newPassword2.errors).length === 0) {
                // eslint-disable-next-line no-null/no-null
                newPassword2.setErrors(null);
            }

            // eslint-disable-next-line no-null/no-null
            return null;
        };
    }

    public ngOnInit(): void {
        this.control?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res: Record<string, string>): void => {
            this.pass1 = res[`${this.settings.name}[pass1]`];
            this.pass2 = res[`${this.settings.name}[pass2]`];

            this.checkPasswordMatch();
            this.validate();
        });
    }

    private checkPasswordMatch(): void {
        this.matches = this.pass1 === this.pass2 && this.pass1 !== '' && this.pass2 !== '';
    }

    private validate(): void {
        // eslint-disable-next-line no-null/no-null, @typescript-eslint/no-unnecessary-condition,sonarjs/different-types-comparison
        if (this.pass1 === undefined || this.pass1 === null) {
            this.hasUpperLetter = false;
            this.hasLowerLetter = false;
            this.hasNumber = false;
            this.hasSpecialChar = false;
            this.hasCorrectLength = false;

            return;
        }

        this.hasLowerLetter = Boolean(PasswordConfirmComponent.hasLowerLetterRegex.exec(this.pass1));
        this.hasUpperLetter = Boolean(PasswordConfirmComponent.hasUpperLetterRegex.exec(this.pass1));
        this.hasNumber = Boolean(PasswordConfirmComponent.hasNumberRegex.exec(this.pass1));
        this.hasSpecialChar = Boolean(PasswordConfirmComponent.hasSpecialCharRegex.exec(this.pass1));
        this.hasCorrectLength = Boolean(PasswordConfirmComponent.hasCorrectLengthRegex.exec(this.pass1));
    }
}
