@if (radiogroupId) {
    @if (label) {
        <p
            [id]="radiogroupId"
            class="flex items-center w-full m-0 mb-3 text-base cursor-default"
            *transloco="let t; prefix: 'uiFormRow'">
            {{ label }}{{ isRequired ? '&nbsp;' + t('required') : '' }}
        </p>
    }
    <ng-container [ngTemplateOutlet]="content" />
} @else {
    @if (label) {
        <div class="flex w-full mb-3">
            <div class="grow">
                <big-ui-label
                    [for]="id"
                    [label]="label"
                    [isDisabled]="isDisabled"
                    [isRequired]="isRequired" />
            </div>

            @if (showInfoButtonInLabel) {
                <div class="shrink-0">
                    <ng-container [ngTemplateOutlet]="infoButton" />
                </div>
            }
        </div>
    }
    <ng-container [ngTemplateOutlet]="content" />
}

<ng-template #content>
    @if (showInfoButtonInLabel) {
        <ng-container *ngTemplateOutlet="helpTextContent; context: { position: 'first' }" />
        <ng-container [ngTemplateOutlet]="contentAndErrorOutput" />
    } @else {
        <div class="flex w-full">
            <div class="grow">
                <ng-container [ngTemplateOutlet]="contentAndErrorOutput" />
            </div>
            @if (showInfo) {
                <div class="shrink-0">
                    <ng-container [ngTemplateOutlet]="infoButton" />
                </div>
            }
        </div>
        <ng-container *ngTemplateOutlet="helpTextContent; context: { position: 'last' }" />
    }
</ng-template>

<ng-template #infoButton>
    @if (showInfo && infoTextTemplate) {
        <div [ngClass]="infoClass">
            <big-ui-info-button
                [scrollAnchorId]="'info-text-' + id"
                (infoLayerOpened)="onInfoLayerOpen($event)" />
        </div>
    }
</ng-template>

<ng-template #contentAndErrorOutput>
    <ng-content />

    @if (showError && hasErrors()) {
        <big-ui-error
            class="mt-2"
            [onlyRenderFirstError]="onlyRenderFirstError"
            [errors]="errorMessages()"
            [overrides]="errorOverrides" />
    }
</ng-template>

<ng-template
    #helpTextContent
    let-position="position">
    @if (isTooltipOpen && infoTextTemplate) {
        <div
            class="mr-10"
            [ngClass]="{
                'mb-2': position === 'first',
                'mt-2': position === 'last',
            }">
            <div
                [id]="'info-text-' + id"
                class="p-3 bg-gray-400 border-2 border-gray-300 rounded-3">
                <ng-container [ngTemplateOutlet]="infoTextTemplate" />
            </div>
        </div>
    }
</ng-template>
