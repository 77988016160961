<div [formGroup]="radiosGroup">
    @for (item of items; track childIndex; let childIndex = $index) {
        <big-ui-form-row
            [showInfo]="!!item.help"
            [infoTextTemplate]="help"
            [id]="id + '-' + childIndex"
            class="block [&:not(:last-child)]:mb-2"
            rowClass="block"
            (infoLayerOpened)="onInfoLayerOpened($event, item)">
            <label
                class="border-0"
                for="{{ id }}-{{ childIndex }}">
                <div
                    class="flex items-center border-2 border-transparent !bg-gray-400 hover:!bg-gray-300 focus-within:!bg-gray-300 p-1.5 rounded-lg"
                    [ngClass]="{
                        '!border-success': isValid,
                        '!border-danger': isInvalid,
                    }">
                    <big-ui-radio
                        [id]="id + '-' + childIndex"
                        [formControlName]="name"
                        [item]="item"
                        [isValid]="isValid"
                        [isInvalid]="isInvalid"
                        (ngModelChange)="onChange($event)"
                        [name]="id" />
                    <div class="w-full h-full ml-2">
                        <big-ui-label
                            for="{{ id }}-{{ childIndex }}"
                            [label]="item.title"
                            [isDisabled]="item.isDisabled"
                            [isRequired]="item.isRequired" />
                        <div [innerHTML]="item.description"></div>
                    </div>
                </div>
            </label>
        </big-ui-form-row>
        <ng-template #help>
            <div [innerHTML]="item.help | nl2br"></div>
        </ng-template>
    }
</div>
