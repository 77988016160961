import { type SubmissionObject } from '../../models/form-submission/submission-object.model';
import { type TypeDef } from '../drupal-webform-types';
import { getLastNestedParent } from '../nested-submission-value.helper';

export const SUBMISSION_MAPPER_STRUCTURE = (data: SubmissionObject, propName: string, arrayParents: string[]): void => {
    const lastParent = getLastNestedParent(data, propName, ...arrayParents);

    if (!lastParent) {
        return;
    }

    const value = lastParent[propName];

    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete lastParent[propName];

    Object.assign(lastParent, value);
};

export const TYPE_DEF_STRUCTURE: TypeDef = {
    isInput: false,
    isStructure: true,
    submissionMapper: SUBMISSION_MAPPER_STRUCTURE,
};
