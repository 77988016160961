/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, Injectable, type Type } from '@angular/core';
import { type ValidatorFn } from '@angular/forms';
import { type FormConfigElement } from '@big-direkt/form/contracts';
import { EnvironmentService } from '@big-direkt/utils/environment';
import { type BaseComponent } from '../../base-components/base/base.component';
import { type LabelComponent } from '../../structures/components/label/label.component';
import { isExtendedMapConfig, type ComponentMapConfig } from '../../utilities/component-map/component-map';
import { RegisterComponentService } from '../component/register-component.service';

@Injectable({
    providedIn: 'root',
})
export class DrupalWebformService extends RegisterComponentService {
    private readonly environmentService = inject(EnvironmentService);

    public registeredValidators(settings: FormConfigElement): ValidatorFn[] {
        const map = this.getComponentMap(settings);

        return isExtendedMapConfig(map) ? map.validators ?? [] : [];
    }

    public registeredComponentType(settings: FormConfigElement): Type<BaseComponent | LabelComponent> | undefined {
        const map = this.getComponentMap(settings);

        return isExtendedMapConfig(map) ? map.component : map;
    }

    private getComponentMap(settings: FormConfigElement): ComponentMapConfig | undefined {
        const map = this.componentMap[settings.type];
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (map === undefined && !this.environmentService.production) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            console.warn(`Not supported element '${settings.type}'!\nParent-Trace: ${(settings.arrayParents ?? []).join(' -> ')}`);
        }

        return map;
    }
}
