import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ScrollService } from '@big-direkt/utils/environment';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumBearbeiten } from '@big-direkt/utils/icons';
import { BaseComponent } from '../base-components/base/base.component';
import { ErrorMessageService } from '../services/error-message/error-message.service';
import { type PageHierarchyFormNode } from '../services/page-navigation/page-hierarchy-node';
import { SubmissionPreviewBuilderService } from '../services/submission-preview-builder/submission-preview-builder.service';
import { type ComponentMap } from '../utilities/component-map/component-map';

@Component({
    selector: 'big-form-preview',
    templateUrl: './preview.component.html',
    providers: [provideTranslationScope('ftbPreview', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewComponent extends BaseComponent {
    public readonly iconEdit = IconBigMediumBearbeiten;

    public override classList = 'block';

    public constructor(
        private readonly errorMessageService: ErrorMessageService,
        private readonly scrollService: ScrollService,
        public readonly submissionPreviewBuilderService: SubmissionPreviewBuilderService,
    ) {
        super();
    }

    public static register(): ComponentMap {
        return {
            big_webform_preview: PreviewComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }

    public onClick($event: Event, page: PageHierarchyFormNode): void {
        $event.preventDefault();

        this.eventBus.emit({
            key: 'forms_previous_step_event',
            data: {
                triggerElementText: ($event.target as HTMLElement).outerText,
                stepName: this.pageNavigationService.getCurrentPage()?.wizardLabel,
                targetStepName: page.wizardLabel,
                triggerElement: ($event.target as HTMLInputElement).tagName,
            },
        });

        this.errorMessageService.reset();
        this.pageNavigationService.gotoPage(page);
        this.scrollService.scroll();
    }
}
