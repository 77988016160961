import { Injectable } from '@angular/core';
import { DRUPAL_WEBFORM_TYPE_CONFIG, type FormConfigElement } from '@big-direkt/form/contracts';
import { TokenRefService, type TokenTreeNode } from '@big-direkt/form/preview';
import { FormService } from '../form/form.service';

const updateNode =
    (formService: FormService) =>
        (node: TokenTreeNode, element: FormConfigElement): void => {
            const control = formService.getFormControl(element.arrayParents);
            const formatter = DRUPAL_WEBFORM_TYPE_CONFIG[element.type].valueFormatter;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            node.valueGetter = (): any => (formatter ? formatter(control?.value, element) : control?.value);
            node.hasFilledVisibleControl = (): boolean => !!control && control.enabled && control.value !== undefined;
        };

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    public readonly tokenRefService: TokenRefService;

    public constructor() {
        this.tokenRefService = new TokenRefService();
    }

    public register(element: FormConfigElement, formService: FormService): void {
        this.tokenRefService.register(element, updateNode(formService));
    }

    public findReplacement(token: string, parents: string[] = []): string {
        return this.tokenRefService.findReplacement(token, parents);
    }

    public replaceTokenRefs(settings: FormConfigElement, index: number | string): void {
        this.tokenRefService.replaceTokenRefs(settings, index.toString());
    }

    public replaceIndexedLabels(settings: FormConfigElement, groupIndex: number | string): void {
        this.tokenRefService.replaceIndexedLabels(settings, groupIndex.toString());
    }
}
