<ng-container *transloco="let t; prefix: 'ftbContactFormSearch'">
    <big-ui-form-row
        [id]="settings.id"
        [label]="label"
        [isRequired]="control?.isRequired"
        [errors]="searchQuery?.errors"
        [showError]="isPageValidationFailed && !searchQuery.valid"
        [errorOverrides]="defaultTopicSelectError"
        [showInfo]="!!settings.help"
        [infoTextTemplate]="help"
        (keydown.enter)="onKeyDown($event)"
        (infoLayerOpened)="trackFormsInfoIconEvent($event)">
        <div class="relative">
            <big-ui-autocomplete-input
                [name]="settings.name"
                [formControl]="searchQuery"
                [id]="settings.id"
                [options]="suggestionOptions()"
                [isValid]="isPageValidationFailed && searchQuery.valid"
                [isInvalid]="isPageValidationFailed && !searchQuery.valid"
                [buttonStyle]="
                    'flex w-7 h-7 justify-center bg-white items-center fill-primary dark:fill-white [&>svg]:!w-full [&>svg]:!h-auto absolute -translate-x-1 right-14 hover:fill-primary-light'
                "
                [inputStyle]="'form-control pr-16'"
                [placeholder]="t('placeholder')"
                (elementBlurred)="onBlur()"
                (optionSelected)="onBlur()"
                [minCharacters]="0"
                [optionsValueTransformer]="transformOptionValue">
                <span class="absolute bg-white pl-2 border-l border-l-gray-300 right-3 top-3 -translate-y-0.5">
                    <button
                        type="button"
                        class="flex items-center justify-center p-0.5 fill-primary"
                        [attr.aria-label]="t('search')">
                        <big-icon
                            [icon]="iconSearch"
                            class="[&>svg]:!h-5 [&>svg]:!w-auto p-0.5" />
                    </button>
                </span>
            </big-ui-autocomplete-input>
        </div>
    </big-ui-form-row>
    @if (resultLinks().length > 0) {
        <h3 class="mt-8 mb-0 text-lg">{{ t('edit') }}</h3>
        <div class="grid grid-cols-12 mt-2 mb-8 gap-x-6">
            <ul class="col-span-12 mb-0 lg:col-span-6">
                @for (item of resultLinks(); track $index) {
                    <li>
                        <big-ui-link-list-item [item]="item" />
                    </li>
                }
            </ul>
        </div>

        @if (!hasProceeded) {
            <button
                type="button"
                (click)="confirmAndProceed($event)"
                class="flex items-center justify-center mt-8 font-bold cursor-pointer text-primary-600">
                {{ t('contact') }}
                <big-icon
                    class="ml-1 fill-primary"
                    [icon]="iconMessage" />
            </button>
            @if (isPageValidationFailed) {
                <big-ui-error
                    class="mt-4"
                    [errors]="errorOverrides"
                    [overrides]="errorOverrides"></big-ui-error>
            }
        }
    }
</ng-container>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
