import { type SubmissionValueState } from '../models/form-submission/submission-value-state.model';
import { type SubmissionObject } from '../models/form-submission/submission-object.model';
import { type SubmissionValue } from '../models/form-submission/submission-value.model';

export const checkNested = (obj: SubmissionObject | SubmissionValue, configName: string, ...arrayParents: string[]): boolean => {
    if (obj === undefined || arrayParents.length === 0) {
        return false;
    }
    const [propName, ...rest] = arrayParents;
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(configName)) {
        return true;
    }

    // eslint-disable-next-line no-prototype-builtins
    return checkNested(obj.hasOwnProperty(propName) ? (obj as SubmissionObject)[propName] : obj, configName, ...rest);
};

// eslint-disable-next-line sonarjs/function-return-type
export const getNestedSubmissionValue = (obj: SubmissionObject | SubmissionValue, propName: string, ...arrayParents: string[]): SubmissionValue | undefined => {
    if ((obj as SubmissionValueState | undefined)?.isDisabled) {
        return obj;
    }

    const parent = obj as SubmissionObject | undefined;
    if (parent === undefined) {
        return undefined;
    }

    if (parent[propName] !== undefined) {
        return parent[propName];
    }

    // if not found propName check under next arrayParent level
    const [arrayParent, ...rest] = arrayParents;
    if (arrayParents.length === 0 || propName === arrayParent) {
        return undefined;
    }

    return getNestedSubmissionValue(parent[arrayParent] ?? obj, propName, ...rest);
};

export const getLastNestedParent = (obj: SubmissionObject | undefined, configName: string, ...arrayParents: string[]): SubmissionObject | undefined => {
    if (obj === undefined || arrayParents.length === 0) {
        return undefined;
    }
    const [propName, ...rest] = arrayParents;
    // eslint-disable-next-line no-prototype-builtins
    if (arrayParents.length <= 1 && obj.hasOwnProperty(configName)) {
        return obj;
    }

    // eslint-disable-next-line no-prototype-builtins
    return getLastNestedParent((obj[propName] as SubmissionObject | undefined) ?? obj, configName, ...rest);
};
