import { DestroyRef, Injectable, createComponent, inject, type ComponentRef, type EnvironmentInjector, type Type, type ViewContainerRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EventBusService } from '@big-direkt/event-bus';
import { DRUPAL_WEBFORM_TYPE_CONFIG, FormConfigElement, type AllowedDrupalType } from '@big-direkt/form/contracts';
import { type BaseComponent } from '../../base-components/base/base.component';
import { DrupalWebformService } from '../drupal-webform/drupal-webform.service';

@Injectable({
    providedIn: 'root',
})
export class ComponentService {
    private readonly manualChildHandlingKeys: AllowedDrupalType[] = [];
    private readonly eventBus = inject(EventBusService);
    private readonly drupalWebformService = inject(DrupalWebformService);
    private readonly destroyRef = inject(DestroyRef);
    private componentMap: Record<string, ComponentRef<BaseComponent> | undefined> = {};

    public environmentInjector!: EnvironmentInjector;

    public constructor() {
        this.eventBus.on('form_unloaded').pipe(
            takeUntilDestroyed(this.destroyRef),
        ).subscribe(() => {
            this.componentMap = {};
        });
    }

    public registerForManualChildHandling(drupalFormKeys: AllowedDrupalType[]): void {
        this.manualChildHandlingKeys.push(...drupalFormKeys);
    }

    public createComponent<T extends BaseComponent>(settings: FormConfigElement): ComponentRef<T> {
        const componentClass = this.drupalWebformService.registeredComponentType(settings);
        if (!componentClass) {
            throw new Error(`No ComponentClass registered for setting with type ${settings.type}`);
        }
        const componentRef = createComponent<T>(componentClass as Type<T>, { environmentInjector: this.environmentInjector });

        componentRef.instance.settings = settings;
        this.componentMap[settings.id] = componentRef;
        componentRef.instance.init();

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!!DRUPAL_WEBFORM_TYPE_CONFIG[settings.type].preventChildProcessing || !settings.children?.length) {
            return componentRef;
        }

        const children = [...settings.children];
        children.forEach(child => {
            this.createComponent(child);
        });

        return componentRef;
    }

    public insertViewChildren(settings: FormConfigElement[], viewContainer: ViewContainerRef | undefined): void {
        settings.forEach(element => {
            if (!(element.id in this.componentMap)) {
                this.createComponent(element);
            }
            const componentRef = this.componentMap[element.id];
            if (componentRef && viewContainer) {
                viewContainer.insert(componentRef.hostView);
                // these components insert their children to a specific location in the dom. They must not be handled by page initializer.
                if (!!DRUPAL_WEBFORM_TYPE_CONFIG[element.type].preventChildProcessing || this.manualChildHandlingKeys.includes(element.type)) {
                    return;
                }
                if (element.children.length) {
                    this.insertViewChildren(element.children, componentRef.instance.elementHost?.viewContainerRef);
                }
            }
        });
    }

    public insertSingleViewChild(element: FormConfigElement, viewContainer: ViewContainerRef | undefined): void {
        const componentRef = this.componentMap[element.id];
        if (componentRef && viewContainer) {
            viewContainer.insert(componentRef.hostView);
        }
    }

    public detachViewContainer(viewContainerRef: ViewContainerRef | undefined): void {
        while (viewContainerRef?.length) {
            viewContainerRef.detach();
        }
    }

    public removeComponent(id: string | undefined): void {
        if (id && id in this.componentMap) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete this.componentMap[id];
        }
    }
}
