import { Component } from '@angular/core';
import { InputBaseComponent } from '../../../base-components/input-base/input-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-currency-input',
    templateUrl: './currency-input.component.html',
})
export class CurrencyInputComponent extends InputBaseComponent {
    public static register(): ComponentMap {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            big_webform_currency: CurrencyInputComponent,
        };
    }
}
