@if (isLoadingData | async) {
    <big-form-initial-loading />
}
<big-error-handling-alert
    [errorCode]="errorCode"
    errorType="form" />

<div
    [ngClass]="{
        'hidden': (isLoadingData | async) || errorCode,
    }">
    @if (!isTransmissionSuccessful) {
        <big-ui-user-data
            [viewMode]="'inline-center'"
            class="block md:hidden" />
    }

    <form
        *transloco="let t; prefix: 'ftbForm'"
        [formGroup]="formService.currentForm"
        (ngSubmit)="onSubmit($event)">
        @if (isNextPageAvailable || isFormShown()) {
            @for (markup of topLevelMarkups; track $index) {
                <big-form-markup [settings]="markup" />
            }
        }
        @if (currentPage) {
            <big-form-page [settings]="currentPage.settings" />
        }
        <div #buttonBarPortalContent>
            @if (isBackButtonAvailable || isNextPageAvailable) {
                <div
                    [ngClass]="{
                        'pt-4 mt-4': !isFormShown(),
                    }">
                    <div [ngClass]="isFormShown() ? 'z-fixed fixed inset-x-0 bottom-0 transition-shadow bg-default shadow-form-footer' : 'row'">
                        <div
                            class="grid grid-cols-12 sm:gap-6"
                            [ngClass]="{
                                'my-4': !isFormShown(),
                                'container my-3.5': isFormShown(),
                            }">
                            <div
                                class="flex col-span-12"
                                [ngClass]="{
                                    'md:col-span-9 md:col-start-4': isWizardShown(),
                                }">
                                @if (isBackButtonAvailable && !isSkipFormStepButtonAvailable) {
                                    <button
                                        (click)="backButtonHandler($event)"
                                        [disabled]="isTransmitting() || (buttonDisabled | async)"
                                        type="button"
                                        class="flex items-center text-lg btn-link text-primary dark:text-dark-primary text-decoration-none fw-bold group">
                                        <big-icon
                                            [icon]="iconArrowLeft"
                                            class="mr-2 [&>svg]:fill-primary group-hover:[&>svg]:fill-primary-light" />
                                        {{ backButtonLabel | stringReplacePipe }}
                                    </button>
                                }
                                @if (isSkipFormStepButtonAvailable && !isBackButtonAvailable) {
                                    <button
                                        (click)="skipFormStepButtonHandler($event)"
                                        [disabled]="isTransmitting() || (buttonDisabled | async)"
                                        type="button"
                                        class="flex items-center text-lg btn-link text-primary dark:text-dark-primary text-decoration-none fw-bold">
                                        {{ skipFormStepButtonLabel }}
                                    </button>
                                }
                                @if (isNextPageAvailable) {
                                    <button
                                        class="flex items-center ml-auto shadow btn btn-secondary text-primary"
                                        type="submit"
                                        (click)="onSubmit($event)"
                                        [disabled]="isTransmitting() || (buttonDisabled | async)">
                                        @if (isTransmitting()) {
                                            <big-ui-spinner
                                                [spinnerClass]="'inline-flex mt-0.5 mr-2 align-text-top'"
                                                [size]="'s'"
                                                [color]="'primary'" />
                                        }
                                        {{ nextButtonLabel | stringReplacePipe }}
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

            @if (afterCompletedCallback && isTransmissionSuccessful) {
                <div>
                    <button
                        (click)="afterCompletedCallback()"
                        class="btn btn-secondary">
                        {{ afterCompletedText }}
                    </button>
                </div>
            }
        </div>
    </form>
</div>

@if (!isEmbeddedInMobileApp() && currentPage?.settings?.faqGroupNode) {
    <div class="mt-12">
        <big-form-faq [faqNodeId]="currentPage?.settings?.faqGroupNode" />
    </div>
}

<!-- Spacer fixed footer while form is rendered with fixed header and footer -->
@if ((isLoadingData | async) === false && !errorCode && isFormShown()) {
    <div class="h-10 my-3.5"></div>
}

@if (showFormState()) {
    <big-form-state-preview />
}
