import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserRepository } from '@big-direkt/state/user';
import { type WebformSubmissionResponse } from '../../interfaces/webform-submission-response';
import { FormStateService } from '../form-state/form-state.service';
import { FormUpdateService } from '../form-update/form-update.service';
import { SubmissionResponseType } from './submission-response-type';

@Injectable({
    providedIn: 'root',
})
export class SubmissionResponseHandlerService {
    public constructor(
        private readonly formStateService: FormStateService,
        private readonly formUpdateService: FormUpdateService,
        private readonly userRepository: UserRepository,
        private readonly router: Router,
    ) {}

    public handle(response: WebformSubmissionResponse): void {
        if (Array.isArray(response.data)) {
            this.formStateService.registerResponse(response);

            return;
        }

        const type = response.data?.type;

        switch (type) {
            case SubmissionResponseType.PdfDownload:
                this.formStateService.registerResponse(response);

                return;
            case SubmissionResponseType.FormUpdate:
                this.formUpdateService.updateFormFields(response.data?.attributes?.configuration ?? []);

                return;
            case SubmissionResponseType.VerifyUserData:
                this.formUpdateService.updateFormFields(response.data?.attributes?.configuration ?? []);

                this.userRepository.setToken(response.data?.attributes?.token);

                return;
            case SubmissionResponseType.Login:
                this.userRepository.setToken(response.data?.attributes?.token);

                this.formStateService.handleDestinationRedirection();

                return;
            case SubmissionResponseType.Redirect:
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                void this.router.navigateByUrl(response.data!.attributes!.url!);

                return;
            default:
                break;
        }
    }
}
