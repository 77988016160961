<button
    *transloco="let t; prefix: 'uiInfoButton'"
    (click)="toggleTooltip($event)"
    type="button"
    [attr.aria-expanded]="isOpen()"
    [attr.aria-controls]="scrollAnchorId"
    [title]="!isOpen() ? t('label.showInfo') : t('label.hideInfo')"
    class="overflow-hidden">
    @if (!isOpen()) {
        <span class="sr-only">{{ t('label.showInfo') }}</span>
        <big-icon
            [icon]="iconInfo"
            class="primary text-[1.6em] float-left" />
    } @else {
        <span class="sr-only">{{ t('label.hideInfo') }}</span>
        <big-icon
            [icon]="iconClose"
            class="primary text-[1.6em] float-left" />
    }
</button>
