import { Injectable } from '@angular/core';
import { KeyValuePair } from '@big-direkt/form/contracts';
import { type AutocompleteOption } from '@big-direkt/ui/input';

@Injectable({ providedIn: 'any' })
export class FilterOptionsService {
    public filterByTerm(term: string | undefined, options: KeyValuePair[]): AutocompleteOption<string>[] {
        if (!term) {
            return this.mapOptions(options);
        }

        const filteredOptions = options.filter(option => option.value.toLowerCase().includes(term.toLowerCase()));

        return this.mapOptions(filteredOptions);
    }

    public getValueByLabel(label: string, options: KeyValuePair[]): KeyValuePair | undefined {
        if (!label) {
            return undefined;
        }

        return options.find(option => option.value.toLowerCase() === label.toLowerCase());
    }

    private mapOptions(options: KeyValuePair[]): AutocompleteOption<string>[] {
        return options.map(option => ({
            label: option.value,
            key: option.key,
            value: option.value,
        }))
    }
}
