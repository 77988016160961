import { type AccessorFn } from '../drupal-webform-types';

// eslint-disable-next-line sonarjs/function-return-type
export const VALUE_ACCESSOR_ITEM: AccessorFn = (_value, settings) => {
    if (!settings?.markup) {
        return '';
    }

    const lines = settings.markup.split('\n');

    return lines.length > 1 ? lines : lines[0];
};
