<big-ui-form-row
    [id]="settings.id"
    [label]="label"
    [isRequired]="control?.isRequired"
    [showInfo]="!!settings.help"
    [infoTextTemplate]="help"
    (infoLayerOpened)="trackFormsInfoIconEvent($event)">
    @for (child of settings.children; track $index) {
        <ng-container
            appElementHost
            [id]="child.name" />
    }
</big-ui-form-row>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
