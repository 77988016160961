<fieldset
    *transloco="let t; prefix: 'ftbAddress'"
    class="min-w-full">
    <legend
        class="block float-left w-full py-2 mb-1"
        [ngClass]="{ 'visually-hidden': settings.titleDisplay === 'invisible' }">
        {{ label }}{{ control?.isRequired ? '&nbsp;' + t('required') : '' }}
    </legend>

    <div class="grid grid-cols-6 gap-x-3 gap-y-6">
        <!-- country -->
        @if (country) {
            <big-ui-form-row
                [label]="country.label"
                [id]="country.settings.id"
                [showInfo]="!!country.settings.help"
                [showError]="isPageValidationFailed"
                [errorOverrides]="selectErrorOverrides"
                [errors]="country.control?.errors"
                [isRequired]="country.control?.isRequired"
                [rowClass]="'block col-span-6 mb-0'">
                <big-ui-select
                    [id]="country.settings.id"
                    [isValid]="country.control?.valid"
                    [options]="country.settings.options"
                    [customNoSelectionOption]="country.settings.emptyOption"
                    [disableTransloco]="true"
                    [formControl]="country.control"
                    [showValidation]="isPageValidationFailed"
                    [isRequired]="country.control?.isRequired" />
            </big-ui-form-row>
        }
        <!-- Street Name -->
        @if (address) {
            <big-ui-form-row
                [label]="address.label"
                [id]="address.settings.id"
                [showInfo]="!!address.settings.help"
                [errors]="address.control?.errors"
                [showError]="isPageValidationFailed"
                [isRequired]="address.control?.isRequired"
                [rowClass]="'block col-span-6 mb-0'"
                [ngClass]="{
                    'sm:col-span-4': !!address2,
                }">
                <big-ui-input
                    [id]="address.settings.id"
                    [isValid]="address.control?.valid"
                    [formControl]="address.control"
                    [showValidation]="isPageValidationFailed"
                    [isRequired]="address.control?.isRequired" />
            </big-ui-form-row>
        }

        <!-- Street Number -->
        @if (address2) {
            <big-ui-form-row
                [label]="address2.label"
                [id]="address2.settings.id"
                [showInfo]="!!address2.settings.help"
                [errors]="address2.control?.errors"
                [showError]="isPageValidationFailed"
                [isRequired]="address2.control?.isRequired"
                [rowClass]="'block col-span-6 mb-0'"
                [ngClass]="{
                    'sm:col-span-2': !!address,
                }">
                <big-ui-input
                    [id]="address2.settings.id"
                    [isValid]="address2.control?.valid"
                    [formControl]="address2.control"
                    [showValidation]="isPageValidationFailed"
                    [isRequired]="address2.control?.isRequired" />
            </big-ui-form-row>
        }

        <!-- post box -->
        @if (postBox) {
            <big-ui-form-row
                [label]="postBox.label"
                [id]="postBox.settings.id"
                [showInfo]="!!postBox.settings.help"
                [errors]="postBox.control?.errors"
                [showError]="isPageValidationFailed"
                [isRequired]="postBox.control?.isRequired"
                [rowClass]="'block col-span-6 mb-0'"
                [ngClass]="{
                    'sm:col-span-2': !!addressAddition,
                }">
                <big-ui-input
                    [id]="postBox.settings.id"
                    [isValid]="postBox.control?.valid"
                    [formControl]="postBox.control"
                    [showValidation]="isPageValidationFailed"
                    [isRequired]="postBox.control?.isRequired" />
            </big-ui-form-row>
        }

        <!-- address addition -->
        @if (addressAddition) {
            <big-ui-form-row
                [label]="addressAddition.label"
                [id]="addressAddition.settings.id"
                [showInfo]="!!addressAddition.settings.help"
                [errors]="addressAddition.control?.errors"
                [showError]="isPageValidationFailed"
                [isRequired]="addressAddition.control?.isRequired"
                [rowClass]="'block col-span-6 mb-0'"
                [ngClass]="{
                    'sm:col-span-4': !!postBox,
                }">
                <big-ui-input
                    [id]="addressAddition.settings.id"
                    [isValid]="addressAddition.control?.valid"
                    [formControl]="addressAddition.control"
                    [showValidation]="isPageValidationFailed"
                    [isRequired]="addressAddition.control?.isRequired" />
            </big-ui-form-row>
        }

        <!-- Postal Code -->
        @if (postalCode) {
            <big-ui-form-row
                [label]="postalCode.label"
                [id]="postalCode.settings.id"
                [showInfo]="!!postalCode.settings.help"
                [errors]="postalCode.control?.errors"
                [showError]="isPageValidationFailed"
                [errorOverrides]="errorOverrides"
                [onlyRenderFirstError]="true"
                [isRequired]="postalCode.control?.isRequired"
                [rowClass]="'block col-span-6 mb-0'"
                [ngClass]="{
                    'sm:col-span-2': !!city,
                }">
                <big-ui-input
                    [id]="postalCode.settings.id"
                    [isValid]="postalCode.control?.valid"
                    [formControl]="postalCode.control"
                    [showValidation]="isPageValidationFailed"
                    [isRequired]="postalCode.control?.isRequired" />
            </big-ui-form-row>
        }

        <!-- City -->
        @if (city) {
            <big-ui-form-row
                [label]="city.label"
                [id]="city.settings.id"
                [showInfo]="!!city.settings.help"
                [errors]="city.control?.errors"
                [showError]="isPageValidationFailed"
                [isRequired]="city.control?.isRequired"
                [rowClass]="'block col-span-6 mb-0'"
                [ngClass]="{
                    'sm:col-span-4': !!postalCode,
                }">
                <big-ui-input
                    [id]="city.settings.id"
                    [isValid]="city.control?.valid"
                    [formControl]="city.control"
                    [showValidation]="isPageValidationFailed"
                    [isRequired]="city.control?.isRequired" />
            </big-ui-form-row>
        }

        <!-- stateProvince -->
        @if (stateProvince) {
            <big-ui-form-row
                [label]="stateProvince.label"
                [id]="stateProvince.settings.id"
                [showInfo]="!!stateProvince.settings.help"
                [errors]="stateProvince.control?.errors"
                [showError]="isPageValidationFailed"
                [errorOverrides]="selectErrorOverrides"
                [isRequired]="stateProvince.control?.isRequired"
                [rowClass]="'block col-span-6 mb-0'">
                <big-ui-select
                    [id]="stateProvince.settings.id"
                    [isValid]="stateProvince.control?.valid"
                    [options]="stateProvince.settings.options"
                    [customNoSelectionOption]="stateProvince.settings.emptyOption"
                    [formControl]="stateProvince.control"
                    [showValidation]="isPageValidationFailed"
                    [isRequired]="stateProvince.control?.isRequired" />
            </big-ui-form-row>
        }
    </div>
</fieldset>
