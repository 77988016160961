import { ChangeDetectionStrategy, Component, HostBinding, Input, inject } from '@angular/core';
import { FormConfigElement, type PreviewValue } from '@big-direkt/form/contracts';
import { ModalService } from '@big-direkt/ui/modal';
import { StringReplaceService } from '../../services/string-replace/string-replace.service';

@Component({
    selector: 'big-form-preview-element',
    templateUrl: './preview-element.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewElementComponent {
    private readonly modalService = inject(ModalService);

    @Input() public settings?: FormConfigElement;
    @Input() public submissionPreviewNode!: PreviewValue;

    @HostBinding('class') public classList = 'block odd:bg-gray-400 even:odd:bg-dark-contrast';

    public constructor(public readonly stringReplaceService: StringReplaceService) {}

    public showModal(src: string, label: string): void {
        this.modalService.showEmbeddedSrc({ src, label });
    }
}
