import { type SubmissionValueAccessorFn } from '../drupal-webform-types';

export const SUBMISSION_VALUE_ACCESSOR_PASSWORD_CONFIRM: SubmissionValueAccessorFn = (v, { name }) => {
    const value = v as Record<string, string>;
    const pass1 = value[`${name}[pass1]`];
    const pass2 = value[`${name}[pass2]`];

    return {
        [name]: JSON.stringify({ pass1, pass2 }),
    };
};
