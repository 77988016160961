<div
    *transloco="let t; prefix: 'uiModalFormClose'"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true"
    class="flex flex-col h-full">
    <div class="flex-none bg-gray-400">
        <div class="flex items-center h-20 px-6 !py-4">
            <h4 class="flex-1 m-0 text-primary">{{ t('title') }}</h4>
            <big-ui-button
                [buttonStyle]="'icon'"
                [id]="'close-overlay-icon'"
                (clicked)="popoverRef.close({ leaveForm: false })"
                [title]="t('closeOverlayBtn')">
                <big-icon
                    [icon]="iconClose"
                    class="primary"
                    [attr.aria-label]="t('closeOverlayBtn')" />
            </big-ui-button>
        </div>
    </div>
    <div class="flex-1 mx-6 mt-4">
        {{ t('content') }}
    </div>
    <div class="flex items-center justify-between mx-6 my-4">
        <big-ui-button
            [buttonStyle]="'link'"
            [id]="'leave-form-btn'"
            [classList]="['no-underline', 'text-primary', 'hover:!text-primary-light']"
            (clicked)="popoverRef.close({ leaveForm: true })"
            [title]="isMobile() ? t('leaveBtnMobile') : t('leaveBtn')">
            <div class="flex items-center gap-2">
                <big-icon
                    [icon]="iconClose"
                    class="text-sm primary"></big-icon>
                <div class="text-base font-bold">
                    {{ isMobile() ? t('leaveBtnMobile') : t('leaveBtn') }}
                </div>
            </div>
        </big-ui-button>
        <big-ui-button
            [buttonStyle]="'secondary'"
            [id]="'close-overlay-btn'"
            (clicked)="popoverRef.close({ leaveForm: false })"
            [title]="isMobile() ? t('closeOverlayBtnMobile') : t('closeOverlayBtn')">
            {{ isMobile() ? t('closeOverlayBtnMobile') : t('closeOverlayBtn') }}
        </big-ui-button>
    </div>
</div>
