import { type PreviewDisplayValue } from '../../models/form-preview/preview-display-value.model';
import { type SubmissionObject } from '../../models/form-submission/submission-object.model';
import { type SubmissionValue } from '../../models/form-submission/submission-value.model';
import { type SubmissionValueState } from '../../models/form-submission/submission-value-state.model';
import { type AccessorFn, type SubmissionValueAccessorFn } from '../drupal-webform-types';
import { getLastNestedParent } from '../nested-submission-value.helper';

// eslint-disable-next-line sonarjs/function-return-type
export const VALUE_ACCESSOR_DEFAULT: AccessorFn = value => {
    if ((value as SubmissionValueState | undefined)?.isDisabled) {
        return undefined;
    }

    return (value as PreviewDisplayValue | undefined) ?? '';
};

export const SUBMISSION_VALUE_ACCESSOR_DEFAULT: SubmissionValueAccessorFn = (v, { name }) =>
    name ? { [name]: v as SubmissionObject | SubmissionValue } : (v as SubmissionObject);

export const SUBMISSION_MAPPER_DEFAULT = (data: SubmissionObject, propName: string, arrayParents: string[]): void => {
    const parent = getLastNestedParent(data, propName, ...arrayParents);

    if (!parent) {
        return;
    }

    if ((parent[propName] as SubmissionValueState | undefined)?.isDisabled) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete parent[propName];

        return;
    }

    if (typeof parent[propName] === 'object' && Array.isArray(parent[propName])) {
        parent[propName] = (parent[propName] as string[]).map(item => {
            if (typeof item !== 'string') {
                return item;
            }

            return item.trim();
        });
    }

    if (typeof parent[propName] === 'string') {
        parent[propName] = parent[propName].trim();
    }
};
