<ng-container *transloco="let t; prefix: 'ftbPasswordConfirm'">
    <p>{{ t('label') }}</p>
    <ul>
        <li>
            {{ t('passwordConfirm.lowerCaseLetter') }}
            @if (hasLowerLetter) {
                <big-icon
                    [icon]="iconOk"
                    class="text-success current" />
            }
        </li>
        <li>
            {{ t('passwordConfirm.upperCaseLetter') }}
            @if (hasUpperLetter) {
                <big-icon
                    [icon]="iconOk"
                    class="text-success current" />
            }
        </li>
        <li>
            {{ t('passwordConfirm.number') }}
            @if (hasNumber) {
                <big-icon
                    [icon]="iconOk"
                    class="text-success current" />
            }
        </li>
        <li>
            {{ t('passwordConfirm.specialCharacter') }}
            @if (hasSpecialChar) {
                <big-icon
                    [icon]="iconOk"
                    class="text-success current" />
            }
        </li>
        <li>
            {{ t('passwordConfirm.allowedCharacterLength') }}
            @if (hasCorrectLength) {
                <big-icon
                    [icon]="iconOk"
                    class="text-success current" />
            }
        </li>
        <li>
            {{ t('passwordsDoMatch') }}
            @if (matches) {
                <big-icon
                    [icon]="iconOk"
                    class="text-success current" />
            }
        </li>
    </ul>
</ng-container>
<ng-template appElementHost />
