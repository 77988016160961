import { Injectable, afterNextRender, signal } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { firstValueFrom, tap } from 'rxjs';

export interface UiState {
    ui: {
        isCoBrowsingEnabled: boolean;
        isFormShown: boolean;
        isTopBarShown: boolean;
        isWizardShown: boolean;
    };
}

export const uiStore = createStore(
    { name: 'ui' },
    withProps<UiState>({
        ui: {
            isCoBrowsingEnabled: false,
            isFormShown: false,
            isTopBarShown: false,
            isWizardShown: false,
        },
    }),
);

@Injectable({ providedIn: 'root' })
export class UiRepository {
    public readonly isFormShown = signal(false);
    public readonly isCoBrowsingEnabled = signal(false);
    public readonly isTopBarShown = signal(false);
    public readonly isWizardShown = signal(false);

    public constructor() {
        afterNextRender(() => {
            this.setInitialValues();
        });
    }

    public setIsFormShown(isFormShown: UiState['ui']['isFormShown']): void {
        uiStore.update(state => ({ ui: { ...state.ui, isFormShown } }));
        this.isFormShown.set(isFormShown);
    }

    public setIsTopBarShown(isTopBarShown: UiState['ui']['isTopBarShown']): void {
        uiStore.update(state => ({ ui: { ...state.ui, isTopBarShown } }));
        this.isTopBarShown.set(isTopBarShown);
    }

    public setIsWizardShown(isWizardShown: UiState['ui']['isWizardShown']): void {
        uiStore.update(state => ({ ui: { ...state.ui, isWizardShown } }));
        this.isWizardShown.set(isWizardShown);
    }

    public setIsCoBrowsingEnabled(isCoBrowsingEnabled: UiState['ui']['isCoBrowsingEnabled']): void {
        uiStore.update(state => ({ ui: { ...state.ui, isCoBrowsingEnabled } }));
        this.isCoBrowsingEnabled.set(isCoBrowsingEnabled);
    }

    private setInitialValues(): void {
        void firstValueFrom(
            uiStore.pipe(
                select((state: UiState) => state.ui),
                tap(ui => {
                    this.isFormShown.set(ui.isFormShown);
                    this.isCoBrowsingEnabled.set(ui.isCoBrowsingEnabled);
                    this.isTopBarShown.set(ui.isTopBarShown);
                    this.isWizardShown.set(ui.isWizardShown);
                }),
            ),
        );
    }
}
