import { type AbstractControl, type ValidationErrors } from '@angular/forms';
import { isValid as isIbanValid } from 'iban';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Iban {
    private static readonly ibanInvalidResult: ValidationErrors = {
        iban: { valid: false },
    };

    public static validateFormControl(fc: AbstractControl): ValidationErrors | null {
        if (!fc.value || typeof fc.value !== 'string') {
            // eslint-disable-next-line no-null/no-null
            return null;
        }

        if (isIbanValid(fc.value)) {
            // eslint-disable-next-line no-null/no-null
            return null;
        }

        return Iban.ibanInvalidResult;
    }
}
