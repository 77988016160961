import { Component } from '@angular/core';
import { BaseComponent } from '../../../base-components/base/base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-progress-bar',
    templateUrl: './progress-bar.component.html',
})
export class ProgressBarComponent extends BaseComponent {
    public static register(): ComponentMap {
        return {
            big_webform_progress_bar: ProgressBarComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }
}
