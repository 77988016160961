/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Component, DestroyRef, inject, type OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { type NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { of, type Observable } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { TextfieldBaseComponent } from '../../../base-components/textfield-base/textfield-base.component';
import { type BigUntypedFormControl } from '../../../form-control/big-form-control';
import { type AutocompleteItem } from '../../../interfaces/autocomplete-item';
import { ApiService } from '../../../services/api-service/api.service';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-autocomplete',
    styleUrl: './autocomplete.component.scss',
    templateUrl: './autocomplete.component.html',
})
export class AutocompleteComponent extends TextfieldBaseComponent implements OnInit {
    private readonly searchDebounceTime = 300;
    private readonly apiService = inject(ApiService);
    private readonly destroy = inject(DestroyRef);

    public model?: AutocompleteItem | string;
    public lastSearchResult: AutocompleteItem[] = [];
    public isSearching = false;
    public isSearchFailed = false;
    public classInput = 'relative';
    public declare control?: BigUntypedFormControl;

    public ngOnInit(): void {
        this.control?.valueChanges.pipe(takeUntilDestroyed(this.destroy), distinctUntilChanged()).subscribe(value => {
            if (value === (this.settings.defaultValue ?? '')) {
                this.model = undefined;
            }
        });
    }

    public static register(): ComponentMap {
        return {
            webform_autocomplete: AutocompleteComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }

    public search: (text$: Observable<string>) => Observable<any> = (text$: Observable<string>): Observable<any> => {
        const path = `webform/${this.settings.webform ?? ''}/autocomplete/${this.settings.name}`;

        const observable: Observable<any> = text$.pipe(
            debounceTime(this.searchDebounceTime),
            distinctUntilChanged(),
            tap((): void => {
                this.isSearching = true; // eslint-disable-line no-invalid-this
            }),
            switchMap(
                (term: string): Observable<any> =>
                    this.apiService.search(path, term).pipe(
                        // eslint-disable-line no-invalid-this
                        tap((): void => {
                            this.isSearchFailed = false; // eslint-disable-line no-invalid-this
                            this.isSearching = false; // eslint-disable-line no-invalid-this
                        }),
                        catchError((): Observable<any> => {
                            this.isSearching = false; // eslint-disable-line no-invalid-this
                            this.isSearchFailed = true; // eslint-disable-line no-invalid-this

                            return of([]);
                        }),
                    ),
            ),
        );

        observable.subscribe((result: AutocompleteItem[]): void => {
            this.lastSearchResult = result; // eslint-disable-line no-invalid-this
            this.updateControl(); // eslint-disable-line no-invalid-this
        });

        return observable;
    };

    public formatter(value: any): string {
        return value.label;
    }

    public updateControl(): void {
        this.updateControlValue();
        this.updateControlValidity();
    }

    public itemSelected($event: NgbTypeaheadSelectItemEvent): void {
        this.control?.setValue($event.item.value);
        this.updateControlValidity();
    }

    private updateControlValue(): void {
        if (!this.model) {
            this.control?.setValue('');

            return;
        }

        if (typeof this.model === 'object') {
            this.control?.setValue(this.model.value);

            return;
        }

        const input: string = this.model.trim().toLowerCase();
        const match: AutocompleteItem | undefined = this.lastSearchResult.find((entry: AutocompleteItem): boolean => entry.label.toLowerCase() === input);

        if (match === undefined) {
            this.control?.setValue('');

            return;
        }

        this.model = match;
        this.control?.setValue(match.value);
    }

    private updateControlValidity(): void {
        if (this.model && typeof this.model === 'string') {
            this.control?.setErrors({ autocompleteStrictMatch: true });
        }
    }
}
